import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import Container from "../atoms/Container";
import Logo from "../atoms/Logo";
import SocialLinks from "../molecules/SocialLinks";
import DirectMenu from "../molecules/DirectMenu";
import AboutMenu from "../molecules/AboutMenu";

const StyledFooter = styled.footer`
  display: table-row;
  height: 1px;
  line-height: normal;
`;

const SiteMap = styled.div`
  padding: 37.5px 0;
  background-color: ${color.primary.base};
  color: ${color.body.base};
`;

const Legal = styled.div`
  padding: 14px 0 20px 0;
  background-color: ${color.primary.base};
  color: ${color.body.base};
`;

const Copyright = styled.p`
  margin: 0;
  color: ${color.body.base};
  opacity: 0.6;
  font-family: ${font.family.head};
  font-size: 0.8rem;
`;

const Title = styled.h5`
  font-size: 1rem;
  margin-bottom: 7px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledLogo = styled(Logo)`
  &:first-child {
    margin-right: 20px;
  }
`;

const RamdathLogo = styled(StyledLogo)`
  height: 65px;
`;

const Footer = () => (
  <StyledFooter>
    <SiteMap>
      <Container>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Logo href="https://www.knhb.nl/" isFooterLogo />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <Title>Direct naar</Title>
                <DirectMenu />
              </div>
              <div className="col-xs-12 col-sm-6">
                <Title>Over KNHB.nl</Title>
                <AboutMenu />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </SiteMap>
    <Legal>
      <Container>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Copyright>&copy; {new Date().getFullYear()} - KNHB</Copyright>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="row">
              <div className="col-xs-7">
                <LogoWrapper>
                  <StyledLogo isDijLogo={true} target="_blank" href="http://deinternetjongens.nl" />
                  <RamdathLogo isRamdathLogo={true} target="_blank" href="http://ramdath.com" />
                </LogoWrapper>
              </div>
              <div className="col-xs-5">
                <SocialLinks isFooter={true} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Legal>
  </StyledFooter>
);

export default Footer;
