import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledStaff = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px;
  background-color: ${color.gray.lighter};

  @media only screen and (min-width: 576px) {
    flex-direction: row;
  }

  @media only screen and (min-width: 992px) {
    justify-content: normal;
  }
`;

const StaffMember = styled.div`
  flex: 0 0 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 576px) {
    flex: 0 0 calc(100% / 3);
    margin-bottom: 0;
  }
`;

const Function = styled.h5`
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${color.gray.darker};
`;

const Name = styled.h4`
  font-size: 16px;
  font-weight: 600;
  color: ${color.black.base};
`;

const Staff = ({ nationalCoach, assistentCoach, manager }) => (
  <>
    {nationalCoach && (
      <StyledStaff>
        {nationalCoach && (
          <StaffMember>
            <Function>Bondscoach</Function>
            <Name>{nationalCoach}</Name>
          </StaffMember>
        )}
        {assistentCoach && (
          <StaffMember>
            <Function>Assistent bondscoach</Function>
            <Name>{assistentCoach}</Name>
          </StaffMember>
        )}
        {manager && (
          <StaffMember>
            <Function>Manager</Function>
            <Name>{manager}</Name>
          </StaffMember>
        )}
      </StyledStaff>
    )}
  </>
);

export default Staff;
