import React from "react";
import MenuItem from "../atoms/MenuItem";
import MenuLink from "../atoms/MenuLink";
import Menu from "../atoms/Menu";
import styled from "styled-components";

const StyledMenuLink = styled(MenuLink)`
  font-size: 13px;
`;

const CommunityMenu = () => (
  <Menu>
    <MenuItem>
      <StyledMenuLink href="http://hockey.nl" title="hockey.nl" target="_blank">
        hockey.nl
      </StyledMenuLink>
    </MenuItem>
    <MenuItem>
      <StyledMenuLink href="https://www.knhb.nl" title="KNHB.nl" target="_blank">
        KNHB.nl
      </StyledMenuLink>
    </MenuItem>
  </Menu>
);

export default CommunityMenu;
