import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: ${props => (props.isCompact ? "991px" : "1200px")};
`;

const Container = ({ children, isCompact, className }) => (
  <StyledContainer isCompact={isCompact} className={className}>
    {children}
  </StyledContainer>
);

export default Container;
