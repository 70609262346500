import React, { Component } from "react";
import styled from "styled-components";
import CommunityBar from "../molecules/CommunityBar";
import TopBar from "../molecules/TopBar";

const StyledHeader = styled.header`
  display: table-row;
  height: 1px;
  line-height: normal;
`;

class Header extends Component {
  render() {
    return (
      <StyledHeader>
        <CommunityBar />
        <TopBar onClick={() => (this.props.toggleOffCanvas ? this.props.toggleOffCanvas() : null)} />
      </StyledHeader>
    );
  }
}

export default Header;
