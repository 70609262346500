import React, { Component } from "react";
import OffCanvasContainer from "../OffCanvasContainer";
import styled from "styled-components";

const StyledOffCanvasContainer = styled(OffCanvasContainer)`
  > ul {
    display: block;
  }
`;

class FiltersCanvas extends Component {
  render() {
    const { children } = this.props;

    return (
      <StyledOffCanvasContainer
        hasCloseButton={true}
        onClose={this.props.toggle}
        containerOpen={this.props.open}
        className="filter-canvas"
      >
        {children}
      </StyledOffCanvasContainer>
    );
  }
}

export default FiltersCanvas;
