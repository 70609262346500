import React from "react";
import Card from "../atoms/Card";
import styled from "styled-components";
import { color, font } from "../templates/ui";

const StyledCardMatchInfo = styled(Card)`
  background-color: ${color.primary.base};
  color: ${color.body.base};
`;

const CardInner = styled.div`
  position: relative;
  padding: 20px 60px 20px 20px;
  display: flex;
  flex-direction: column;
`;

const CardLabel = styled.label`
  display: block;
  font-size: 16px;
  text-transform: uppercase;
`;

const Year = styled.h1`
  margin: 0;
  font-size: 55px;
  font-weight: 700;
  font-family: ${font.family.alt};
  line-height: 3.4rem;

  @media only screen and (min-width: 992px) {
    font-size: 65px;
  }
`;

const Type = styled.label`
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;

  @media only screen and (min-width: 992px) {
    font-size: 30px;
  }
`;

const Date = styled.label`
  font-size: 17px;
  font-weight: 700;

  @media only screen and (min-width: 992px) {
    font-size: 22px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledMatchWrapper = styled.div`
  margin-bottom: 10px;
`;

const CardMatchInfo = ({ className, date, year, tournamentType }) => (
  <StyledCardMatchInfo className={className}>
    <CardInner>
      <Wrapper>
        {date && (
          <StyledMatchWrapper>
            <CardLabel>Datum</CardLabel>
            <Date>{date}</Date>
          </StyledMatchWrapper>
        )}
        {tournamentType && (
          <div>
            {tournamentType === "Oefen" ? (
              <CardLabel>{tournamentType}</CardLabel>
            ) : (
              <CardLabel data-testid="card-label-tournament">Toernooi</CardLabel>
            )}
            {tournamentType !== "Oefen" && <Type>{tournamentType}</Type>}
          </div>
        )}
      </Wrapper>
      {year && (
        <div>
          <CardLabel>Jaar</CardLabel>
          <Year>{year}</Year>
        </div>
      )}
    </CardInner>
  </StyledCardMatchInfo>
);

export default CardMatchInfo;
