import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledScore = styled.div`
  color: ${color.primary.base};
  font-weight: 700;
  font-size: 20px;

  span {
    color: #9b9b9b;
  }
`;

const Score = ({ className, children }) => <StyledScore className={className}>{children}</StyledScore>;

export default Score;
