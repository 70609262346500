import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";
import IconFacebook from "../atoms/icons/IconFacebook";
import IconTwitter from "../atoms/icons/IconTwitter";
import IconLinkedin from "../atoms/icons/IconLinkedin";
import IconYoutube from "../atoms/icons/IconYoutube";
import IconInstagram from "../atoms/icons/IconInstagram";

const StyledSocialLinks = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 177px;
`;

const StyledIconFacebook = styled(IconFacebook)`
  fill: ${color.body.base};
  width: 13px;
  height: 22px;
`;

const StyledIconTwitter = styled(IconTwitter)`
  fill: ${color.body.base};
  width: 23px;
  height: 18px;
`;

const StyledIconLinkedin = styled(IconLinkedin)`
  fill: ${color.body.base};
  width: 23px;
  height: 22px;
`;

const StyledIconYoutube = styled(IconYoutube)`
  fill: ${color.body.base};
  height: 18px;
  width: 25px;
`;

const StyledIconInstagram = styled(IconInstagram)`
  fill: ${color.body.base};
  width: 23px;
  height: 22px;
`;

const IconWrapper = styled.a`
  display: flex;
  align-items: center;
  padding: ${props => (props.isFooter ? "0" : "11px 15px 11px 0")};
  &:last-child {
    padding-right: 10px;
  }
`;

const SocialLinks = ({ isFooter }) => (
  <StyledSocialLinks>
    <IconWrapper href="https://www.facebook.com/KNHB.NL" target="_blank" isFooter={isFooter}>
      <StyledIconFacebook />
    </IconWrapper>
    <IconWrapper href="https://twitter.com/KNHB_NL" isFooter={isFooter}>
      <StyledIconTwitter />
    </IconWrapper>
    <IconWrapper href="https://www.linkedin.com/company/knhb?trk=biz-companies-cyf" target="_blank" isFooter={isFooter}>
      <StyledIconLinkedin />
    </IconWrapper>
    <IconWrapper
      href="https://www.youtube.com/channel/UC_BWXefInkTSYFdsTYySQCg/videos"
      target="_blank"
      isFooter={isFooter}
    >
      <StyledIconYoutube />
    </IconWrapper>
    <IconWrapper href="https://www.instagram.com/knhb_nl/" target="_blank" isFooter={isFooter}>
      <StyledIconInstagram />
    </IconWrapper>
  </StyledSocialLinks>
);

export default SocialLinks;
