import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledCardHeader = styled.header`
  padding: 15px;
  color: ${color.primary.base};
  border-bottom: 1px solid ${color.gray.alt};
  text-transform: uppercase;

  h2 {
    font-size: 18px;
    margin: 0;
  }
`;

const CardHeader = ({ className, children }) => (
  <StyledCardHeader className={className}>
    <h2>{children}</h2>
  </StyledCardHeader>
);

export default CardHeader;
