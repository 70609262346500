import React, { Component } from "react";
import styled from "styled-components";
import Header from "../organisms/Header";
import Layout from "../templates/Layout";
import Container from "../atoms/Container";
import Footer from "../organisms/Footer";
import OffCanvasContainer from "../organisms/OffCanvasContainer";
import MainMenu from "../molecules/MainMenu";
import FiltersCanvas from "../organisms/offcanvas/FiltersCanvas";
import Card from "../atoms/Card";
import { color } from "../templates/ui";
import CardHeader from "../atoms/CardHeader";
import CardBody from "../atoms/CardBody";
import Staff from "../atoms/Staff";
import MatchDetailTableHeader from "../molecules/MatchDetailTableHeader";
import MatchBlock from "../organisms/MatchBlock";
import AppLinks from "../molecules/AppLinks";
import MatchDetailItem from "../atoms/MatchDetailItem";
import Breadcrumbs, { getBreadcrumbs } from "../molecules/Breadcrumbs";
import { getMatch } from "../../api/Match";
import moment from "moment";
import { getAssistentCoachName, getManagerName, getNationalCoachName, parseScore, parseActions } from "../../helpers";
import { Fade } from "react-reveal";
import withErrorPage from "../hoc/withErrorPage";

const StyledCard = styled(Card)`
  flex: auto;
  margin-bottom: 20px;
`;

const StyledCardHeader = styled(CardHeader)`
  background-color: ${color.body.base};
`;

const StyledContainer = styled(Container)`
  padding: 0;
  max-width: 800px;
`;

const StyledAppLinks = styled(AppLinks)`
  margin-bottom: 30px;
`;

class Match extends Component {
  state = {
    menu_open: false,
    showFiltersCanvas: false,
    match: {}
  };

  toggleStateBool = fieldName => {
    this.setState(prevState => {
      return {
        ...prevState,
        [fieldName]: !prevState[fieldName]
      };
    });
  };

  fetchMatch = () => {
    getMatch({ id: this.props.match.params.id })
      .then(response =>
        this.setState({
          match: response.data
        })
      )
      .catch(this.props.setError);
  };

  componentDidMount() {
    this.fetchMatch();
  }

  render() {
    if (!this.state.match.teams) {
      return null;
    }

    var players =
      this.state.match.teams.home.composition.players.length > 0
        ? this.state.match.teams.home.composition.players
        : this.state.match.teams.away.composition.players;

    return (
      <>
        <Header toggleOffCanvas={() => this.setState({ menu_open: !this.state.menu_open })} />
        <Layout>
          <Container>
            <StyledAppLinks
              onClick={() => this.toggleStateBool("showFiltersCanvas")}
              breadcrumbs={this.props.location.state?.breadcrumbs || []}
            />
            <Breadcrumbs
              links={getBreadcrumbs(
                this.props.location,
                `${this.state.match.teams.home.name} - ${this.state.match.teams.away.name}`
              )}
            />
            <MatchBlock
              date={moment(this.state.match.datetime).format("DD-MM-YYYY")}
              year={moment(this.state.match.datetime).format("YYYY")}
              tournamentType={this.state.match.tournament.event.label}
              homeFlag={this.state.match.teams.home.flag}
              homeTeam={this.state.match.teams.home.name}
              awayFlag={this.state.match.teams.away.flag}
              awayTeam={this.state.match.teams.away.name}
              homeScore={parseScore(this.state.match.scores).home}
              awayScore={parseScore(this.state.match.scores).away}
              homeScoreExtended={parseScore(this.state.match.scores).homeExtended}
              awayScoreExtended={parseScore(this.state.match.scores).awayExtended}
              country={
                this.state.match.location && this.state.match.location.country
                  ? this.state.match.location.country.name
                  : ""
              }
              city={this.state.match.location ? this.state.match.location.city : ""}
              href="#"
            />
            <StyledContainer>
              <StyledCard>
                {players.length > 0 && <StyledCardHeader>Statistieken</StyledCardHeader>}
                <CardBody>
                  <Staff
                    nationalCoach={getNationalCoachName(this.state.match.teams)}
                    assistentCoach={getAssistentCoachName(this.state.match.teams)}
                    manager={getManagerName(this.state.match.teams)}
                  />
                  {players.length > 0 && <MatchDetailTableHeader />}
                  {players &&
                    players.map((player, index) => (
                      <Fade key={player.id} bottom distance="40px" delay={index * 50}>
                        <MatchDetailItem
                          href={{
                            pathname: `/internationals/${player.id}`,
                            state: {
                              breadcrumbs: getBreadcrumbs(
                                this.props.location,
                                `${this.state.match.teams.home.name} - ${this.state.match.teams.away.name}`
                              )
                            }
                          }}
                          name={player.name}
                          goals={parseActions(player.actions)}
                        />
                      </Fade>
                    ))}
                </CardBody>
              </StyledCard>
            </StyledContainer>
          </Container>
        </Layout>
        <Footer />
        <OffCanvasContainer containerOpen={this.state.menu_open} isMenu={true}>
          <MainMenu isOffCanvasMenu={true} />
        </OffCanvasContainer>
        <FiltersCanvas toggle={() => this.toggleStateBool("showFiltersCanvas")} open={this.state.showFiltersCanvas} />
      </>
    );
  }
}

export default withErrorPage(Match);
