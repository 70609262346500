import React from "react";
import styled from "styled-components";
import { font } from "../templates/ui";

const StyledTimelineTournament = styled.div`
  background-color: ${props => (props.index ? "#EF790C" : "#EFEFEF")};
  color: ${props => (props.index ? "#FFFFFF" : "#393939")};
  font-size: 14px;
  font-weight: 700;
  font-family: ${font.family.head};
  padding: 4px 6px;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
`;

const TimelineTournament = ({ children, index }) => (
  <StyledTimelineTournament index={index}>{children}</StyledTimelineTournament>
);

export default TimelineTournament;
