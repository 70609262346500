import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";

const StyledTableHeaderItem = styled.div`
  color: ${color.gray.darker};
  font-size: 13px;
  font-family: ${font.family.head};
  font-weight: 600;
  text-transform: uppercase;

  &:first-child {
    min-width: 180px;
  }

  &:nth-child(2) {
    min-width: 100px;

    @media only screen and (min-width: 576px) {
      min-width: 80px;
    }

    @media only screen and (min-width: 992px) {
      min-width: 90px;
    }
  }
`;

const TableHeaderItem = ({ children, className }) => (
  <StyledTableHeaderItem className={className}>{children}</StyledTableHeaderItem>
);

export default TableHeaderItem;
