import React from "react";
import styled from "styled-components";
import Radio from "../atoms/Radio";
import InputLabel from "../atoms/InputLabel";

const StyledRadioItem = styled.div`
  position: relative;
  margin-right: 30px;

  .filter-canvas & {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const Radios = styled.div`
  display: flex;
`;

const RadioItem = ({ selected, onChange, className, disabled }) => (
  <StyledRadioItem className={className} disabled={disabled}>
    <InputLabel>Team</InputLabel>
    <Radios>
      <Radio id="female" checked={selected === "f"} radioText="Dames" value="f" onChange={onChange} />
      <Radio id="male" checked={selected === "m"} radioText="Heren" value="m" onChange={onChange} />
    </Radios>
  </StyledRadioItem>
);

export default RadioItem;
