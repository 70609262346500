import React from "react";
import Card from "../atoms/Card";
import styled from "styled-components";
import { color } from "../templates/ui";
import IconCompetition from "../atoms/icons/IconCompetition";

const StyledCardPlayerInfo = styled(Card)`
  background-color: ${color.primary.base};
  color: ${color.body.base};
`;

const CardInner = styled.div`
  position: relative;
  padding: 20px 50px 20px 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 154px;
  height: 100%;
`;

const CardLabel = styled.label`
  display: block;
  font-size: 16px;
  text-transform: uppercase;
`;

const CardText = styled.h3`
  margin: 0;
  font-size: 20px;
`;

const CardInfo = styled.div`
  margin: 5px 0;
  z-index: 1;
`;

const StyledIconCompetition = styled(IconCompetition)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const CardPlayerInfo = ({ className, name, dateOfBirth, placeOfBirth }) => (
  <StyledCardPlayerInfo className={className}>
    <CardInner>
      {name && (
        <CardInfo>
          <CardLabel>Naam</CardLabel>
          <CardText>{name}</CardText>
        </CardInfo>
      )}
      {dateOfBirth && (
        <CardInfo>
          <CardLabel>Geboortedatum</CardLabel>
          <CardText>{dateOfBirth}</CardText>
        </CardInfo>
      )}
      {placeOfBirth && (
        <CardInfo>
          <CardLabel>Geboorteplaats</CardLabel>
          <CardText>{placeOfBirth}</CardText>
        </CardInfo>
      )}
      <StyledIconCompetition />
    </CardInner>
  </StyledCardPlayerInfo>
);

export default CardPlayerInfo;
