import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledSelect = styled.select`
  transition: border-color 0.2s ease-in-out;
  position: relative;
  border: 1px solid #dddddd;
  border-radius: 0;
  width: 100%;
  background-color: ${color.body.base};
  height: 48px;
  padding-left: 14px;
  padding-right: 34px;
  color: ${color.base.base};
  font-size: 14px;
  font-weight: 600;
  appearance: none;
  min-width: 80px;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #efefef;
    opacity: 0.8;
  }

  option {
    color: #bbbbbb;
    font-weight: 400;
  }
`;

const Select = ({ onChange, children, value }) => (
  <StyledSelect value={value} onChange={onChange}>
    {children}
  </StyledSelect>
);

export default Select;
