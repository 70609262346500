import React, { Component } from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import IconAward from "../atoms/icons/IconAward";
import TimelineItem from "../atoms/TimelineItem";
import TimelineButton from "../atoms/TimelineButton";
import TimelineTournament from "../atoms/TimelineTournament";
import TimelineStartingPoint from "../atoms/TimelineStartingPoint";
import TimelineProgress from "../atoms/TimelineProgress";
import moment from "moment";

const StyledTimelineDots = styled.div`
  position: relative;
  list-style: none;
  padding: 80px 0;
  margin-top: 40px;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  scroll-behavior: smooth;
  background-color: ${color.body.base};
`;

const TimelineYear = styled.div`
  position: absolute;
  top: ${props => (props.index ? "-36px" : "-30px")};
  left: 50%;
  transform: translateX(-50%);
  color: ${props => (props.index ? "#1F1F1F" : "#9b9b9b")};
  font-size: ${props => (props.index ? "24px" : "18px")};
  font-weight: ${props => (props.index ? "700" : "400")};
  font-family: ${font.family.alt};
  transition: all 0.1s ease-in-out;
`;

const TournamentInfoWrapper = styled.div`
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  min-width: 60px;
`;

const StyledIconAward = styled(IconAward)`
  margin-left: 5px;
`;

class CarouselDots extends Component {
  carouselRef = React.createRef();

  componentDidUpdate() {
    this.carouselRef.current.scrollLeft = this.props.currentSlide * 96 - window.innerWidth / 2;
  }

  render() {
    return (
      <StyledTimelineDots ref={this.carouselRef}>
        <TimelineProgress
          index={this.props.currentSlide}
          previousSlides={this.props.index < this.props.currentSlide}
          totalSlides={this.props.slideCount}
        />
        <ul>
          {this.props.tournaments &&
            this.props.tournaments.map((tournament, i) => {
              return (
                <TimelineItem key={tournament.id}>
                  <TimelineYear index={this.props.currentSlide === i}>
                    {moment(tournament.endAt).format("YYYY")}
                  </TimelineYear>
                  <TimelineButton
                    previousSlides={i < this.props.currentSlide}
                    index={this.props.currentSlide === i}
                    onClick={this.props.goToSlide.bind(null, i)}
                  >
                    <TimelineStartingPoint />
                  </TimelineButton>
                  <TournamentInfoWrapper>
                    {tournament.event.label && (
                      <TimelineTournament index={this.props.currentSlide === i}>
                        {tournament.event.label}
                      </TimelineTournament>
                    )}
                    {tournament.ranking === 1 && <StyledIconAward />}
                  </TournamentInfoWrapper>
                </TimelineItem>
              );
            })}
        </ul>
      </StyledTimelineDots>
    );
  }
}

export default CarouselDots;
