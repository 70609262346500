import React from "react";

const IconLinkedin = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.96 44.97" className={className}>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M5.39,0A5.09,5.09,0,0,0,0,5.12a5.06,5.06,0,0,0,5.28,5.12,5.1,5.1,0,0,0,5.45-5.12A5.08,5.08,0,0,0,5.39,0ZM5.28,8.24A3.06,3.06,0,0,1,2,5.12,3.1,3.1,0,0,1,5.39,2,3.08,3.08,0,0,1,8.73,5.15C8.73,6.94,7.4,8.24,5.28,8.24Z"
        />
        <path
          className="cls-1"
          d="M33.65,11.93a11.88,11.88,0,0,0-8.2,2.77l-.28-2.77H15V45H25V27.61a10.19,10.19,0,0,1,.49-2.71c1-2.22,2-2.59,5.28-2.59,2.78,0,5.22,2.62,5.22,5.8V45H46V26.3C46,17.66,40.68,11.93,33.65,11.93ZM44,43H38V28.1c0-4.25-3.31-7.8-7.22-7.8s-5.65.64-7.1,3.75A11.55,11.55,0,0,0,23,27.61V43H17v-29h6.36l.41,4h1.64l.29-.47c1.32-2.12,4-3.57,7.95-3.57C39.54,13.93,44,18.73,44,26.3Z"
        />
        <path className="cls-2" d="M1,11.93V45h9v-33Zm7,31H3v-29h5Z" />
      </g>
    </g>
  </svg>
);

export default IconLinkedin;
