import React from "react";
import ScoreListItem from "../atoms/ScoreListItem";
import { Fade } from "react-reveal";

const ScoreList = ({ id, goals, name, topScorers, breadcrumbs }) => (
  <>
    {topScorers &&
      topScorers.map((topScorer, index) => (
        <Fade key={topScorer.id} bottom distance="40px" delay={index * 50}>
          <ScoreListItem
            href={{ pathname: `/internationals/${topScorer.id}`, state: { breadcrumbs: breadcrumbs } }}
            score={topScorer.goals}
            name={topScorer.name}
          />
        </Fade>
      ))}
  </>
);

export default ScoreList;
