import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";
import InputItem from "./InputItem";
import IconMagnifier from "../atoms/icons/IconMagnifier";
import RadioItem from "./RadioItem";
import Button from "../atoms/Button";
import { withRouter } from "react-router-dom";

const TimelineContainer = styled.div`
  display: none;
  margin-bottom: 20px;
  text-align: center;

  @media only screen and (min-width: 992px) {
    display: block;
  }

  .filter-canvas & {
    display: block;
    margin: 0;
  }
`;

const StyledTabPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 4px solid ${color.primary.base};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  background-color: ${color.gray.lighter};

  .filter-canvas & {
    display: block;
    border: none;
    box-shadow: none;
    background-color: ${color.body.base};
  }
`;

const SearchWrapper = styled.div`
  padding: 20px;
  border-right: 1px solid #e6e6e6;

  .filter-canvas & {
    border: none;
    padding-top: 0;
  }
`;

const FilterWrapper = styled.form`
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;

  .filter-canvas & {
    flex-wrap: wrap;
    padding-top: 0;
  }
`;

const StyledRadioItem = styled(RadioItem)`
  .filter-canvas & {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledButton = styled(Button)`
  display: none;

  .filter-canvas & {
    display: block;
  }
`;

const StyledInputItem = styled(InputItem)`
  width: 200px;

  .filter-canvas & {
    width: 100%;
  }
`;

const InternationalFilter = ({
  onSearchChange,
  onSearchClick,
  onGenderChange,
  search,
  gender,
  resetFilters,
  onClose
}) => {
  return (
    <>
      <TimelineContainer>
        <StyledTabPanel>
          <SearchWrapper>
            <StyledInputItem
              data-testid="search-name"
              data-mobile={!!onClose}
              labelText="Zoeken"
              placeholder="Zoek op naam..."
              icon={<IconMagnifier />}
              onChange={onSearchChange}
              onClick={onSearchClick}
              value={search}
            />
          </SearchWrapper>
          <FilterWrapper id="filtersInternational">
            <StyledRadioItem selected={gender} onChange={onGenderChange} />
            <ButtonWrapper>
              <Button onClick={resetFilters} isGray data-testid="search-reset-filters" data-mobile={!!onClose}>
                Wis filters
              </Button>
              <StyledButton onClick={onClose} data-testid="search-filter" data-mobile={!!onClose}>
                Filteren
              </StyledButton>
            </ButtonWrapper>
          </FilterWrapper>
        </StyledTabPanel>
      </TimelineContainer>
    </>
  );
};

export default withRouter(InternationalFilter);
