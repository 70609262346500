import React from "react";

const IconAward = ({ className }) => (
  <svg width="18" height="25" viewBox="0 0 18 25" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g fill="none" fillRule="evenodd">
      <g fill="#CFCFCF">
        <path d="M12.986 2.59L14.715 0H2.58l1.73 2.59zM4.921 9.69a5.714 5.714 0 0 1 2.316-1.2L1.567 0 .337 1.65a.977.977 0 0 0-.029 1.128L4.921 9.69zM12.375 9.69l4.613-6.912a.977.977 0 0 0-.03-1.128L15.73 0l-5.67 8.49a5.714 5.714 0 0 1 2.316 1.2z" />
      </g>
      <path
        d="M8.648 10.118a5.977 5.977 0 1 0 5.978 5.978 5.984 5.984 0 0 0-5.978-5.978z"
        fill="#EF790C"
        fillRule="nonzero"
      />
      <path
        d="M6.928 18.545v-1.09h1.264V14.09h-1.09v-.834c.317-.061.586-.134.806-.22.22-.085.434-.19.642-.312h.99v4.73h1.09v1.09H6.928z"
        fill="#FFF"
      />
      <path
        d="M8.648 8.114a7.982 7.982 0 1 0 7.982 7.982 7.991 7.991 0 0 0-7.982-7.982zm0 14.901a6.92 6.92 0 1 1 6.92-6.92 6.927 6.927 0 0 1-6.92 6.92z"
        fill="#EF790C"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default IconAward;
