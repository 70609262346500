import React from "react";
import Card from "../atoms/Card";
import styled from "styled-components";
import { color } from "../templates/ui";
import IconHockeystick from "../atoms/icons/IconHockeystick";

const StyledCardPlayerDetails = styled(Card)`
  background-color: ${color.body.base};
  color: ${color.gray.darkest};
`;

const CardInner = styled.div`
  position: relative;
  padding: 20px 50px 20px 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 154px;
  height: 100%;
`;

const CardLabel = styled.label`
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  color: #bbbbbb;
`;

const CardText = styled.h3`
  margin: 0;
  font-size: 20px;
`;

const CardInfo = styled.div`
  margin: 5px 0;
`;

const StyledIconHockeystick = styled(IconHockeystick)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const CardPlayerDetails = ({ className, preferredPosition, interlands, goals }) => (
  <StyledCardPlayerDetails className={className}>
    <CardInner>
      {preferredPosition && (
        <CardInfo>
          <CardLabel>Positie</CardLabel>
          <CardText>{preferredPosition}</CardText>
        </CardInfo>
      )}
      {typeof interlands !== undefined && (
        <CardInfo>
          <CardLabel>Interlands</CardLabel>
          <CardText>{interlands}</CardText>
        </CardInfo>
      )}
      {typeof goals !== undefined && (
        <CardInfo>
          <CardLabel>Doelpunten</CardLabel>
          <CardText>{goals}</CardText>
        </CardInfo>
      )}
      <StyledIconHockeystick />
    </CardInner>
  </StyledCardPlayerDetails>
);

export default CardPlayerDetails;
