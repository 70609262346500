import React from "react";
import styled from "styled-components";

const StyledMenu = styled.ul`
  display: flex;
  align-items: center;
`;

const Menu = ({ children, className }) => <StyledMenu className={className}>{children}</StyledMenu>;

export default Menu;
