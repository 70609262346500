import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { color, font } from "../templates/ui";

const StyledBreadcrumb = styled(Link)`
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: ${color.body.base};
  padding: 12px 18px;
  color: ${color.primary.base};
  font-size: 15px;
  font-weight: 700;
  font-family: ${font.family.head};
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  margin-right: 6px;
  margin-bottom: 5px;
  height: 44px;

  &:last-child {
    color: #9b9b9b;
    margin-bottom: 0;
  }

  &:first-child {
    &:before {
      content: none;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 6px solid #f7f7f7;
  }

  &:after {
    content: "";
    position: absolute;
    right: -6px;
    width: 0;
    height: 0;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 6px solid #ffffff;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    color: ${color.primary.base};
  }
`;

const Breadcrumb = ({ text, link }) => <StyledBreadcrumb to={link}>{text}</StyledBreadcrumb>;

export default Breadcrumb;
