import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import IconChevronRight from "./icons/IconChevronRight";

const StyledMenuLink = styled.a`
  display: flex;
  align-items: center;
  padding: ${props => (props.isFooterLink ? "6px" : "0 10px")};
  color: ${color.body.base};
  font-size: ${props => {
    if (props.isFooterLink) {
      return "14px";
    }

    if (props.isOffCanvasMenu) {
      return "18px";
    }

    return "16px";
  }};
  font-weight: 700;
  text-decoration: none;
  transition: height 0.3s ease-out 0s, line-height 0.3s ease-out 0s;
  border-radius: ${props => (props.isFooterLink ? "3px" : "0")};
  font-family: ${font.family.head};

  @media only screen and (min-width: 992px) {
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: block;
      content: ${props => {
        if (props.isMainMenuLink) {
          return "''";
        }

        if (props.isOffCanvasMenu) {
          return "none";
        }

        return "none";
      }};
      background-color: ${color.primary.base};
      height: 3px;
      transition: ${props =>
        props.isOffCanvasMenu
          ? "transition: background-color .3s ease-out 0s,height .3s ease-out 0s,line-height .3s ease-out 0s"
          : "transform 0.24s ease-in-out 0ms"};
      transform: translateX(-120%);
    }
  }

  &:hover {
    color: ${props => {
      if (props.isFooterLink) {
        return `${color.body.base}`;
      }
      if (props.isOffCanvasMenu) {
        return `${color.black.base}`;
      }

      return `${color.primary.base}`;
    }};
    background-color: ${props => (props.isFooterLink || props.isOffCanvasMenu ? "rgba(0,0,0,.1)" : "transparent")};

    &:before {
      transform: none;
    }
  }
`;

const StyledChevronRight = styled(IconChevronRight)`
  width: 12px;
  height: 11px;
  fill: ${color.body.base};
  opacity: 0.7;
  margin-right: 4px;
`;

const MenuLink = ({ href, title, children, className, isFooterLink, isMainMenuLink, isOffCanvasMenu }) => (
  <StyledMenuLink
    href={href}
    title={title}
    className={className}
    isFooterLink={isFooterLink}
    isMainMenuLink={isMainMenuLink}
    isOffCanvasMenu={isOffCanvasMenu}
  >
    {isFooterLink && <StyledChevronRight />}
    {children}
  </StyledMenuLink>
);

export default MenuLink;
