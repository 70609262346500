import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";

const CheckboxWrapper = styled.div`
  input[type="radio"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  input[type="radio"] + label {
    position: relative;
    display: block;
    padding: 14px;
    cursor: pointer;
    min-width: 80px;
    height: 48px;

    label {
      pointer-events: none;
    }

    &:before {
      transition: all 50ms ease-in-out;
      position: absolute;
      content: "";
      border-radius: 0;
      left: 0;
      top: 0;
      width: 80px;
      height: 46px;
      background-color: ${color.body.base};
      z-index: 1;
      border: 1px solid #dddddd;
    }

    &:after {
      position: absolute;
      content: "";
      border-radius: 0;
      left: 0;
      top: 0;
      width: 80px;
      height: 46px;
      background-color: ${color.body.base};
      opacity: 0;
      z-index: 0;
    }
  }

  input[type="radio"]:checked + label {
    &:before {
      background-color: ${color.primary.base};
      color: ${color.body.base};
    }

    label {
      color: ${color.body.base};
    }

    svg {
      opacity: 1;
      fill: ${color.body.base};
    }

    &:before {
      transition: all 0.2s ease-in-out;
      border-color: ${color.primary.base};
    }
  }

  input[type="radio"]:focus + label {
    &:after {
      opacity: 1;
    }
  }
`;

const RadioText = styled.label`
  position: relative;
  z-index: 1;
  text-align: center;
  font-family: ${font.family.head};
  color: #797979;
`;

const Radio = ({ id, checked, radioText, value, onChange }) => (
  <CheckboxWrapper>
    <input type="radio" id={id} checked={checked} onChange={onChange} value={value} />
    <label htmlFor={id}>
      <RadioText>{radioText}</RadioText>
    </label>
  </CheckboxWrapper>
);

export default Radio;
