import React from "react";

const IconChevronRight = ({ className }) => (
  <svg width="11" height="18" viewBox="0 0 11 18" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M8.25 9L1.11 1.58a.694.694 0 0 1 0-.98.68.68 0 0 1 .968 0l7.83 7.91a.695.695 0 0 1 0 .978l-7.83 7.908c-.268.27-.7.27-.97 0a.7.7 0 0 1 0-.98L8.25 9z"
      fillRule="evenodd"
    />
  </svg>
);

export default IconChevronRight;
