import React, { Component } from "react";
import styled from "styled-components";
import Row from "../atoms/Row";
import { color, font } from "../templates/ui";
import IconArrowRight from "../atoms/icons/IconArrowRight";
import { Link } from "react-router-dom";

const StyledRow = styled(Row)`
  justify-content: space-between;
  border-bottom: ${props => (props.active ? "none" : "1px solid #f1f1f1")};
`;

const Name = styled.div`
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 600;
  font-family: ${font.family.head};
  width: 130px;

  &:first-child {
    min-width: auto;

    @media only screen and (min-width: 576px) {
      min-width: 200px;
    }
  }
`;

const Item = styled.div`
  color: #797979;
  font-weight: 400;
  font-family: ${font.family.head};
`;

const Interlands = styled(Item)`
  width: 100px;
  text-align: center;
`;

const Goals = styled(Item)`
  display: none;
  width: 100px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

const Position = styled(Item)`
  display: none;
  width: 50px;

  @media only screen and (min-width: 576px) {
    width: 100px;
    display: block;
  }
`;

const Icon = styled(Item)`
  width: 34px;
  display: flex;
  justify-content: flex-end;

  @media only screen and (min-width: 992px) {
    width: 15px;
  }
`;

const StyledIconArrowRight = styled(IconArrowRight)`
  fill: ${color.primary.base};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Team = styled(Item)`
  display: none;

  @media only screen and (min-width: 992px) {
    display: block;
    width: 60px;
  }
`;

class PlayerRow extends Component {
  state = {
    height: 0
  };

  render() {
    const { name, interlands, goals, preferredPosition, href, team } = this.props;

    return (
      <StyledLink to={href} data-testid={this.props["data-testid"]}>
        <StyledRow>
          <Name>{name}</Name>
          <Interlands>{interlands}</Interlands>
          <Goals>{goals}</Goals>
          {team === "m" && <Team>Heren</Team>}
          {team === "f" && <Team>Dames</Team>}
          <Position>{preferredPosition}</Position>
          <Icon>
            <StyledIconArrowRight />
          </Icon>
        </StyledRow>
      </StyledLink>
    );
  }
}

export default PlayerRow;
