import React from "react";

const IconInstagram = ({ className }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 22 21"
    xmlSpace="preserve"
    className={className}
  >
    <desc>Created with Sketch.</desc>
    <path
      d="M10.2,20.2c-0.9,0-2,0-3.1-0.1c-1.8-0.1-3-0.5-4-1.5c-1-1-1.4-2.2-1.5-4c-0.1-1.2-0.1-2.5-0.1-3.7V9.9c0-1.2,0-2.5,0.1-3.7
	c0.1-1.8,0.5-3,1.5-4c1-1,2.2-1.4,4-1.5c1.1-0.1,2.2-0.1,3.1-0.1h2.2c0.9,0,2.1,0,3.1,0.1c1.8,0.1,3,0.5,4,1.5c1,1,1.4,2.2,1.5,4
	c0.1,1.2,0.1,2.5,0.1,3.7V11c0,1.2,0,2.5-0.1,3.7c-0.1,1.8-0.5,3-1.5,4c-1,1-2.2,1.4-4,1.5c-1.1,0.1-2.2,0.1-3.1,0.1H10.2z
	 M13.1,19.4c1.5,0,3.4-0.1,4.5-0.5c0.5-0.2,0.9-0.5,1.3-0.9c0.4-0.4,0.7-0.8,0.9-1.3c0.5-1.3,0.5-3.8,0.5-5.5l0-0.7l0-0.7
	c0-1.7,0-4.3-0.5-5.5c-0.2-0.5-0.5-0.9-0.9-1.3c-0.4-0.4-0.8-0.7-1.3-0.9c-1-0.4-2.9-0.5-4.5-0.5c-0.4,0-1.8,0-1.8,0s-1.4,0-1.8,0
	C8,1.5,6.1,1.5,5,1.9C4.5,2.2,4.1,2.4,3.7,2.8S3,3.6,2.8,4.2C2.3,5.4,2.3,8,2.3,9.7l0,0.7l0,0.7c0,1.7,0,4.3,0.5,5.5
	C3,17.2,3.3,17.6,3.7,18c0.4,0.4,0.8,0.7,1.3,0.9c1,0.4,2.9,0.5,4.5,0.5c0.4,0,1.8,0,1.8,0S12.7,19.4,13.1,19.4z M11.3,15.2
	c-2.6,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8c2.6,0,4.8,2.1,4.8,4.8S13.9,15.2,11.3,15.2z M11.3,6.5c-2.2,0-3.9,1.8-3.9,3.9
	c0,2.2,1.8,3.9,3.9,3.9s3.9-1.8,3.9-3.9C15.2,8.3,13.5,6.5,11.3,6.5z M16.8,5.7c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7
	c0.4,0,0.7,0.3,0.7,0.7S17.2,5.7,16.8,5.7z"
    />
  </svg>
);

export default IconInstagram;
