import React, { Component } from "react";
import styled from "styled-components";
import IconChevronDown from "../atoms/icons/IconChevronDown";
import { color } from "../templates/ui";
import IconChevronUp from "../atoms/icons/IconChevronUp";

const StyledSortButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #797979;
  font-size: 13px;
  font-family: "Source Sans Pro Webfont";
  font-weight: 600;
  text-transform: uppercase;
  padding: 0;
  margin: ${props => (props.centered ? "0 auto" : "0")};
`;

const StyledIconChevronUp = styled(IconChevronUp)`
  display: inline-block;
  fill: ${props => (props.direction ? color.primary.base : color.gray.darker)};
  margin-top: -3px;
`;

const StyledIconChevronDown = styled(IconChevronDown)`
  display: inline-block;
  fill: ${props => (props.direction ? color.primary.base : color.gray.darker)};
  margin-top: ${props => (props.direction ? "-2px" : "1px")};
`;

const ChevronWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 5px;
  pointer-events: none;
`;

class SortButton extends Component {
  render() {
    const { children, direction, column, centered, sortInternationals, ...props } = this.props;

    return (
      <StyledSortButton onClick={sortInternationals} value={column} {...props} centered={centered}>
        {children}
        <ChevronWrapper>
          {(direction === "asc" || !direction) && <StyledIconChevronUp direction={direction} />}
          {(direction === "desc" || !direction) && <StyledIconChevronDown direction={direction} />}
        </ChevronWrapper>
      </StyledSortButton>
    );
  }
}

export default SortButton;
