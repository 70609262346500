import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StartingPoint = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${color.primary.base};
  z-index: 3;
`;

const TimelineStartingPoint = () => <StartingPoint />;

export default TimelineStartingPoint;
