import React from "react";
import styled from "styled-components";
import Logo from "../atoms/Logo";
import { color } from "../templates/ui";
import MainMenu from "./MainMenu";
import MenuToggle from "../atoms/MenuToggle";

const StyledMainMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: ${color.body.base};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3), 0 -3px 10px 1px rgba(0, 0, 0, 0.3);
`;

const TopBar = ({ onClick }) => (
  <StyledMainMenu>
    <Logo href="https://www.knhb.nl/" />
    <MainMenu />
    <MenuToggle onClick={onClick} />
  </StyledMainMenu>
);

export default TopBar;
