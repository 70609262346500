import React from "react";
import styled from "styled-components";
import { font } from "../templates/ui";
import CardPlayerInfo from "../molecules/CardPlayerInfo";
import CardPlayerDetails from "../molecules/CardPlayerDetails";
import CardPlayerSlider from "../molecules/CardPlayerSlider";

const StyledPlayerItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 991px;
  font-family: ${font.family.head};
  margin: 0 auto 40px auto;
  padding: 0;

  @media only screen and (min-width: 768px) {
    padding: 0;
  }
`;

const StyledPlayerInfo = styled(CardPlayerInfo)`
  flex-basis: 100%;

  @media only screen and (min-width: 768px) {
    flex-basis: calc(100% / 3);
  }

  @media only screen and (min-width: 992px) {
    flex-basis: 37.5%;
  }
`;

const StyledPlayerSlider = styled(CardPlayerSlider)`
  flex-basis: 100%;
  top: 0;
  z-index: 1;

  @media only screen and (min-width: 768px) {
    flex-basis: calc(100% / 3);
    transform: scale(1.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  }

  @media only screen and (min-width: 992px) {
    flex-basis: 25%;
  }
`;

const StyledPlayerDetails = styled(CardPlayerDetails)`
  display: block;
  flex-basis: 100%;
  left: 0;

  button {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    display: block;
    flex-basis: calc(100% / 3);
  }

  @media only screen and (min-width: 992px) {
    flex-basis: 37.5%;
  }
`;

const PlayerItem = ({ name, dateOfBirth, placeOfBirth, preferredPosition, interlands, goals, images }) => (
  <StyledPlayerItem>
    <StyledPlayerInfo name={name} dateOfBirth={dateOfBirth} placeOfBirth={placeOfBirth} />
    <StyledPlayerSlider images={images} />
    <StyledPlayerDetails preferredPosition={preferredPosition} interlands={interlands} goals={goals} />
  </StyledPlayerItem>
);

export default PlayerItem;
