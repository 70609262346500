import React, { Component } from "react";
import Card from "../atoms/Card";
import styled from "styled-components";
import { color } from "../templates/ui";
import IconLoader from "../atoms/icons/IconLoader";

const StyledCardImage = styled(Card)`
  display: none;
  position: relative;
  color: ${color.body.base};
  transform: scale(1.12);
  z-index: 1;
  min-height: 149px;
  padding: 0 20px;

  @media only screen and (min-width: 440px) {
    padding: 0 30px;
  }

  @media only screen and (min-width: 768px) {
    display: block;
    max-height: 195px;
    padding: 0;
  }

  @media only screen and (min-width: 992px) {
    height: 190px;
    max-height: 169px;
    min-height: auto;
    background-color: ${color.gray.white};
  }
`;

const StyledIconLoader = styled(IconLoader)`
  max-height: 169px;
  height: 100vh;
  display: block;
  margin-left: 145px;
`;

const StyledImg = styled.div`
  max-height: 169px;
  height: 100%;
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-repeat: no-repeat;

  @media only screen and (min-width: 768px) {
    max-height: 195px;
  }

  @media only screen and (min-width: 992px) {
    max-height: 169px;
  }
`;

class CardImage extends Component {
  state = {
    slideIndex: 0,
    loading: true
  };

  componentDidMount() {
    this.setState({
      loading: false
    });
  }

  render() {
    const { className, isDetailLayout, images } = this.props;

    if (images) {
      return images.length > 1 && this.state.loading === false ? (
        <StyledCardImage className={className} isDetailLayout={isDetailLayout}>
          <StyledImg imageUrl={images[0].url} />
        </StyledCardImage>
      ) : (
        <StyledCardImage>
          {this.state.loading ? <StyledIconLoader /> : <StyledImg imageUrl="/img/slider-placeholder.png" />}
        </StyledCardImage>
      );
    }
  }
}

export default CardImage;
