import React from "react";

const IconYoutube = ({ className }) => (
  <svg
    width="49px"
    height="36px"
    viewBox="0 0 49 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <defs />
    <path
      d="M47,26.2258065 C47,30.5000936 43.5120632,34 39.255814,34 L9.74418605,34 C5.48793683,34 2,30.5000936 2,26.2258065 L2,9.77419355 C2,5.4999064 5.48793682,2 9.74418605,2 L39.255814,2 C43.5120632,2 47,5.4999064 47,9.77419355 L47,26.2258065 Z M49,26.2258065 L49,9.77419355 C49,4.39686779 44.6181694,0 39.255814,0 L9.74418605,0 C4.38183062,0 0,4.39686779 0,9.77419355 L0,26.2258065 C0,31.6031322 4.38183063,36 9.74418605,36 L39.255814,36 C44.6181694,36 49,31.6031322 49,26.2258065 Z"
      id="Shape"
      stroke="none"
      fillRule="evenodd"
    />
    <path
      d="M30.8761716,18.5010777 L20,24.8700661 L20,12.130196 L30.8761716,18.5010777 Z M34.833591,18.5013341 L18,8.64080404 L18,28.3589339 L34.833591,18.5013341 Z"
      id="Shape"
      stroke="none"
      fillRule="evenodd"
    />
  </svg>
);

export default IconYoutube;
