import React from "react";

const IconChevronUp = ({ className }) => (
  <svg width="14" height="7" viewBox="0 0 14 7" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M7.006 1.684l5.385 5.182a.504.504 0 0 0 .859-.35.492.492 0 0 0-.147-.352L7.361.48a.505.505 0 0 0-.71 0L.911 6.164a.495.495 0 0 0 0 .704.508.508 0 0 0 .712 0l5.383-5.184z"
      fillRule="nonzero"
    />
  </svg>
);

export default IconChevronUp;
