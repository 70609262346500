import React from "react";

const IconPin = ({ className }) => (
  <svg width="15" height="20" viewBox="0 0 15 20" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M7.5 0C3.375 0 .044 3.353.044 7.456c0 6.11 7.456 12.397 7.456 12.397s7.456-6.287 7.456-12.397C14.956 3.353 11.625 0 7.5 0zm0 10.985a3.54 3.54 0 0 1-3.53-3.53A3.54 3.54 0 0 1 7.5 3.927a3.54 3.54 0 0 1 3.53 3.53 3.526 3.526 0 0 1-3.53 3.53z"
      fill="#EF790C"
      fillRule="nonzero"
    />
  </svg>
);

export default IconPin;
