import React from "react";
import styled from "styled-components";
import SocialLinks from "./SocialLinks";
import CommunityMenu from "./CommunityMenu";
import { font } from "../templates/ui";

const StyledCommunityBar = styled.div`
  position: relative;
  display: none;
  background-image: url(/img/top.jpg);
  background-size: cover;
  background-position: top;
  font-size: 1.2rem;
  color: #fff;
  font-family: ${font.family.head};
  font-weight: 700;

  @media only screen and (min-width: 992px) {
    display: flex;
    justify-content: space-between;
  }
`;

const CommunityBar = () => (
  <StyledCommunityBar>
    <CommunityMenu />
    <SocialLinks />
  </StyledCommunityBar>
);

export default CommunityBar;
