import React, { useState } from "react";
import NotFound from "../pages/NotFound";

const withErrorPage = Component => props => {
  const [error, setError] = useState(null);

  if (error?.response.status === 404) {
    return <NotFound />;
  }

  return <Component {...props} setError={setError} />;
};

export default withErrorPage;
