import React, { Component } from "react";
import styled from "styled-components";
import Header from "../organisms/Header";
import Layout from "../templates/Layout";
import Container from "../atoms/Container";
import Footer from "../organisms/Footer";
import OffCanvasContainer from "../organisms/OffCanvasContainer";
import MainMenu from "../molecules/MainMenu";
import FiltersCanvas from "../organisms/offcanvas/FiltersCanvas";
import Card from "../atoms/Card";
import MatchRow from "../organisms/MatchRow";
import PlayerItem from "../organisms/PlayerItem";
import { getInternational } from "../../api/International";
import moment from "moment";
import AppLinks from "../molecules/AppLinks";
import Pagination from "../molecules/Pagination";
import Breadcrumbs, { getBreadcrumbs } from "../molecules/Breadcrumbs";
import { parseLocation, parseScore } from "../../helpers";
import { Fade } from "react-reveal";
import withErrorPage from "../hoc/withErrorPage";
import withQueryParams, { setPagination } from "../hoc/withQueryParams";

const StyledCard = styled(Card)`
  flex: auto;
  margin-bottom: 20px;
`;

const StyledContainer = styled(Container)`
  padding: 0;
`;

const StyledAppLinks = styled(AppLinks)`
  margin-bottom: 30px;
`;

class International extends Component {
  state = {
    menu_open: false,
    showFiltersCanvas: false,
    international: {
      images: [],
      matches: {
        data: [],
        meta: {
          pagination: {
            links: {}
          }
        }
      },
      preferredPosition: "-",
      score: [],
      stats: {
        caps: 0,
        goals: 0
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      this.fetchInternational();
    }
  }

  fetchInternational = () => {
    const data = {
      id: this.props.match.params.id,
      matches_page: this.props.queryParams.pagination.page
    };

    getInternational(data)
      .then(response =>
        this.setState({
          international: response.data
        })
      )
      .catch(this.props.setError);
  };

  handlePageChange = pageNumber => this.props.queryParamsDispatch(setPagination(pageNumber));

  toggleStateBool = fieldName => {
    this.setState(prevState => {
      return {
        ...prevState,
        [fieldName]: !prevState[fieldName]
      };
    });
  };

  componentDidMount() {
    this.fetchInternational();
  }

  getMatchIndex(index) {
    const totalMatchCount = this.state.international.matches.meta.pagination.total;
    const matchesPerPage = this.state.international.matches.meta.pagination.per_page;
    const currentPage = this.state.international.matches.meta.pagination.current_page;
    const skip = (currentPage - 1) * matchesPerPage;
    const count = totalMatchCount - (skip + index);

    return count;
  }

  render() {
    return (
      <>
        <Header toggleOffCanvas={() => this.setState({ menu_open: !this.state.menu_open })} />
        <Layout>
          <Container>
            <StyledAppLinks
              onClick={() => this.toggleStateBool("showFiltersCanvas")}
              breadcrumbs={this.props.location.state?.breadcrumbs || []}
            />
            <Breadcrumbs links={getBreadcrumbs(this.props.location, this.state.international.name)} />
            <PlayerItem
              isDetailLayout={true}
              name={this.state.international.name}
              dateOfBirth={
                !!this.state.international.dateOfBirth
                  ? moment(this.state.international.dateOfBirth).format("DD-MM-YYYY")
                  : null
              }
              placeOfBirth={this.state.international.placeOfBirth}
              images={this.state.international.images}
              preferredPosition={this.state.international.preferredPosition}
              interlands={this.state.international.stats.caps}
              goals={this.state.international.stats.goals}
            />
            <StyledContainer isCompact>
              <StyledCard>
                {this.state.international.matches.data.map((match, index) => (
                  <Fade key={match.id} bottom distance="40px" delay={index * 50}>
                    <MatchRow
                      key={match.id}
                      href={{
                        pathname: `/wedstrijden/${match.id}`,
                        state: { breadcrumbs: getBreadcrumbs(this.props.location, this.state.international.name) }
                      }}
                      date={moment(match.datetime).format("DD-MM-YYYY")}
                      location={parseLocation(match.location)}
                      homeFlag={match.teams.home.flag}
                      awayFlag={match.teams.away.flag}
                      homeTeam={match.teams.home.name}
                      awayTeam={match.teams.away.name}
                      homeTeamShort={match.teams.home.isoCode}
                      awayTeamShort={match.teams.away.isoCode}
                      homeScore={parseScore(match.scores).home}
                      awayScore={parseScore(match.scores).away}
                      homeScoreExtended={parseScore(match.scores).homeExtended}
                      awayScoreExtended={parseScore(match.scores).awayExtended}
                      playerActions={match.player_actions}
                      type={match.tournament.event.label}
                      matchIndex={this.getMatchIndex(index)}
                      international={this.state.international}
                    />
                  </Fade>
                ))}
              </StyledCard>
              {this.state.international.matches.meta.pagination.total !== 0 &&
              this.state.international.matches.meta.pagination.total > 10 ? (
                <Pagination
                  total={this.state.international.matches.meta.pagination.total}
                  count={this.state.international.matches.meta.pagination.count}
                  perPage={this.state.international.matches.meta.pagination.per_page}
                  currentPage={this.props.queryParams.pagination.page}
                  totalPages={this.state.international.matches.meta.pagination.total_pages}
                  next={this.state.international.matches.meta.pagination.links.next}
                  previous={this.state.international.matches.meta.pagination.links.previous}
                  handlePageChange={this.handlePageChange}
                />
              ) : null}
            </StyledContainer>
          </Container>
        </Layout>
        <Footer />
        <OffCanvasContainer containerOpen={this.state.menu_open} isMenu={true}>
          <MainMenu isOffCanvasMenu={true} />
        </OffCanvasContainer>
        <FiltersCanvas toggle={() => this.toggleStateBool("showFiltersCanvas")} open={this.state.showFiltersCanvas} />
      </>
    );
  }
}

export default withQueryParams(withErrorPage(International));
