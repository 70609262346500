import React from "react";
import Menu from "../atoms/Menu";
import MenuItem from "../atoms/MenuItem";
import MenuLink from "../atoms/MenuLink";
import styled from "styled-components";

const StyledMenu = styled(Menu)`
  flex-direction: column;
`;

const StyledMenuItem = styled(MenuItem)`
  width: 100%;
  line-height: 1.2rem;
`;

const DirectMenu = () => (
  <StyledMenu>
    <StyledMenuItem>
      <MenuLink href="http://knhb.nl/kenniscentrum" isFooterLink={true}>
        Kenniscentrum
      </MenuLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <MenuLink href="http://knhb.nl/" isFooterLink={true}>
        KNHB
      </MenuLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <MenuLink href="https://www.knhb.nl/kenniscentrum/thema/standenmotor" isFooterLink={true}>
        Standenmotor
      </MenuLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <MenuLink href="http://knhb.nl/agenda" isFooterLink={true}>
        Agenda
      </MenuLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <MenuLink href="http://knhb.nl/groepen" isFooterLink={true}>
        Groepen
      </MenuLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <MenuLink href="http://knhb.nl/clubs" isFooterLink={true}>
        Clubs
      </MenuLink>
    </StyledMenuItem>
  </StyledMenu>
);

export default DirectMenu;
