import React from "react";
import styled from "styled-components";
import Button from "./Button";
import { color } from "../templates/ui";
import IconMagnifier from "./icons/IconMagnifier";

const StyledFilterButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.body.base};
  color: ${color.primary.base};
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: ${color.body.base};
  }

  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const StyledIconMagnifier = styled(IconMagnifier)`
  fill: ${color.primary.base};
  width: 32px;
  height: 32px;
  flex-shrink: 0;
`;

const FilterButton = ({ onClick }) => (
  <StyledFilterButton onClick={onClick} data-testid="filter-button">
    <StyledIconMagnifier />
  </StyledFilterButton>
);

export default FilterButton;
