import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Breadcrumb from "../atoms/Breadcrumb";

const StyledBreadcrumbs = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

interface BreadcrumbsProps {
  links: Link[];
}

interface Link {
  name: string;
  pathname: string;
  search: string;
}

interface Location {
  pathname: string;
  state: State;
  search: string;
  hash: string;
  key: string;
}

interface State {
  breadcrumbs: Link[];
}

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ links = [] }) => (
  <StyledBreadcrumbs>
    {links.length > 3 && (
      <Breadcrumb text='&nbsp;' link={{
        pathname: links[0].pathname,
        search: links[0].search
      }}/>
    )}
    {links.map((item, index) => (
      <Breadcrumb key={index} text={item.name} link={{pathname: item.pathname, search: item.search, state: { breadcrumbs: links.slice(0, index)}}} />
    )).slice(Math.max(0,links.length - 3), links.length)}
  </StyledBreadcrumbs>
);

export const getBreadcrumbs = (location: Location, currentName: string): Link[] => {
  return [
    ...location.state?.breadcrumbs || [],
    {
      name: currentName,
      pathname: location.pathname,
      search: location.search
    }
  ];
};

export default Breadcrumbs;
