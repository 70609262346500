import React, { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import { Route, Switch, withRouter, RouteComponentProps } from "react-router-dom";
import Tournaments from "./pages/Tournaments";
import Tournament from "./pages/Tournament";
import Matches from "./pages/Matches";
import Internationals from "./pages/Internationals";
import International from "./pages/International";
import Match from "./pages/Match";
import NotFound from "./pages/NotFound";

const StyledApp = styled.div`
  width: 100%;
  height: 100%;
  display: table;
  table-layout: fixed;
  line-height: normal;
  background: #f7f7f7;
`;

const App: FunctionComponent<RouteComponentProps> = ({ location }) => {
  useEffect(() => {
    (document.getElementById("root") as HTMLDivElement).scrollTop = 0;
  }, [location]);

  return (
    <StyledApp>
      <Switch>
        <Route path="/" exact component={Tournaments} />
        <Route path="/toernooien/:id" exact component={Tournament} />
        <Route path="/wedstrijden" exact component={Matches} />
        <Route path="/wedstrijden/:id" exact component={Match} />
        <Route path="/internationals" exact component={Internationals} />
        <Route path="/internationals/:id" exact component={International} />
        <Route component={NotFound} />
      </Switch>
    </StyledApp>
  );
};

export default withRouter(App);
