import React from "react";
import Card from "../atoms/Card";
import styled from "styled-components";
import { color } from "../templates/ui";
import Flag from "../atoms/Flag";
import IconGoal from "../atoms/icons/IconGoal";
import Score from "../atoms/Score";

const StyledResultBlock = styled(Card)`
  background-color: ${color.body.base};
`;

const CardInner = styled.div`
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  @media only screen and (min-width: 350px) {
    padding: 20px;
  }
`;

const CardText = styled.h3`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 16px;
  text-align: center;
  min-height: 50px;

  @media only screen and (min-width: 992px) {
    font-size: 18px;
  }
`;

const CardInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  width: 80px;

  @media only screen and (min-width: 768px) {
    width: 50px;
  }

  @media only screen and (min-width: 992px) {
    width: 80px;
  }

  @media only screen and (min-width: 1200px) {
    width: 90px;
  }
`;

const StyledScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${color.primary.base};
`;

const StyledFlag = styled(Flag)`
  display: block;
  width: 40px;
  height: 24px;
  margin-bottom: 10px;
`;

const StyledScore = styled(Score)`
  font-size: 32px;
  border-bottom: 2px solid #f1f1f1;
`;

const Shootouts = styled.div`
  padding-top: 10px;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;

  @media only screen and (min-width: 992px) {
    font-size: 26px;
    width: 80px;
  }
`;

const Result = styled.div`
  color: ${color.primary.base};
  font-size: 22px;
  font-weight: 700;
  border-bottom: 3px solid #e0e0e0;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const StyledIconGoal = styled(IconGoal)`
  width: 24px;
  height: 21px;

  @media only screen and (min-width: 992px) {
    width: 32px;
    height: 28px;
  }
`;

const ResultBlock = ({
  className,
  homeFlag,
  homeTeam,
  homeScore,
  homeScoreExtended,
  awayFlag,
  awayScore,
  awayTeam,
  awayScoreExtended
}) => (
  <StyledResultBlock className={className}>
    <CardInner>
      <Result>Uitslag</Result>
      <Wrapper>
        <CardInfo>
          <StyledFlag src={homeFlag} />
          <CardText>{homeTeam}</CardText>
        </CardInfo>
        <StyledScoreWrapper>
          <StyledScore>
            {homeScore}
            <span>-</span>
            {awayScore}
          </StyledScore>
          {homeScoreExtended && (
            <Shootouts>
              {homeScoreExtended}
              <StyledIconGoal />
              {awayScoreExtended}
            </Shootouts>
          )}
        </StyledScoreWrapper>
        <CardInfo>
          <StyledFlag src={awayFlag} />
          <CardText>{awayTeam}</CardText>
        </CardInfo>
      </Wrapper>
    </CardInner>
  </StyledResultBlock>
);

export default ResultBlock;
