import React from "react";
import styled from "styled-components";
import Select from "../atoms/Select";
import InputLabel from "../atoms/InputLabel";

const StyledSelectItem = styled.div`
  position: relative;
`;

const SelectWrapper = styled.div`
  position: relative;

  &:after {
    content: "";
    display: block;
    background-image: url("data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjE0cHgiIGhlaWdodD0iN3B4IiB2aWV3Qm94PSIwIDAgMTQgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJUb2Vybm9vaWVuLWxpanN0L3RpamRsaWpuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjkwLjAwMDAwMCwgLTI1MC4wMDAwMDApIiBmaWxsPSIjRUY3OTBDIj4KICAgICAgICAgICAgPGcgaWQ9IkZpbHRlcnMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzMC4wMDAwMDAsIDE0MC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJKYWFydGFsIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MDEuMDAwMDAwLCA2Ny4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iY2hldnJvbi1kb3duIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1OS4wMDAwMDAsIDQzLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNNy4wMDgxOTY4OSw1LjY2NDE1Mzg2IEwxLjYyMjUyMzYxLDAuNDgxNzEzNTMgQzEuNDI1OTI4MDEsMC4yODU3MDMyMDYgMS4xMDc4MDM4NywwLjI4NTcwMzIwNiAwLjkxMTIwODI3MSwwLjQ4MTcxMzUzIEMwLjgxNzIzMjE3OSwwLjU3NDQ1NTU0OSAwLjc2NDMzMTk3NCwwLjcwMDk4MzY4MSAwLjc2NDMzMTk3NCwwLjgzMzAxNjIwNyBDMC43NjQzMzE5NzQsMC45NjUwNDg3MzMgMC44MTcyMzIxNzksMS4wOTE1NzY4NyAwLjkxMTIwODI3MSwxLjE4NDMxODg5IEw2LjY1MjUzOTIyLDYuODY3NTgzMjggQzYuODQ4NTEzODYsNy4wNjIxMDYyNSA3LjE2NjQyODI2LDcuMDYyMTA2MjUgNy4zNjI0MDI5LDYuODY3NTgzMjggTDEzLjEwMjI4MjIsMS4xODQzMTg4OSBDMTMuMjk4MjU2OCwwLjk4OTc5NTkxNSAxMy4yOTgyNTY4LDAuNjc2MjM2NSAxMy4xMDIyODIyLDAuNDgwMjYxODY2IEMxMi45MDQ3MTc2LDAuMjg2NjA5ODg0IDEyLjU4ODUzMTQsMC4yODY2MDk4ODQgMTIuMzkwOTY2OCwwLjQ4MDI2MTg2NiBMNy4wMDgxOTY4OSw1LjY2NDE1Mzg2IFoiIGlkPSJQYXRoIj48L3BhdGg+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
    background-repeat: no-repeat;
    width: 14px;
    height: 8px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

const SelectItem = ({ value = "", placeholder, options = [], labelText, className, onChange }) => (
  <StyledSelectItem className={className}>
    {labelText && <InputLabel>{labelText}</InputLabel>}
    <SelectWrapper>
      <Select value={value} placeholder={placeholder} onChange={onChange}>
        <option value="">{placeholder}</option>
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </Select>
    </SelectWrapper>
  </StyledSelectItem>
);

export default SelectItem;
