import React from "react";
import styled from "styled-components";
import { font } from "../templates/ui";
import IconPin from "../atoms/icons/IconPin";

const StyledLocation = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const LocationText = styled.div`
  font-size: 16px;
  font-family: ${font.family.head};
  font-weight: 600;
  color: #797979;
  margin-left: 10px;
`;

const Location = ({ className, location, title }) => (
  <StyledLocation className={className}>
    <IconPin />
    <LocationText title={title}>{location}</LocationText>
  </StyledLocation>
);

export default Location;
