import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledTimelineButton = styled.button`
  position: relative;
  background-color: ${color.body.base};
  border: 1px solid ${props => (props.index || props.previousSlides ? `${color.primary.base}` : `${color.gray.base}`)};
  cursor: pointer;
  outline: none;
  margin: 0 35px;
  width: 24px;
  height: 24px;
  border-radius: 12px;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: ${color.primary.base};
  }
`;

const TimelineButton = ({ previousSlides, index, onClick, children }) => (
  <StyledTimelineButton previousSlides={previousSlides} index={index} onClick={onClick}>
    {children}
  </StyledTimelineButton>
);

export default TimelineButton;
