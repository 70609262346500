import React, { Component } from "react";
import styled from "styled-components";
import Header from "../organisms/Header";
import Layout from "../templates/Layout";
import Container from "../atoms/Container";
import Footer from "../organisms/Footer";
import OffCanvasContainer from "../organisms/OffCanvasContainer";
import MainMenu from "../molecules/MainMenu";
import FiltersCanvas from "../organisms/offcanvas/FiltersCanvas";
import Card from "../atoms/Card";
import Pagination from "../molecules/Pagination";
import MatchRow from "../organisms/MatchRow";
import moment from "moment";
import { getMatches } from "../../api/Matches";
import AppLinks from "../molecules/AppLinks";
import TournamentFilter from "../molecules/TournamentFilter";
import { parseLocation, parseScore } from "../../helpers";
import IconLoader from "../atoms/icons/IconLoader";
import { Fade } from "react-reveal";
import Label from "../atoms/Label";
import Dock from "../molecules/Dock";
import FilterButton from "../atoms/FilterButton";
import { getBreadcrumbs } from "../molecules/Breadcrumbs";
import produce from "immer";
import withQueryParams, { setFilters, setPagination } from "../hoc/withQueryParams";
import { withRouter } from "react-router";

const StyledCard = styled(Card)`
  flex: auto;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const StyledContainer = styled(Container)`
  padding: 0;
`;

const StyledIconLoader = styled(IconLoader)`
  display: block;
  margin: 0 auto;
  width: 52px;
  height: 460px;

  @media only screen and (min-width: 992px) {
    height: 490px;
  }
`;

class Matches extends Component {
  state = {
    menu_open: false,
    showFiltersCanvas: false,
    matches: {
      data: null,
      meta: {
        pagination: {
          links: {}
        },
        filters: {
          years: [],
          event_types: [],
          classifications: null
        }
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      this.fetchMatches();
    }
  }

  handleYearFromChange = event => {
    this.props.queryParamsDispatch(
      setFilters({
        ...this.props.queryParams.filters,
        yearFrom: event.target.value
      })
    );
  };

  handleYearTillChange = event => {
    this.props.queryParamsDispatch(
      setFilters({
        ...this.props.queryParams.filters,
        yearTill: event.target.value
      })
    );
  };

  handleEventTypeChange = event => {
    this.props.queryParamsDispatch(
      setFilters({
        ...this.props.queryParams.filters,
        eventType: event.target.value
      })
    );
  };

  handleOpponentTypeChange = event => {
    this.props.queryParamsDispatch(
      setFilters({
        ...this.props.queryParams.filters,
        opponent: event.target.value
      })
    );
  };

  handleGenderChange = event => {
    this.props.queryParamsDispatch(
      setFilters({
        ...this.props.queryParams.filters,
        gender: event.target.value
      })
    );
  };

  resetFilters = event => {
    event.preventDefault();
    this.props.queryParamsDispatch(setFilters({}));
  };

  handlePageChange = pageNumber => this.props.queryParamsDispatch(setPagination(pageNumber));

  toggleStateBool = fieldName => {
    this.setState(prevState => {
      return {
        ...prevState,
        [fieldName]: !prevState[fieldName]
      };
    });
  };

  fetchMatches = () => {
    this.setState(
      produce(draft => {
        draft.matches.data = null;
      })
    );

    getMatches({
      page: this.props.queryParams.pagination.page,
      team: this.props.queryParams.filters.gender,
      from: this.props.queryParams.filters.yearFrom,
      till: this.props.queryParams.filters.yearTill,
      opponent: this.props.queryParams.filters.opponent,
      event: this.props.queryParams.filters.eventType
    }).then(response =>
      this.setState({
        matches: response.data
      })
    );
  };

  componentDidMount() {
    this.fetchMatches();
  }

  getAllMatches = () => {
    if (this.state.matches.data === null) {
      return <StyledIconLoader />;
    }

    if (this.state.matches.data.length === 0) {
      return <Label>Er zijn geen wedstrijden gevonden</Label>;
    }

    return this.state.matches.data.map((match, index) => (
      <Fade bottom distance="40px" delay={index * 50} key={match.id}>
        <MatchRow
          key={match.id}
          href={{
            pathname: `/wedstrijden/${match.id}`,
            state: { breadcrumbs: getBreadcrumbs(this.props.location, "Wedstrijden") }
          }}
          date={moment(match.datetime).format("DD-MM-YYYY")}
          location={parseLocation(match.location)}
          homeFlag={match.teams.home.flag}
          awayFlag={match.teams.away.flag}
          homeTeam={match.teams.home.name}
          awayTeam={match.teams.away.name}
          homeTeamShort={match.teams.home.isoCode}
          awayTeamShort={match.teams.away.isoCode}
          homeScore={parseScore(match.scores).home}
          awayScore={parseScore(match.scores).away}
          homeScoreExtended={parseScore(match.scores).homeExtended}
          awayScoreExtended={parseScore(match.scores).awayExtended}
          type={match.tournament.event.label}
          team={match.tournament.gender}
        />
      </Fade>
    ));
  };

  render() {
    return (
      <>
        <Header toggleOffCanvas={() => this.setState({ menu_open: !this.state.menu_open })} />
        <Layout>
          <Container>
            <AppLinks
              results={this.state.matches.meta.pagination.total}
              breadcrumbs={this.props.location.state?.breadcrumbs || []}
            />
            <TournamentFilter
              onGenderChange={this.handleGenderChange}
              gender={this.props.queryParams.filters.gender ?? ""}
              onYearFromChange={this.handleYearFromChange}
              onYearTillChange={this.handleYearTillChange}
              yearFrom={this.props.queryParams.filters.yearFrom ?? ""}
              yearTill={this.props.queryParams.filters.yearTill ?? ""}
              yearFromOptions={this.state.matches.meta.filters.years}
              yearTillOptions={this.state.matches.meta.filters.years}
              onEventTypeChange={this.handleEventTypeChange}
              onOpponentTypeChange={this.handleOpponentTypeChange}
              eventType={this.props.queryParams.filters.eventType}
              eventTypeOptions={this.state.matches.meta.filters.event_types}
              opponentOptions={this.state.matches.meta.filters.opponents}
              opponent={this.props.queryParams.filters.opponent ?? ""}
              resetFilters={this.resetFilters}
            />
            <StyledContainer>
              <StyledCard>{this.getAllMatches()}</StyledCard>
              {this.state.matches.meta.pagination.total !== 0 && this.state.matches.meta.pagination.total > 10 ? (
                <Pagination
                  total={this.state.matches.meta.pagination.total}
                  count={this.state.matches.meta.pagination.count}
                  perPage={this.state.matches.meta.pagination.per_page}
                  currentPage={this.props.queryParams.pagination.page}
                  totalPages={this.state.matches.meta.pagination.total_pages}
                  next={this.state.matches.meta.pagination.links.next}
                  previous={this.state.matches.meta.pagination.links.previous}
                  handlePageChange={this.handlePageChange}
                />
              ) : null}
            </StyledContainer>
          </Container>
        </Layout>
        <Footer />
        <OffCanvasContainer containerOpen={this.state.menu_open} isMenu={true}>
          <MainMenu isOffCanvasMenu={true} />
        </OffCanvasContainer>
        <FiltersCanvas toggle={() => this.toggleStateBool("showFiltersCanvas")} open={this.state.showFiltersCanvas}>
          <TournamentFilter
            onGenderChange={this.handleGenderChange}
            gender={this.props.queryParams.filters.gender ?? ""}
            onYearFromChange={this.handleYearFromChange}
            onYearTillChange={this.handleYearTillChange}
            yearFrom={this.props.queryParams.filters.yearFrom ?? ""}
            yearTill={this.props.queryParams.filters.yearTill ?? ""}
            yearFromOptions={this.state.matches.meta.filters.years}
            yearTillOptions={this.state.matches.meta.filters.years}
            onEventTypeChange={this.handleEventTypeChange}
            onOpponentTypeChange={this.handleOpponentTypeChange}
            eventType={this.props.queryParams.filters.eventType ?? ""}
            eventTypeOptions={this.state.matches.meta.filters.event_types}
            opponentOptions={this.state.matches.meta.filters.opponents}
            opponent={this.state.opponent}
            resetFilters={this.resetFilters}
            onClose={event => {
              event.preventDefault();
              this.toggleStateBool("showFiltersCanvas");
            }}
          />
        </FiltersCanvas>
        <Dock>
          <FilterButton onClick={() => this.toggleStateBool("showFiltersCanvas")}>Toon filters</FilterButton>
        </Dock>
      </>
    );
  }
}

export default withQueryParams(withRouter(Matches));
