import styled from "styled-components";
import { color } from "../templates/ui";

const TimelineProgress = styled.div`
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  width: calc((${props => props.totalSlides} * 94px) - 94px);
  height: ${props => (props.previousSlides ? "4px" : "2px")};
  background-color: ${props => (props.previousSlides ? `${color.primary.base}` : "#ECECEC")};
  background-color: ${props => (props.previousSlides ? "red" : "#ECECEC")};

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: calc(((${props => props.totalSlides} * 94px) / ${props => props.totalSlides}) * ${props => props.index});
    height: 4px;
    background-color: ${color.primary.base};
    transition: width 0.4s ease-in-out;
  }
`;

export default TimelineProgress;
