import React from "react";
import styled from "styled-components";
import { font } from "../templates/ui";
import CardLocation from "../molecules/CardLocation";
import ResultBlock from "./ResultBlock";
import CardMatchInfo from "../molecules/CardMatchInfo";

const StyledMatchBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 991px;
  font-family: ${font.family.head};
  margin: 0 auto 40px auto;
  padding: 0;

  @media only screen and (min-width: 768px) {
    padding: 0;
  }
`;

const StyledMatchInfo = styled(CardMatchInfo)`
  flex-basis: 100%;

  @media only screen and (min-width: 768px) {
    flex-basis: calc(100% / 3);
  }
`;

const StyledResultBlock = styled(ResultBlock)`
  flex-basis: 100%;
  top: 0;
  z-index: 1;

  @media only screen and (min-width: 768px) {
    flex-basis: calc(100% / 3);
    transform: scale(1.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  }
`;

const StyledCardLocation = styled(CardLocation)`
  flex-basis: 100%;
  left: 0;

  button {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    display: block;
    flex-basis: calc(100% / 3);
  }
`;

const MatchBlock = ({
  date,
  year,
  tournamentType,
  homeFlag,
  homeTeam,
  homeScore,
  awayScore,
  homeScoreExtended,
  awayScoreExtended,
  awayFlag,
  awayTeam,
  country,
  city,
  href
}) => (
  <StyledMatchBlock>
    <StyledMatchInfo date={date} year={year} tournamentType={tournamentType} />
    <StyledResultBlock
      homeFlag={homeFlag}
      homeTeam={homeTeam}
      homeScore={homeScore}
      awayScore={awayScore}
      homeScoreExtended={homeScoreExtended}
      awayScoreExtended={awayScoreExtended}
      awayFlag={awayFlag}
      awayTeam={awayTeam}
    />
    <StyledCardLocation country={country} city={city} href={href} />
  </StyledMatchBlock>
);

export default MatchBlock;
