import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: ${color.body.base};
  border-bottom: 1px solid #f1f1f1;
  transition: background-color 0.1s ease-in-out;

  &:hover,
  &:focus {
    background-color: ${color.gray.lighter};
  }
`;

const Row = ({ children, className }) => <StyledRow className={className}>{children}</StyledRow>;

export default Row;
