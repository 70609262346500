import React from "react";

const IconCalendar = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="#EF790C" fillRule="evenodd">
      <path
        d="M17.712 2.204h-.918v1.101c0 .911-.742 1.653-1.653 1.653a1.655 1.655 0 0 1-1.653-1.653V2.204h-1.836v1.101c0 .911-.741 1.653-1.653 1.653a1.655 1.655 0 0 1-1.652-1.653V2.204H6.51v1.101c0 .911-.741 1.653-1.652 1.653a1.654 1.654 0 0 1-1.653-1.653V2.204h-.918A2.206 2.206 0 0 0 .083 4.407v13.39c0 1.215.989 2.203 2.204 2.203h15.425a2.206 2.206 0 0 0 2.204-2.203V4.407a2.206 2.206 0 0 0-2.204-2.203zm.734 15.593c0 .404-.33.734-.734.734H2.287a.735.735 0 0 1-.734-.734V7.345c0-.406.329-.735.734-.735h15.425c.405 0 .734.33.734.735v10.452z"
        fillRule="nonzero"
      />
      <path d="M4.858 4.407c.609 0 1.102-.493 1.102-1.102V1.102a1.102 1.102 0 0 0-2.204 0v2.203c0 .609.494 1.102 1.102 1.102zM10 4.407c.608 0 1.1-.493 1.1-1.102V1.102a1.101 1.101 0 1 0-2.202 0v2.203c0 .609.493 1.102 1.102 1.102zM15.14 4.407c.61 0 1.103-.493 1.103-1.102V1.102a1.102 1.102 0 0 0-2.204 0v2.203c0 .609.493 1.102 1.102 1.102zM5.14 8.157H3.912a.615.615 0 0 0-.615.615v1.23c0 .34.276.615.615.615h1.23c.34 0 .615-.276.615-.615v-1.23a.615.615 0 0 0-.615-.615zM8.79 8.157H7.56a.615.615 0 0 0-.615.615v1.23c0 .34.276.615.615.615h1.23c.34 0 .615-.276.615-.615v-1.23a.615.615 0 0 0-.615-.615zM12.439 8.157h-1.23a.615.615 0 0 0-.614.615v1.23c0 .34.275.615.615.615h1.229c.34 0 .615-.276.615-.615v-1.23a.615.615 0 0 0-.615-.615zM16.088 8.157h-1.23a.615.615 0 0 0-.614.615v1.23c0 .34.275.615.614.615h1.23c.34 0 .615-.276.615-.615v-1.23a.615.615 0 0 0-.615-.615zM5.14 11.341H3.912a.615.615 0 0 0-.615.615v1.23c0 .34.276.615.615.615h1.23c.34 0 .615-.276.615-.615v-1.23a.615.615 0 0 0-.615-.615zM8.79 11.341H7.56a.615.615 0 0 0-.615.615v1.23c0 .34.276.615.615.615h1.23c.34 0 .615-.276.615-.615v-1.23a.615.615 0 0 0-.615-.615zM12.439 11.341h-1.23a.615.615 0 0 0-.614.615v1.23c0 .34.275.615.615.615h1.229c.34 0 .615-.276.615-.615v-1.23a.615.615 0 0 0-.615-.615zM16.088 11.341h-1.23a.615.615 0 0 0-.614.615v1.23c0 .34.275.615.614.615h1.23c.34 0 .615-.276.615-.615v-1.23a.615.615 0 0 0-.615-.615zM5.14 14.525H3.912a.615.615 0 0 0-.615.615v1.23c0 .34.276.614.615.614h1.23c.34 0 .615-.275.615-.615V15.14a.615.615 0 0 0-.615-.615zM8.79 14.525H7.56a.615.615 0 0 0-.615.615v1.23c0 .34.276.614.615.614h1.23c.34 0 .615-.275.615-.615V15.14a.615.615 0 0 0-.615-.615zM12.439 14.525h-1.23a.615.615 0 0 0-.614.615v1.23c0 .34.275.614.615.614h1.229c.34 0 .615-.275.615-.615V15.14a.615.615 0 0 0-.615-.615z" />
    </g>
  </svg>
);

export default IconCalendar;
