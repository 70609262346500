import React from "react";
import styled from "styled-components";
import { font } from "../templates/ui";
import IconTrophy from "../atoms/icons/IconTrophy";

const StyledChampionShip = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const ChampionShipText = styled.div`
  font-size: 16px;
  font-family: ${font.family.head};
  font-weight: 600;
  color: #797979;
  margin-left: 10px;
`;

const Spacer = styled.div`
  width: 52px;
`;

const ChampionShip = ({ className, type, hideIcon }) => (
  <StyledChampionShip className={className}>
    {type ? (
      <>
        {!hideIcon && <IconTrophy />}
        <ChampionShipText>{type}</ChampionShipText>
      </>
    ) : (
      <Spacer />
    )}
  </StyledChampionShip>
);

export default ChampionShip;
