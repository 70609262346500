import React from "react";

const IconTrophy = ({ className }) => (
  <svg width="20" height="19" viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g fill="#EF790C" fillRule="evenodd">
      <path d="M7.98 12.727v3.57c0 .92-.555 1.985-1.292 2.477l-.021.014h6.06l-.016-.011c-.723-.487-1.248-1.528-1.248-2.48v-3.57H7.981z" />
      <path
        d="M20 2.118l-.002-.217a.829.829 0 0 0-.83-.827h-2.81l.2-1.074H3.42l.2 1.074H.833a.829.829 0 0 0-.831.827L0 2.13c-.007 1.365-.02 3.907 1.582 5.508.864.864 2.057 1.31 3.546 1.33.402 1.086 1.546 1.941 2.69 1.941h4.34c1.144 0 2.288-.854 2.69-1.941 1.495-.018 2.693-.465 3.56-1.332C20.017 6.03 20.005 3.485 20 2.118zm-18.336.61h2.264l.852 4.571c-.868-.068-1.532-.34-2.019-.826-.941-.941-1.08-2.58-1.097-3.746zM17.232 6.47c-.491.49-1.16.763-2.034.83l.852-4.573h2.285c-.018 1.167-.16 2.8-1.103 3.743z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default IconTrophy;
