import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";

const StyledLabel = styled.label`
  display: block;
  color: ${color.gray.darker};
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  font-family: ${font.family.head};
  text-transform: uppercase;
  text-align: left;
`;

const InputLabel = ({ children, className }) => <StyledLabel className={className}>{children}</StyledLabel>;

export default InputLabel;
