import React from "react";
import Card from "../atoms/Card";
import styled from "styled-components";
import { color, font } from "../templates/ui";

const StyledCardResults = styled(Card)`
  color: ${color.primary.base};
  background-color: ${color.body.base};
  top: -10px;
  position: relative;
`;

const CardInner = styled.div`
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ResultsNumberContainer = styled.div`
  flex: 0 0 100%;
  text-align: center;
`;

const CardLabel = styled.label`
  display: block;
  font-size: 16px;
  text-transform: uppercase;
`;

const Year = styled.h1`
  margin: 0;
  font-size: 52px;
  font-family: ${font.family.alt};
  line-height: 3.4rem;
  padding: 20px 0 30px 0;

  @media only screen and (min-width: 1200px) {
    font-size: 65px;
  }
`;

const CardResults = ({ className, ranking }) => (
  <StyledCardResults className={className}>
    <CardInner>
      <ResultsNumberContainer>
        <CardLabel>Resultaat</CardLabel>
        {ranking && (
          <Year>
            {ranking}
            <sup>e</sup>
          </Year>
        )}
      </ResultsNumberContainer>
    </CardInner>
  </StyledCardResults>
);

export default CardResults;
