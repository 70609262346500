import React from "react";

const IconYellowCard = ({ className }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M.6 18h16.8a.6.6 0 0 0 .6-.6V.6a.6.6 0 0 0-.6-.6H.6a.6.6 0 0 0-.6.6v16.8a.6.6 0 0 0 .6.6z"
      fill="#FACE40"
      fillRule="evenodd"
    />
  </svg>
);

export default IconYellowCard;
