import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";

const StyledCardButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: ${color.body.hover};
  border: none;
  color: #9e9e9e;
  width: 100%;
  font-family: ${font.family.head};
  font-size: 16px;
  text-align: left;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
`;

const CardButton = ({ children, className, tournamentId, filterEvent }) => (
  <StyledCardButton className={className} onClick={filterEvent} value={tournamentId}>
    {children}
  </StyledCardButton>
);

export default CardButton;
