import React from "react";

const IconChevronDown = ({ className }) => (
  <svg width="14" height="7" viewBox="0 0 14 7" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M7.008 5.664L1.623.482a.504.504 0 0 0-.859.351c0 .132.053.259.147.351l5.742 5.684a.505.505 0 0 0 .71 0l5.74-5.684a.495.495 0 0 0 0-.704.508.508 0 0 0-.712 0L7.008 5.664z"
      fillRule="evenodd"
    />
  </svg>
);

export default IconChevronDown;
