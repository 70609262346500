import React from "react";

const IconCross = ({ className }) => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M5.494 8L.105 13.389a.353.353 0 0 0-.002.5l2.008 2.008a.353.353 0 0 0 .5-.002L8 10.506l5.389 5.389c.14.14.362.14.5.002l2.008-2.008a.353.353 0 0 0-.002-.5L10.506 8l5.389-5.389c.14-.14.14-.362.002-.5L13.889.103a.353.353 0 0 0-.5.002L8 5.494 2.611.105a.353.353 0 0 0-.5-.002L.103 2.111a.353.353 0 0 0 .002.5L5.494 8z"
      fillRule="evenodd"
    />
  </svg>
);

export default IconCross;
