import React from "react";
import styled from "styled-components";

const StyledLogo = styled.div`
  display: block;
  width: ${props => {
    if (props.isDijLogo) {
      return "70px";
    }
    if (props.isRamdathLogo) {
      return "96px";
    }

    return "176px";
  }};
  height: ${props => {
    if (props.isDijLogo) {
      return "35px";
    }
    if (props.isRamdathLogo) {
      return "16px";
    }

    return "50px";
  }};
  background-image: ${props => {
    if (props.isFooterLogo) {
      return "url(/img/knhb-border.svg)";
    }
    if (props.isDijLogo) {
      return "url(/img/deinternetjongens.svg)";
    }
    if (props.isRamdathLogo) {
      return "url(/img/ramdath.svg)";
    }

    return "url(/img/knhb.svg)";
  }};
  background-color: #ef790c;
  background-size: contain;
  background-position: 50%;
  text-indent: -1200px;
  background-repeat: no-repeat;

  @media only screen and (min-width: 992px) {
    width: ${props => {
      if (props.isDijLogo) {
        return "70px";
      }
      if (props.isRamdathLogo) {
        return "96px";
      }

      return "247px";
    }};

    height: ${props => {
      if (props.isDijLogo) {
        return "70px";
      }
      if (props.isRamdathLogo) {
        return "96px";
      }

      return "70px";
    }};
  }
`;

const Link = styled.a``;

const Logo = ({ isFooterLogo, isDijLogo, isRamdathLogo, title, children, className, href, target }) => (
  <Link href={href} target={target}>
    <StyledLogo
      title={title}
      isFooterLogo={isFooterLogo}
      isDijLogo={isDijLogo}
      isRamdathLogo={isRamdathLogo}
      className={className}
    >
      {children}
    </StyledLogo>
  </Link>
);

export default Logo;
