import React from "react";

const IconGoal = ({ className }) => (
  <svg width="32" height="28" viewBox="0 0 32 28" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g fill="#9B9B9B" fillRule="nonzero">
      <path d="M9.997 2.315h2.315V4.63H9.997zM13.47 5.788h2.315v2.315H13.47zM9.997 5.788h2.315v2.315H9.997zM9.997 9.26h2.315v2.316H9.997zM9.997 12.733h2.315v2.315H9.997zM6.524 12.733H8.84v2.315H6.524zM3.052 2.315h2.315V4.63H3.052zM6.524 9.26H8.84v2.316H6.524zM3.052 9.26h2.315v2.316H3.052zM3.052 5.788h2.315v2.315H3.052zM13.47 2.315h2.315V4.63H13.47zM6.524 2.315H8.84V4.63H6.524zM6.524 5.788H8.84v2.315H6.524zM3.052 12.733h2.315v2.315H3.052zM3.052 16.247h26.616v3.721H3.052zM16.942 12.733h2.316v2.315h-2.316zM13.47 9.26h2.315v2.316H13.47zM23.888 9.26h2.315v2.316h-2.315zM23.888 5.788h2.315v2.315h-2.315zM27.36 12.733h2.316v2.315H27.36zM23.888 2.315h2.315V4.63h-2.315zM27.36 9.26h2.316v2.316H27.36zM27.36 2.315h2.316V4.63H27.36z" />
      <path d="M30.255 0H2.473C1.513 0 .736.838.736 1.872v17.472c0 .345.26.624.58.624.319 0 .578-.28.578-.624V1.872c0-.345.26-.624.579-.624h27.782c.32 0 .578.28.578.624v17.472c0 .345.26.624.58.624.319 0 .578-.28.578-.624V1.872C31.99.838 31.214 0 30.255 0z" />
      <path d="M27.36 5.788h2.316v2.315H27.36zM23.888 12.733h2.315v2.315h-2.315zM16.942 9.26h2.316v2.316h-2.316zM16.942 2.315h2.315V4.63h-2.315zM16.942 5.788h2.316v2.315h-2.316zM20.415 5.788h2.315v2.315h-2.315zM20.415 9.26h2.315v2.316h-2.315zM20.415 12.733h2.315v2.315h-2.315zM20.415 2.315h2.315V4.63h-2.315zM13.47 12.733h2.315v2.315H13.47z" />
      <circle cx="16.488" cy="25.425" r="1.86" />
    </g>
  </svg>
);

export default IconGoal;
