import React, { Component } from "react";
import styled from "styled-components";
import Header from "../organisms/Header";
import Layout from "../templates/Layout";
import Container from "../atoms/Container";
import Footer from "../organisms/Footer";
import OffCanvasContainer from "../organisms/OffCanvasContainer";
import MainMenu from "../molecules/MainMenu";
import FiltersCanvas from "../organisms/offcanvas/FiltersCanvas";
import Card from "../atoms/Card";
import { color } from "../templates/ui";
import CardHeader from "../atoms/CardHeader";
import CardBody from "../atoms/CardBody";
import TeamStaff from "../atoms/Staff";
import MatchItem from "../atoms/MatchItem";
import TournamentItem from "../organisms/TournamentItem";
import IntroBlock from "../molecules/IntroBlock";
import ScoreList from "../molecules/ScoreList";
import AppLinks from "../molecules/AppLinks";
import { getTournament } from "../../api/Tournament";
import TeamMember from "../atoms/TeamMember";
import moment from "moment";
import { getGenderLabel, getAssistentCoachName, getManagerName, getNationalCoachName, parseScore } from "../../helpers";
import { Fade } from "react-reveal";
import Breadcrumbs, { getBreadcrumbs } from "../molecules/Breadcrumbs";
import { Link } from "react-router-dom";
import Button from "../atoms/Button";
import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.min.css";
import withErrorPage from "../hoc/withErrorPage";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex: 0 0 100%;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    flex: 0 0 50%;
  }
`;

const StyledCard = styled(Card)`
  flex: auto;
  background-color: transparent;
  margin-bottom: 20px;
`;

const ColumnWrapper = styled.div`
  height: 100%;
  flex: 0 0 100%;

  @media only screen and (min-width: 992px) {
    flex: 0 0 50%;

    &:first-child {
      padding-right: 10px;
    }

    &:last-child {
      padding-left: 10px;
    }
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
  padding: 0;
`;

const StyledTournamentItem = styled(TournamentItem)`
  position: relative;
  margin-bottom: 0;
`;

const StyledAppLinks = styled(AppLinks)`
  margin-bottom: 30px;
`;

const StyledCardHeader = styled(CardHeader)`
  background-color: ${color.body.base};
`;

const StyledMatchItem = styled(MatchItem)`
  background-color: ${color.body.base};

  &:hover {
    background-color: ${color.gray.lighter};
  }
`;

const StyledTeamMember = styled(TeamMember)`
  &:hover {
    background-color: ${color.gray.lighter};
  }
`;

const RoundedButton = styled(Button)`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 10px 0;
  -webkit-tap-highlight-color: transparent;

  @media only screen and (min-width: 1200px) {
    width: 50px;
    height: 50px;
  }

  &:hover,
  &:focus {
    background-color: ${color.primary.base};
  }
`;

const PreviousLink = styled(Link)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  z-index: 1;

  @media only screen and (min-width: 350px) {
    left: -18px;
  }

  @media only screen and (min-width: 1200px) {
    left: -80px;
  }
`;

const NextLink = styled(Link)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  z-index: 1;

  @media only screen and (min-width: 350px) {
    right: -18px;
  }

  @media only screen and (min-width: 1200px) {
    right: -80px;
  }
`;

const PreviousButton = styled(RoundedButton)`
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjE3cHgiIHZpZXdCb3g9IjAgMCAxMCAxNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5QYXRoPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IlRvZXJub29pZW4tbGlqc3QvdGlqZGxpam4iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNDE2LjAwMDAwMCwgLTg2MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4gICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTM5NS4wMDAwMDAsIDg0Mi4wMDAwMDApIj4gICAgICAgICAgICAgICAgPHBhdGggZD0iTTI2LjkxMDA4NDEsMjMuMDIyMjUyNyBMMzEsMjcuMDQ0NTA1NCBMMjYuODk4ODQ3OSwzMS4wNzc4MDgzIEMyNC42NDMyMTQ3LDMzLjI5NjEyNTcgMjIuNzg3NTI0LDM1LjExMTExMTEgMjIuNzc1MDkyMywzNS4xMTExMTExIEMyMi43NjI2NjA2LDM1LjExMTExMTEgMjIuMzU4MTc4NiwzNC43MjMyMzMzIDIxLjg3NjI0NDQsMzQuMjQ5MTYwOSBMMjEsMzMuMzg3MjEwNyBMMjQuMjE5MDkxOCwzMC4yMjEzNTQ5IEMyNS45ODk1OTI2LDI4LjQ4MDEzMzIgMjcuNDM4MTgzNywyNy4wNDU1NTIxIDI3LjQzODE4MzcsMjcuMDMzMzk2OSBDMjcuNDM4MTgzNywyNy4wMjEyNDE4IDI1Ljk5NzE2MzEsMjUuNTk0MTU4OSAyNC4yMzU5MTczLDIzLjg2MjEwMjYgTDIxLjAzMzY0OTIsMjAuNzEyOTA2OCBMMjEuOTA0MzA0MiwxOS44NTY0NTM0IEMyMi4zODMxNjYsMTkuMzg1NDA0MyAyMi43ODUxMzMsMTkgMjIuNzk3NTY0NiwxOSBDMjIuODA5OTk2MywxOSAyNC42NjA2Mjk4LDIwLjgxMDAxMzcgMjYuOTEwMDg0MSwyMy4wMjIyNTI3IEwyNi45MTAwODQxLDIzLjAyMjI1MjcgWiIgaWQ9IlBhdGgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI2LjAwMDAwMCwgMjcuMDU1NTU2KSByb3RhdGUoMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0yNi4wMDAwMDAsIC0yNy4wNTU1NTYpICI+PC9wYXRoPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-size: contain;
    width: 11px;
    height: 18px;
  }
`;

const NextButton = styled(RoundedButton)`
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjlweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgOSAxNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJUb2Vybm9vaWVuLWxpanN0L3RpamRsaWpuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTA3Mi4wMDAwMDAsIC03NzQuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxnIGlkPSJUb2Vybm9vaS1pdGVtIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNjAuMDAwMDAwLCAzODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iU2xpZGVyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYuMDAwMDAwLCAxODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM1OC4wMDAwMDAsIDIwMi4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTIzLjMxOTA3NTcsMTUuNjIwMDI3NCBMMjcsMTkuMjQwMDU0OSBMMjMuMzA4OTYzMSwyMi44NzAwMjc0IEMyMS4yNzg4OTMyLDI0Ljg2NjUxMzEgMTkuNjA4NzcxNiwyNi41IDE5LjU5NzU4MzEsMjYuNSBDMTkuNTg2Mzk0NiwyNi41IDE5LjIyMjM2MDgsMjYuMTUwOTEgMTguNzg4NjE5OSwyNS43MjQyNDQ4IEwxOCwyNC45NDg0ODk3IEwyMC44OTcxODI3LDIyLjA5OTIxOTQgQzIyLjQ5MDYzMzQsMjAuNTMyMTE5OCAyMy43OTQzNjUzLDE5LjI0MDk5NjkgMjMuNzk0MzY1MywxOS4yMzAwNTcyIEMyMy43OTQzNjUzLDE5LjIxOTExNzYgMjIuNDk3NDQ2OCwxNy45MzQ3NDMgMjAuOTEyMzI1NiwxNi4zNzU4OTIzIEwxOC4wMzAyODQyLDEzLjU0MTYxNjEgTDE4LjgxMzg3MzgsMTIuNzcwODA4MSBDMTkuMjQ0ODQ5NCwxMi4zNDY4NjM5IDE5LjYwNjYxOTcsMTIgMTkuNjE3ODA4MiwxMiBDMTkuNjI4OTk2NywxMiAyMS4yOTQ1NjY4LDEzLjYyOTAxMjMgMjMuMzE5MDc1NywxNS42MjAwMjc0IEwyMy4zMTkwNzU3LDE1LjYyMDAyNzQgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-size: contain;
    width: 11px;
    height: 18px;
  }
`;

const SwiperWrapper = styled.div`
  max-width: 580px;
  margin: 30px auto 0 auto;

  > div {
    width: 100%;
  }

  .swiper-wrapper {
    z-index: -1 !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
    background-color: ${color.primary.base};
    transition: background 0.2s ease-in-out;
    text-align: center;
    border: none;
    width: 40px;
    height: 40px;
    padding: 0;
    color: transparent;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .swiper-button-next {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url("data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjlweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgOSAxNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJUb2Vybm9vaWVuLWxpanN0L3RpamRsaWpuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTA3Mi4wMDAwMDAsIC03NzQuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxnIGlkPSJUb2Vybm9vaS1pdGVtIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNjAuMDAwMDAwLCAzODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iU2xpZGVyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYuMDAwMDAwLCAxODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM1OC4wMDAwMDAsIDIwMi4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTIzLjMxOTA3NTcsMTUuNjIwMDI3NCBMMjcsMTkuMjQwMDU0OSBMMjMuMzA4OTYzMSwyMi44NzAwMjc0IEMyMS4yNzg4OTMyLDI0Ljg2NjUxMzEgMTkuNjA4NzcxNiwyNi41IDE5LjU5NzU4MzEsMjYuNSBDMTkuNTg2Mzk0NiwyNi41IDE5LjIyMjM2MDgsMjYuMTUwOTEgMTguNzg4NjE5OSwyNS43MjQyNDQ4IEwxOCwyNC45NDg0ODk3IEwyMC44OTcxODI3LDIyLjA5OTIxOTQgQzIyLjQ5MDYzMzQsMjAuNTMyMTE5OCAyMy43OTQzNjUzLDE5LjI0MDk5NjkgMjMuNzk0MzY1MywxOS4yMzAwNTcyIEMyMy43OTQzNjUzLDE5LjIxOTExNzYgMjIuNDk3NDQ2OCwxNy45MzQ3NDMgMjAuOTEyMzI1NiwxNi4zNzU4OTIzIEwxOC4wMzAyODQyLDEzLjU0MTYxNjEgTDE4LjgxMzg3MzgsMTIuNzcwODA4MSBDMTkuMjQ0ODQ5NCwxMi4zNDY4NjM5IDE5LjYwNjYxOTcsMTIgMTkuNjE3ODA4MiwxMiBDMTkuNjI4OTk2NywxMiAyMS4yOTQ1NjY4LDEzLjYyOTAxMjMgMjMuMzE5MDc1NywxNS42MjAwMjc0IEwyMy4zMTkwNzU3LDE1LjYyMDAyNzQgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=") !important;
      background-repeat: no-repeat;
      width: 9px;
      height: 15px;
    }
  }

  .swiper-button-prev {
    right: 45px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url("data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjlweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgOSAxNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJUb2Vybm9vaWVuLWxpanN0L3RpamRsaWpuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAyNi4wMDAwMDAsIC03NzQuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxnIGlkPSJUb2Vybm9vaS1pdGVtIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNjAuMDAwMDAwLCAzODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iU2xpZGVyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYuMDAwMDAwLCAxODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMxNi4wMDAwMDAsIDIwMi4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTE3LjY4MDkyNDMsMjIuODc5OTcyNiBMMTQsMTkuMjU5OTQ1MSBMMTcuNjkxMDM2OSwxNS42Mjk5NzI2IEMxOS43MjExMDY4LDEzLjYzMzQ4NjkgMjEuMzkxMjI4NCwxMiAyMS40MDI0MTY5LDEyIEMyMS40MTM2MDU0LDEyIDIxLjc3NzYzOTIsMTIuMzQ5MDkgMjIuMjExMzgwMSwxMi43NzU3NTUyIEwyMywxMy41NTE1MTAzIEwyMC4xMDI4MTczLDE2LjQwMDc4MDYgQzE4LjUwOTM2NjYsMTcuOTY3ODgwMiAxNy4yMDU2MzQ3LDE5LjI1OTAwMzEgMTcuMjA1NjM0NywxOS4yNjk5NDI4IEMxNy4yMDU2MzQ3LDE5LjI4MDg4MjQgMTguNTAyNTUzMiwyMC41NjUyNTcgMjAuMDg3Njc0NCwyMi4xMjQxMDc3IEwyMi45Njk3MTU4LDI0Ljk1ODM4MzkgTDIyLjE4NjEyNjIsMjUuNzI5MTkxOSBDMjEuNzU1MTUwNiwyNi4xNTMxMzYxIDIxLjM5MzM4MDMsMjYuNSAyMS4zODIxOTE4LDI2LjUgQzIxLjM3MTAwMzMsMjYuNSAxOS43MDU0MzMyLDI0Ljg3MDk4NzcgMTcuNjgwOTI0MywyMi44Nzk5NzI2IEwxNy42ODA5MjQzLDIyLjg3OTk3MjYgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=") !important;
      background-repeat: no-repeat;
      width: 9px;
      height: 15px;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: 160px !important;
  transition: all 0.2s ease-in-out;
  padding-top: 50%;

  @media only screen and (min-width: 768px) {
    height: 200px !important;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

class Tournament extends Component {
  state = {
    menu_open: false,
    showFiltersCanvas: false,
    tournament: {
      content: {},
      ranking: null,
      teamComposition: {
        players: []
      },
      location: {
        country: {}
      },
      event: {},
      players: [],
      matches: [],
      topScorers: [],
      images: []
    },
    errorCode: null
  };

  toggleStateBool = fieldName => {
    this.setState(prevState => {
      return {
        ...prevState,
        [fieldName]: !prevState[fieldName]
      };
    });
  };

  fetchTournament = () => {
    getTournament({ id: this.props.match.params.id })
      .then(response =>
        this.setState({
          tournament: response.data
        })
      )
      .catch(this.props.setError);
  };

  componentDidMount() {
    this.fetchTournament();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id === prevProps.match.params.id) {
      return;
    }

    this.fetchTournament();
  }

  filterEvent = event => {
    this.props.history.push(`/?filters[eventType]=${event.target.value}`);
  };

  getImages = () => {
    return this.state.tournament.images.map((tournament, index) => (
      <ImageWrapper key={tournament.id}>
        <Image src={tournament.url} />
      </ImageWrapper>
    ));
  };

  render() {
    const params = {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      spaceBetween: 0,
      slidesPerView: 1,
      centeredSlides: true,
      loop: true
    };

    return (
      <>
        <Header toggleOffCanvas={() => this.setState({ menu_open: !this.state.menu_open })} />
        <Layout>
          <Container>
            <StyledAppLinks
              onClick={() => this.toggleStateBool("showFiltersCanvas")}
              breadcrumbs={this.props.location.state?.breadcrumbs || []}
            />
            <Breadcrumbs
              links={getBreadcrumbs(
                this.props.location,
                this.state.tournament.endAt
                  ? `${moment(this.state.tournament.endAt).format("YYYY")} - ${this.state.tournament.event.label} - ${
                      this.state.tournament.location.city
                    }`
                  : ""
              )}
            />
            <StyledTournamentItem
              isDetailLayout={true}
              year={this.state.tournament.startAt ? moment(this.state.tournament.startAt).format("YYYY") : null}
              href="#"
              ranking={this.state.tournament.ranking}
              country={
                this.state.tournament.location && this.state.tournament.location.country
                  ? this.state.tournament.location.country.name
                  : ""
              }
              city={this.state.tournament.location ? this.state.tournament.location.city : ""}
              tournamentId={this.state.tournament.event.id}
              tournamentType={this.state.tournament.event.label}
              showTournamentLink={true}
              filterEvent={this.filterEvent}
            >
              {this.state.tournament.meta && this.state.tournament.meta.previous_event ? (
                <PreviousLink to={`/toernooien/${this.state.tournament.meta.previous_event.id}`}>
                  <PreviousButton />
                </PreviousLink>
              ) : null}
              {this.state.tournament.meta && this.state.tournament.meta.next_event ? (
                <NextLink to={`/toernooien/${this.state.tournament.meta.next_event.id}`}>
                  <NextButton />
                </NextLink>
              ) : null}
            </StyledTournamentItem>
            <SwiperWrapper>
              {this.state.tournament.images.length > 1 ? (
                <Swiper {...params} key={this.props.match.params.id} rebuildOnUpdate={true}>
                  {this.getImages()}
                </Swiper>
              ) : (
                this.getImages()
              )}
            </SwiperWrapper>
            {this.state.tournament.content ? (
              <IntroBlock
                title={this.state.tournament.content.title}
                description={this.state.tournament.content.description}
              />
            ) : (
              ""
            )}
            <StyledContainer isCompact>
              <Row>
                <ColumnWrapper>
                  <Column>
                    <StyledCard>
                      <StyledCardHeader>Internationals</StyledCardHeader>
                      <CardBody>
                        <TeamStaff
                          nationalCoach={getNationalCoachName(this.state.tournament.teamComposition)}
                          assistentCoach={getAssistentCoachName(this.state.tournament.teamComposition)}
                          manager={getManagerName(this.state.tournament.teamComposition)}
                        />
                        {this.state.tournament.teamComposition
                          ? this.state.tournament.teamComposition.players.map((player, index) => (
                              <Fade key={player.id} bottom distance="40px" delay={index * 50}>
                                <StyledTeamMember
                                  name={player.name}
                                  href={{
                                    pathname: `/internationals/${player.id}`,
                                    state: {
                                      breadcrumbs: getBreadcrumbs(
                                        this.props.location,
                                        `${moment(this.state.tournament.endAt).format("YYYY")} - ${
                                          this.state.tournament.event.label
                                        } - ${this.state.tournament.location.city}`
                                      )
                                    }
                                  }}
                                />
                              </Fade>
                            ))
                          : ""}
                      </CardBody>
                    </StyledCard>
                  </Column>
                </ColumnWrapper>
                <ColumnWrapper>
                  <Column>
                    <StyledCard>
                      <StyledCardHeader>Topscorers</StyledCardHeader>
                      <CardBody>
                        <ScoreList
                          topScorers={this.state.tournament.topScorers}
                          breadcrumbs={getBreadcrumbs(
                            this.props.location,
                            `${moment(this.state.tournament.endAt).format("YYYY")} - ${
                              this.state.tournament.event.label
                            } - ${this.state.tournament.location.city}`
                          )}
                        />
                      </CardBody>
                    </StyledCard>
                    <StyledCard>
                      <StyledCardHeader>
                        Wedstrijden Nederland
                        {this.state.tournament.gender && " (" + getGenderLabel(this.state.tournament.gender) + ")"}
                      </StyledCardHeader>
                      <CardBody>
                        {this.state.tournament.matches
                          ? this.state.tournament.matches.map((match, index) => (
                              <Fade key={match.id} bottom distance="40px" delay={index * 50}>
                                <StyledMatchItem
                                  key={match.id}
                                  date={moment(match.datetime).format("DD-MM-YYYY")}
                                  homeFlag={match.teams.home.flag}
                                  homeTeam={match.teams.home.name}
                                  homeTeamShort={match.teams.home.isoCode}
                                  homeScore={parseScore(match.scores).home}
                                  awayFlag={match.teams.away.flag}
                                  awayTeam={match.teams.away.name}
                                  awayTeamShort={match.teams.away.isoCode}
                                  awayScore={parseScore(match.scores).away}
                                  href={{
                                    pathname: `/wedstrijden/${match.id}`,
                                    state: {
                                      breadcrumbs: getBreadcrumbs(
                                        this.props.location,
                                        `${moment(this.state.tournament.endAt).format("YYYY")} - ${
                                          this.state.tournament.event.label
                                        } - ${this.state.tournament.location.city}`
                                      )
                                    }
                                  }}
                                />
                              </Fade>
                            ))
                          : ""}
                      </CardBody>
                    </StyledCard>
                  </Column>
                </ColumnWrapper>
              </Row>
            </StyledContainer>
          </Container>
        </Layout>
        <Footer />
        <OffCanvasContainer containerOpen={this.state.menu_open} isMenu={true}>
          <MainMenu isOffCanvasMenu={true} />
        </OffCanvasContainer>
        <FiltersCanvas toggle={() => this.toggleStateBool("showFiltersCanvas")} open={this.state.showFiltersCanvas} />
      </>
    );
  }
}

export default withErrorPage(Tournament);
