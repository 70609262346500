import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import { Link, withRouter } from "react-router-dom";

const StyledAppLinks = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const LinksWrapper = styled.div`
  box-shadow: 0 -3px 10px -5px rgba(0, 0, 0, 0.1);
  width: 100%;

  @media only screen and (min-width: 992px) {
    width: auto;
  }

  .filter-canvas & {
    box-shadow: none;
  }
`;

const StyledTimelineLink = styled(({ active, ...props }) => <Link {...props} />)`
  position: relative;
  display: inline-block;
  padding: 12px 6px;
  background-color: ${color.body.base};
  color: ${color.black.alt};
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  font-weight: 700;
  font-family: ${font.family.head};
  border-radius: 0 !important;
  font-size: 14px;
  text-align: center;
  width: calc(100% / 3);
  text-decoration: none;

  @media only screen and (min-width: 992px) {
    width: auto;
    text-align: left;
  }

  ${props => props.active} {
    border-right: 1px solid #f1f1f1;

    &:last-child {
      border-right: none;
    }
  }

  ${props => !props.active} {
    background-color: ${color.primary.base};
    color: ${color.body.base};
    border: none;

    &:hover {
      background-color: ${color.primary.hover};
    }

    &:before {
      content: none;
      display: block;
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid ${color.primary.base};

      @media only screen and (min-width: 992px) {
        content: "";
      }
    }
  }

  &:hover {
    background-color: ${color.primary.base};
    color: ${color.body.base};
  }

  &:focus {
    outline: none;
  }

  .filter-canvas & {
    display: none;
  }

  @media only screen and (min-width: 576px) {
    padding: 14px 24px;
    font-size: 16px;
  }
`;

const FilterResults = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: ${color.primary.base};
  font-family: ${font.family.base};
  font-size: 18px;

  @media only screen and (min-width: 992px) {
    display: block;
  }
`;

const AppLinks = ({ className, match, results, breadcrumbs = [] }) => (
  <>
    <StyledAppLinks className={className}>
      <LinksWrapper>
        <StyledTimelineLink
          to="/"
          active={
            breadcrumbs[0]?.link === "/" ||
            (!breadcrumbs[0]?.link && (match.path === "/" || match.path === "/toernooien/:id"))
          }
        >
          Toernooien
        </StyledTimelineLink>
        <StyledTimelineLink
          to="/wedstrijden"
          active={
            breadcrumbs[0]?.link === "/wedstrijden" ||
            (!breadcrumbs[0]?.link && (match.path === "/wedstrijden" || match.path === "/wedstrijden/:id"))
          }
        >
          Wedstrijden
        </StyledTimelineLink>
        <StyledTimelineLink
          to="/internationals"
          active={
            breadcrumbs[0]?.link === "/internationals" ||
            (!breadcrumbs[0]?.link && (match.path === "/internationals" || match.path === "/internationals/:id"))
          }
        >
          Internationals
        </StyledTimelineLink>
      </LinksWrapper>
      {results > 0 && (
        <FilterResults>
          {results} {ResultLabel(match)} gevonden
        </FilterResults>
      )}
    </StyledAppLinks>
  </>
);

const ResultLabel = match => {
  switch (match.path) {
    case "/":
      return "toernooien";
    case "/wedstrijden":
      return "wedstrijden";
    case "/internationals":
      return "internationals";
    default:
      return "resultaten";
  }
};

export default withRouter(AppLinks);
