import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledLabel = styled.span`
  display: block;
  color: ${color.primary.base};
  font-family: "Merriweather Webfont", sans-serif;
  font-size: 20px;
  text-align: center;
`;

const Label = ({ className, children }) => <StyledLabel className={className}>{children}</StyledLabel>;

export default Label;
