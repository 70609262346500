import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import IconArrowRight from "./icons/IconArrowRight";
import { Link } from "react-router-dom";

const StyledScoreListItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: ${color.gray.lighter};
  border-bottom: 1px solid ${color.gray.alt};
  text-decoration: none;

  &:last-child {
    border-bottom: none;
  }
`;

const Score = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: ${color.primary.base};
  margin-right: 15px;

  span {
    color: ${color.body.base};
    font-size: 20px;
    font-weight: 700;
    font-family: ${font.family.alt};
    line-height: 1.1rem;
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #f6f6f6;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${color.primary.base};
  }
`;

const Name = styled.div`
  font-family: ${font.family.head};
  font-size: 16px;
  color: ${color.gray.darkest};
  font-weight: 600;
`;

const StyledIconArrowRight = styled(IconArrowRight)`
  fill: ${color.primary.base};
  margin-left: auto;
`;

const ScoreListItem = ({ score, name, href }) => (
  <StyledScoreListItem to={href}>
    <Score>
      <span>{score}</span>
    </Score>
    <Name>{name}</Name>
    <StyledIconArrowRight />
  </StyledScoreListItem>
);

export default ScoreListItem;
