import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { font } from "../templates/ui";
import Header from "../organisms/Header";
import Footer from "../organisms/Footer";
import OffCanvasContainer from "../organisms/OffCanvasContainer";
import MainMenu from "../molecules/MainMenu";
import Container from "../atoms/Container";
import IconErrorField from "../atoms/icons/IconErrorField";
import Link from "react-router-dom/Link";

const StyledTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const StyledText = styled.h2`
  font-family: ${font.family.head};
  font-size: 24px;
  font-weight: 700;
  color: #ef790c;
  margin-top: 22px;
  text-align: center;
  line-height: 1.1em;
  padding: 0 20px;

  @media only screen and (min-width: 640px) {
    font-size: 27px;
    padding: 0;
  }
`;

const StyledContainer = styled(Container)`
  height: 100%;
  display: table-row;
`;

const StyledIconErrorField = styled(IconErrorField)`
  width: 335px;
  height: 245px;

  @media only screen and (min-width: 640px) {
    width: 460px;
    height: 336px;
  }
`;

const Paragraph = styled.p`
  font-family: ${font.family.base};
  font-size: 14px;
  margin-top: 21px;
  color: #5e5e5e;

  @media only screen and (min-width: 640px) {
    font-size: 16px;
  }
`;

const StyledLink = styled(Link)`
  color: #ef790c;
  text-decoration: none;
`;

const PartnerText = styled.p`
  font-family: ${font.family.head};
  font-size: 19px;
  color: #9b9b9b;
  margin: 0;
  text-align: center;

  @media only screen and (min-width: 640px) {
    font-size: 21px;
  }

  @media only screen and (min-width: 992px) {
    text-align: left;
  }
`;

const PartnerBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 25px;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const Partner = styled.div`
  width: 150px;
  height: 155px;

  img {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 150px;
    height: auto;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 25px 0;

  @media only screen and (min-width: 640px) {
    padding: 50px 0;
    margin-bottom: 30px;
  }
`;

const PartnerWrapper = styled.div`
  background-color: #fff;
  padding-top: 25px;
  width: 100%;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1200px;
`;

class NotFound extends Component {
  state = {
    menu_open: false,
    showFiltersCanvas: false
  };

  render() {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Header toggleOffCanvas={() => this.setState({ menu_open: !this.state.menu_open })} />
        <StyledContainer>
          <StyledTextContainer>
            <ContentWrapper>
              <StyledIconErrorField />
              <StyledText>Deze pagina heeft helaas het veld moeten ruimen</StyledText>
              <Paragraph>
                <StyledLink to="/" title="Ga terug naar de Interlandhistorie">
                  Ga terug naar de Interlandhistorie
                </StyledLink>
              </Paragraph>
            </ContentWrapper>
            <PartnerWrapper>
              <Wrapper>
                <PartnerText>Official partners Koninklijke Nederlandse Hockey Bond</PartnerText>
                <PartnerBar>
                  <Partner>
                    <a
                      href="https://www.adidas.nl/veld_hockey"
                      title="adidas"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/adidas.png" alt="adidas" />
                    </a>
                  </Partner>
                  <Partner>
                    <a href="/" title="Auping" target="_blank" rel="noopener noreferrer">
                      <img src="/img/auping.png" alt="Auping" />
                    </a>
                  </Partner>
                  <Partner>
                    <a
                      href="https://www2.deloitte.com/nl/nl.html"
                      title="Deloitte"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/deloitte.png" alt="Deloitte" />
                    </a>
                  </Partner>
                  <Partner>
                    <a
                      href="https://www.nederlandseloterij.nl/"
                      title="Nederlandse Loterij"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/nederlandse-loterij.png" alt="Nederlandse Loterij" />
                    </a>
                  </Partner>
                  <Partner>
                    <a href="https://www.onvz.nl/" title="ONVZ" target="_blank" rel="noopener noreferrer">
                      <img src="/img/onvz.png" alt="ONVZ" />
                    </a>
                  </Partner>
                  <Partner>
                    <a href="http://www.rabobank.com" title="Rabobank" target="_blank" rel="noopener noreferrer">
                      <img src="/img/rabobank.png" alt="Rabobank" />
                    </a>
                  </Partner>
                  <Partner>
                    <a href="/" title="Volvo en hockey" target="_blank" rel="noopener noreferrer">
                      <img src="/img/volvo.png" alt="Volvo en hockey" />
                    </a>
                  </Partner>
                </PartnerBar>
              </Wrapper>
            </PartnerWrapper>
          </StyledTextContainer>
        </StyledContainer>
        <Footer />
        <OffCanvasContainer containerOpen={this.state.menu_open} isMenu={true}>
          <MainMenu isOffCanvasMenu={true} />
        </OffCanvasContainer>
      </>
    );
  }
}

export default NotFound;
