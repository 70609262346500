import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";
import IconCross from "../atoms/icons/IconCross";

const StyledOffCanvasContainer = styled.div``;

const StyledContainer = styled.div`
  transition: ${props => {
    if (props.isMenu) {
      return "left .2s ease-in-out";
    }

    return "right .2s ease-in-out";
  }};
  position: fixed;
  overflow: hidden;
  top: 0;
  left: ${props => {
    if (props.containerOpen && props.isMenu) {
      return "0";
    }

    if ((props.containerOpen && !props.isMenu) || (!props.containerOpen && !props.isMenu)) {
      return "auto";
    }

    return "-400px";
  }};
  right: ${props => {
    if (props.containerOpen && !props.isMenu) {
      return "0";
    }

    return "-400px";
  }};
  width: 100%;
  max-width: ${props => {
    if (props.isMenu) {
      return "240px";
    }

    return "400px";
  }};
  height: 100%;
  background-color: ${props => {
    if (props.isMenu) {
      return `${color.gray.light}`;
    }

    return `${color.body.base}`;
  }};
  z-index: 8;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media only screen and (min-width: 640px) {
    max-width: 400px;
  }
`;

const StyledContent = styled.div`
  position: relative;
  overflow-y: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  padding: 10px;
`;

const StyledButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  padding: 15px;
`;

const StyledIconCross = styled(IconCross)`
  fill: ${color.primary.base};
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: flex-end;
`;

class OffCanvasContainer extends React.Component {
  render() {
    const { children, onClose, className, isMenu, hasCloseButton } = this.props;

    return (
      <StyledOffCanvasContainer className={className} onClose={onClose}>
        <StyledContainer
          containerOpen={this.props.containerOpen}
          hasCloseButton={this.props.hasCloseButton}
          isMenu={isMenu}
        >
          {hasCloseButton === true && (
            <StyledHeader>
              <StyledButton onClick={onClose}>
                <StyledIconCross />
              </StyledButton>
            </StyledHeader>
          )}
          <StyledContent>{children}</StyledContent>
        </StyledContainer>
      </StyledOffCanvasContainer>
    );
  }
}

export default OffCanvasContainer;
