import React, { Component } from "react";
import Header from "../organisms/Header";
import Layout from "../templates/Layout";
import Container from "../atoms/Container";
import CarouselDots from "../molecules/CarouselDots";
import Footer from "../organisms/Footer";
import OffCanvasContainer from "../organisms/OffCanvasContainer";
import MainMenu from "../molecules/MainMenu";
import FiltersCanvas from "../organisms/offcanvas/FiltersCanvas";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import TournamentItem from "../organisms/TournamentItem";
import MediaQuery from "react-responsive";
import LazyLoad from "react-lazyload";
import moment from "moment";
import { color } from "../templates/ui";
import TournamentFilter from "../molecules/TournamentFilter";
import AppLinks from "../molecules/AppLinks";
import { getTournaments } from "../../api/Tournaments";
import IconLoader from "../atoms/icons/IconLoader";
import Label from "../atoms/Label";
import { getBreadcrumbs } from "../molecules/Breadcrumbs";
import Dock from "../molecules/Dock";
import FilterButton from "../atoms/FilterButton";
import produce from "immer";
import withQueryParams, { setFilters } from "../hoc/withQueryParams";
import { withRouter } from "react-router";

const StyledCarousel = styled(Carousel)`
  position: absolute;

  .slider-frame {
    padding: 20px 0 !important;
    min-height: 740px;

    @media only screen and (min-width: 768px) {
      min-height: 400px;
    }
    @media only screen and (min-width: 992px) {
      padding: 50px 0 !important;
      min-height: auto;
    }
    @media only screen and (min-width: 1024px) {
      min-height: 470px;
    }
    @media only screen and (min-width: 1200px) {
      min-height: auto;
    }
  }

  .slider-control-bottomcenter {
    position: relative !important;
    bottom: auto !important;
    left: auto !important;
    transform: none !important;
  }

  .slider-control-centerleft {
    z-index: 1;
    position: absolute;
    top: auto !important;
    transform: none !important;
    right: auto !important;
    left: 0 !important;
    bottom: 160px !important;

    button {
      font-size: 0;
      color: transparent;
      -webkit-tap-highlight-color: transparent;
    }
  }

  .slider-control-centerright {
    z-index: 1;
    position: absolute;
    top: auto !important;
    transform: none !important;
    left: auto !important;
    bottom: 160px !important;

    button {
      font-size: 0;
      color: transparent;
      -webkit-tap-highlight-color: transparent;
    }
  }
`;

const StyledButton = styled.button`
  position: relative;
  background-color: ${color.primary.base};
  transition: background 0.2s ease-in-out;
  text-align: center;
  border: none;
  width: 50px;
  height: 56px;
  padding: 0;
  color: ${color.body.base};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  @media only screen and (min-width: 1200px) {
    &:hover {
      background-color: ${color.primary.hover};
    }
  }
`;

const ButtonLeft = styled(StyledButton)`
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjlweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgOSAxNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJUb2Vybm9vaWVuLWxpanN0L3RpamRsaWpuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAyNi4wMDAwMDAsIC03NzQuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxnIGlkPSJUb2Vybm9vaS1pdGVtIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNjAuMDAwMDAwLCAzODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iU2xpZGVyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYuMDAwMDAwLCAxODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMxNi4wMDAwMDAsIDIwMi4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTE3LjY4MDkyNDMsMjIuODc5OTcyNiBMMTQsMTkuMjU5OTQ1MSBMMTcuNjkxMDM2OSwxNS42Mjk5NzI2IEMxOS43MjExMDY4LDEzLjYzMzQ4NjkgMjEuMzkxMjI4NCwxMiAyMS40MDI0MTY5LDEyIEMyMS40MTM2MDU0LDEyIDIxLjc3NzYzOTIsMTIuMzQ5MDkgMjIuMjExMzgwMSwxMi43NzU3NTUyIEwyMywxMy41NTE1MTAzIEwyMC4xMDI4MTczLDE2LjQwMDc4MDYgQzE4LjUwOTM2NjYsMTcuOTY3ODgwMiAxNy4yMDU2MzQ3LDE5LjI1OTAwMzEgMTcuMjA1NjM0NywxOS4yNjk5NDI4IEMxNy4yMDU2MzQ3LDE5LjI4MDg4MjQgMTguNTAyNTUzMiwyMC41NjUyNTcgMjAuMDg3Njc0NCwyMi4xMjQxMDc3IEwyMi45Njk3MTU4LDI0Ljk1ODM4MzkgTDIyLjE4NjEyNjIsMjUuNzI5MTkxOSBDMjEuNzU1MTUwNiwyNi4xNTMxMzYxIDIxLjM5MzM4MDMsMjYuNSAyMS4zODIxOTE4LDI2LjUgQzIxLjM3MTAwMzMsMjYuNSAxOS43MDU0MzMyLDI0Ljg3MDk4NzcgMTcuNjgwOTI0MywyMi44Nzk5NzI2IEwxNy42ODA5MjQzLDIyLjg3OTk3MjYgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=") !important;
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
  }
`;

const ButtonRight = styled(StyledButton)`
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjlweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgOSAxNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJUb2Vybm9vaWVuLWxpanN0L3RpamRsaWpuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTA3Mi4wMDAwMDAsIC03NzQuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxnIGlkPSJUb2Vybm9vaS1pdGVtIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNjAuMDAwMDAwLCAzODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iU2xpZGVyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYuMDAwMDAwLCAxODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM1OC4wMDAwMDAsIDIwMi4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTIzLjMxOTA3NTcsMTUuNjIwMDI3NCBMMjcsMTkuMjQwMDU0OSBMMjMuMzA4OTYzMSwyMi44NzAwMjc0IEMyMS4yNzg4OTMyLDI0Ljg2NjUxMzEgMTkuNjA4NzcxNiwyNi41IDE5LjU5NzU4MzEsMjYuNSBDMTkuNTg2Mzk0NiwyNi41IDE5LjIyMjM2MDgsMjYuMTUwOTEgMTguNzg4NjE5OSwyNS43MjQyNDQ4IEwxOCwyNC45NDg0ODk3IEwyMC44OTcxODI3LDIyLjA5OTIxOTQgQzIyLjQ5MDYzMzQsMjAuNTMyMTE5OCAyMy43OTQzNjUzLDE5LjI0MDk5NjkgMjMuNzk0MzY1MywxOS4yMzAwNTcyIEMyMy43OTQzNjUzLDE5LjIxOTExNzYgMjIuNDk3NDQ2OCwxNy45MzQ3NDMgMjAuOTEyMzI1NiwxNi4zNzU4OTIzIEwxOC4wMzAyODQyLDEzLjU0MTYxNjEgTDE4LjgxMzg3MzgsMTIuNzcwODA4MSBDMTkuMjQ0ODQ5NCwxMi4zNDY4NjM5IDE5LjYwNjYxOTcsMTIgMTkuNjE3ODA4MiwxMiBDMTkuNjI4OTk2NywxMiAyMS4yOTQ1NjY4LDEzLjYyOTAxMjMgMjMuMzE5MDc1NywxNS42MjAwMjc0IEwyMy4zMTkwNzU3LDE1LjYyMDAyNzQgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=") !important;
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
  }
`;

const StyledIconLoader = styled(IconLoader)`
  display: block;
  margin: 150px auto 150px auto;
  width: 52px;
  height: 52px;
`;

const StyledTournamentItem = styled(TournamentItem)`
  @media only screen and (min-width: 1024px) {
    max-width: 650px;
  }
`;

class Tournaments extends Component {
  state = {
    menu_open: false,
    page: 1,
    showFiltersCanvas: false,
    tournaments: {
      data: null,
      meta: {
        pagination: {
          links: {}
        },
        filters: {
          years: [],
          event_types: [],
          classifications: null
        }
      }
    }
  };

  handleYearFromChange = event => {
    this.props.queryParamsDispatch(
      setFilters({
        ...this.props.queryParams.filters,
        yearFrom: event.target.value
      })
    );
  };

  handleYearTillChange = event => {
    this.props.queryParamsDispatch(
      setFilters({
        ...this.props.queryParams.filters,
        yearTill: event.target.value
      })
    );
  };

  handleEventTypeChange = event => {
    this.props.queryParamsDispatch(
      setFilters({
        ...this.props.queryParams.filters,
        eventType: event.target.value
      })
    );
  };

  handleOpponentTypeChange = event => {
    this.props.queryParamsDispatch(
      setFilters({
        ...this.props.queryParams.filters,
        opponent: event.target.value
      })
    );
  };

  handleGenderChange = event => {
    this.props.queryParamsDispatch(
      setFilters({
        ...this.props.queryParams.filters,
        gender: event.target.value
      })
    );
  };

  resetFilters = event => {
    event.preventDefault();
    this.props.queryParamsDispatch(setFilters({}));
  };

  toggleStateBool = fieldName => {
    this.setState(prevState => {
      return {
        ...prevState,
        [fieldName]: !prevState[fieldName]
      };
    });
  };

  fetchTournaments = eventType => {
    this.setState(
      produce(draft => {
        draft.tournaments.data = null;
      })
    );

    getTournaments({
      page: this.state.page,
      team: this.props.queryParams.filters.gender,
      from: this.props.queryParams.filters.yearFrom,
      opponent: this.props.queryParams.filters.opponent,
      till: this.props.queryParams.filters.yearTill,
      event: eventType ?? this.props.queryParams.filters.eventType
    }).then(response => {
      this.setState({
        tournaments: response.data,
        eventType
      });
    });
  };

  componentDidMount() {
    const eventType = this.props.queryParams.filters.eventType ?? this.state.eventType;

    this.fetchTournaments(eventType);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);

    if (prevProps.location.key !== this.props.location.key) {
      this.fetchTournaments();
    }
  }

  getSliderTournaments = () => {
    if (this.state.tournaments.data === null) {
      return <StyledIconLoader />;
    }

    if (this.state.tournaments.data.length === 0) {
      return <Label>Er zijn geen toernooien gevonden</Label>;
    }

    return this.state.tournaments.data.map(tournament => (
      <LazyLoad key={tournament.id} unmountIfInvisible={true} once={true}>
        <StyledTournamentItem
          isDetailLayout={false}
          year={moment(tournament.endAt).format("YYYY")}
          ranking={tournament.ranking ? tournament.ranking : "-"}
          country={tournament.location && tournament.location.country ? tournament.location.country.name : ""}
          images={tournament.images}
          city={tournament.location ? tournament.location.city : ""}
          tournamentType={tournament.event.label}
          team={tournament.gender}
          href={{
            pathname: `/toernooien/${tournament.id}`,
            state: { breadcrumbs: getBreadcrumbs(this.props.location, "Toernooien") }
          }}
        />
      </LazyLoad>
    ));
  };

  getSpeed = () => (this.state.tournaments.data === null ? 0 : 1500);

  render() {
    return (
      <>
        <Header toggleOffCanvas={() => this.setState({ menu_open: !this.state.menu_open })} />
        <Layout>
          <Container>
            <AppLinks
              onClick={() => this.toggleStateBool("showFiltersCanvas")}
              results={this.state.tournaments.meta.pagination.total}
              breadcrumbs={this.props.location.state?.breadcrumbs || []}
            />
            <TournamentFilter
              onGenderChange={this.handleGenderChange}
              gender={this.props.queryParams.filters.gender ?? ""}
              onYearFromChange={this.handleYearFromChange}
              onYearTillChange={this.handleYearTillChange}
              yearFrom={this.props.queryParams.filters.yearFrom ?? ""}
              yearTill={this.props.queryParams.filters.yearTill ?? ""}
              yearFromOptions={this.state.tournaments.meta.filters.years}
              yearTillOptions={this.state.tournaments.meta.filters.years}
              onEventTypeChange={this.handleEventTypeChange}
              onOpponentTypeChange={this.handleOpponentTypeChange}
              eventType={this.props.queryParams.filters.eventType}
              eventTypeOptions={this.state.tournaments.meta.filters.event_types}
              opponentOptions={this.state.tournaments.meta.filters.opponents}
              opponent={this.props.queryParams.filters.opponent}
              resetFilters={this.resetFilters}
            />
          </Container>
          <MediaQuery query="(max-device-width: 1023px)">
            <StyledCarousel
              renderBottomCenterControls={props => (
                <CarouselDots {...props} tournaments={this.state.tournaments.data} />
              )}
              slidesToShow={1}
              cellAlign="center"
              transitionMode="scroll"
              speed={this.getSpeed()}
              easing="easeExpOut"
              renderCenterLeftControls={({ previousSlide }) => <ButtonLeft onClick={previousSlide} />}
              renderCenterRightControls={({ nextSlide }) => <ButtonRight onClick={nextSlide} />}
            >
              {this.getSliderTournaments()}
            </StyledCarousel>
          </MediaQuery>
          <MediaQuery query="(min-device-width: 1024px) and (max-device-width: 1199px)">
            <StyledCarousel
              renderBottomCenterControls={props => (
                <CarouselDots {...props} tournaments={this.state.tournaments.data} />
              )}
              slidesToShow={1.4}
              cellAlign="center"
              transitionMode="scroll"
              speed={this.getSpeed()}
              easing="easeExpOut"
              renderCenterLeftControls={({ previousSlide }) => <ButtonLeft onClick={previousSlide} />}
              renderCenterRightControls={({ nextSlide }) => <ButtonRight onClick={nextSlide} />}
            >
              {this.getSliderTournaments()}
            </StyledCarousel>
          </MediaQuery>
          <MediaQuery query="(min-device-width: 1200px)">
            <StyledCarousel
              renderBottomCenterControls={props => (
                <CarouselDots {...props} tournaments={this.state.tournaments.data} />
              )}
              slidesToShow={1.8}
              cellAlign="center"
              transitionMode="scroll"
              speed={this.getSpeed()}
              easing="easeExpOut"
              renderCenterLeftControls={({ previousSlide }) => <ButtonLeft onClick={previousSlide} />}
              renderCenterRightControls={({ nextSlide }) => <ButtonRight onClick={nextSlide} />}
            >
              {this.getSliderTournaments()}
            </StyledCarousel>
          </MediaQuery>
        </Layout>
        <Footer />
        <OffCanvasContainer containerOpen={this.state.menu_open} isMenu={true}>
          <MainMenu isOffCanvasMenu={true} />
        </OffCanvasContainer>
        <FiltersCanvas toggle={() => this.toggleStateBool("showFiltersCanvas")} open={this.state.showFiltersCanvas}>
          <TournamentFilter
            onGenderChange={this.handleGenderChange}
            gender={this.props.queryParams.filters.gender}
            onYearFromChange={this.handleYearFromChange}
            onYearTillChange={this.handleYearTillChange}
            yearFrom={this.props.queryParams.filters.yearFrom}
            yearTill={this.props.queryParams.filters.yearTill}
            yearFromOptions={this.state.tournaments.meta.filters.years}
            yearTillOptions={this.state.tournaments.meta.filters.years}
            onEventTypeChange={this.handleEventTypeChange}
            onOpponentTypeChange={this.handleOpponentTypeChange}
            eventType={this.props.queryParams.filters.eventType}
            eventTypeOptions={this.state.tournaments.meta.filters.event_types}
            opponentOptions={this.state.tournaments.meta.filters.opponents}
            opponent={this.props.queryParams.filters.opponent}
            resetFilters={this.resetFilters}
            onClose={event => {
              event.preventDefault();
              this.toggleStateBool("showFiltersCanvas");
            }}
          />
        </FiltersCanvas>
        <Dock>
          <FilterButton onClick={() => this.toggleStateBool("showFiltersCanvas")}>Toon filters</FilterButton>
        </Dock>
      </>
    );
  }
}

export default withQueryParams(withRouter(Tournaments));
