import React from "react";
import styled from "styled-components";
import IconCalendar from "../atoms/icons/IconCalendar";
import { font } from "../templates/ui";

const StyledDate = styled.div`
  display: flex;
  align-items: center;
`;

const DateText = styled.div`
  font-size: 16px;
  font-family: ${font.family.head};
  font-weight: 600;
  color: #797979;
  margin-left: 10px;
`;

const Date = ({ className, date }) => (
  <StyledDate className={className}>
    <IconCalendar />
    <DateText>{date}</DateText>
  </StyledDate>
);

export default Date;
