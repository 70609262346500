import React from "react";
import styled, { css } from "styled-components";
import { color } from "../templates/ui";
import SelectItem from "./SelectItem";
import RadioItem from "./RadioItem";
import SelectGroup from "../organisms/SelectItems";
import Button from "../atoms/Button.js";

const TimelineContainer = styled.div`
  display: none;
  margin-bottom: 20px;
  text-align: center;

  @media only screen and (min-width: 992px) {
    display: block;
  }

  .filter-canvas & {
    display: block;
    margin: 0;
  }
`;

const StyledTabPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 4px solid ${color.primary.base};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  background-color: ${color.gray.lighter};

  .filter-canvas & {
    display: block;
    border: none;
    box-shadow: none;
    background-color: ${color.body.base};
  }
`;

const StyledSelectItem = styled(SelectItem)`
  min-width: 150px;
  margin-right: 30px;

  ${props =>
    props.wide &&
    css`
      min-width: 175px;

      @media only screen and (min-width: 1200px) {
        min-width: 250px;
      }
    `};

  .filter-canvas & {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
`;

const FilterWrapper = styled.form`
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;

  .filter-canvas & {
    flex-wrap: wrap;
    padding-top: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledButton = styled(Button)`
  display: none;

  .filter-canvas & {
    display: block;
  }
`;

const TournamentFilter = ({
  onGenderChange,
  gender,
  onYearFromChange,
  onYearTillChange,
  yearFrom,
  yearTill,
  yearFromOptions,
  yearTillOptions,
  eventTypeOptions,
  eventType,
  opponentOptions,
  opponent,
  onEventTypeChange,
  onOpponentTypeChange,
  resetFilters,
  onClose
}) => {
  return (
    <>
      <TimelineContainer>
        <StyledTabPanel>
          <FilterWrapper id="filters">
            <RadioItem selected={gender} onChange={onGenderChange} />
            <SelectGroup
              onFromChange={onYearFromChange}
              onTillChange={onYearTillChange}
              from={yearFrom}
              till={yearTill}
              fromOptions={yearFromOptions}
              tillOptions={yearTillOptions}
            />
            <StyledSelectItem
              labelText="Toernooi"
              placeholder="Alle"
              value={eventType}
              options={eventTypeOptions}
              onChange={onEventTypeChange}
              wide={true}
            />
            <StyledSelectItem
              labelText="Tegenstander"
              placeholder="Alle"
              value={opponent}
              options={opponentOptions}
              onChange={onOpponentTypeChange}
              wide={true}
            />
            <ButtonWrapper>
              <Button onClick={resetFilters} isGray>
                Wis filters
              </Button>
              <StyledButton onClick={onClose}>Filteren</StyledButton>
            </ButtonWrapper>
          </FilterWrapper>
        </StyledTabPanel>
      </TimelineContainer>
    </>
  );
};

export default TournamentFilter;
