export function getNationalCoachName(teams) {
  if (teams.home && teams.home.composition && teams.home.composition.nationalCoach) {
    return teams.home.composition.nationalCoach.name;
  }

  if (teams.away && teams.away.composition && teams.away.composition.nationalCoach) {
    return teams.away.composition.nationalCoach.name;
  }

  if (teams.teamComposition && teams.teamComposition.nationalCoach) {
    return teams.teamComposition.nationalCoach.name;
  }
  return "";
}

export function getAssistentCoachName(teams) {
  if (teams.home && teams.home.composition && teams.home.composition.assistantManager) {
    return teams.home.composition.assistantManager.name;
  }

  if (teams.away && teams.away.composition && teams.away.composition.assistantManager) {
    return teams.away.composition.assistantManager.name;
  }

  if (teams.teamComposition && teams.teamComposition.assistantManager) {
    return teams.teamComposition.assistantManager.name;
  }

  return "";
}

export function getManagerName(teams) {
  if (teams.home && teams.home.composition && teams.home.composition.manager) {
    return teams.home.composition.manager.name;
  }

  if (teams.away && teams.away.composition && teams.away.composition.manager) {
    return teams.away.composition.manager.name;
  }

  if (teams.teamComposition && teams.teamComposition.manager) {
    return teams.teamComposition.manager.name;
  }

  return "";
}

export function parseLocation(location) {
  if (location === null) {
    return "";
  }

  if (location.country !== null) {
    return `${location.city}, ${location.country.name}`;
  }

  return `${location.city}`;
}

export function parseScore(scores) {
  const regularScore = scores.filter(score => score.type === "regular");
  const extendedScore = scores.filter(score => score.type !== "regular");

  return {
    home: typeof regularScore[0] !== "undefined" ? regularScore[0].home : null,
    away: typeof regularScore[0] !== "undefined" ? regularScore[0].away : null,
    homeExtended: typeof extendedScore[0] !== "undefined" ? extendedScore[0].home : null,
    awayExtended: typeof extendedScore[0] !== "undefined" ? extendedScore[0].away : null
  };
}

export function getGenderLabel(gender) {
  if (gender === "m") {
    return "heren";
  }

  if (gender === "f") {
    return "dames";
  }

  return "";
}

export function getLastLetterOfString(text) {
  return text.substr(text.length - 1).toLowerCase();
}

export function createMarkup(html) {
  return { __html: html };
}

export function parseActions(actions) {
  if (typeof actions === undefined || !actions) {
    return "";
  }

  let goals = actions.map(action => action.minute + "'");

  return goals.join(" ");
}
