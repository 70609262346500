import styled, { css } from "styled-components";
import { color, font } from "../templates/ui";
const Button = styled.button`
  position: relative;
  background-color: ${color.primary.base};
  transition: background 0.2s ease-in-out;
  text-align: center;
  border: none;
  height: 48px;
  padding-left: 18px;
  padding-right: 18px;
  color: ${color.body.base};
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  font-family: ${font.family.head};

  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &:hover,
  &:focus {
    background-color: ${color.primary.hover};
  }

  ${props =>
    props.isGray &&
    css`
      background-color: #cfcfcf;
      color: #656565;

      &:hover,
      &:focus {
        background-color: #b6b6b6;
      }
    `};
`;

export default Button;
