import React from "react";
import styled from "styled-components";

const StyledDock = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 5;
  display: block;

  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const DockInnerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  background-color: transparent;
`;

const Dock = ({ className, children }) => (
  <StyledDock className={className}>
    <DockInnerWrapper>{children}</DockInnerWrapper>
  </StyledDock>
);

export default Dock;
