import React from "react";

const IconHockeystick = ({ className }) => (
  <svg width="130" height="134" viewBox="0 0 130 134" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g fill="#F6F6F6" fillRule="nonzero">
      <path d="M90.64 46.546a3.053 3.053 0 1 1 4.318-4.317l15.003 15.006a3.053 3.053 0 1 1-4.317 4.316L90.64 46.546zM95.81 32.589a3.053 3.053 0 1 1 4.318-4.317l16.088 16.088a3.053 3.053 0 1 1-4.317 4.317L95.81 32.589zM100.412 18.064a3.053 3.053 0 0 1 4.317-4.317L121.784 30.8a3.053 3.053 0 1 1-4.317 4.317l-17.055-17.054z" />
      <path d="M128.715 7.661a15.695 15.695 0 0 1 1.93 14.252l-.028.079-.032.077a933.088 933.088 0 0 1-.455 1.085l-1.285 3.062c-1.386 3.297-2.934 6.97-4.607 10.926a4953.853 4953.853 0 0 1-15.159 35.496c-10.953 25.333-18.31 41.54-20.884 45.508-7.953 12.26-17.861 18.743-28.527 19.57-9.118.708-18.175-2.882-24.765-9.203-6.784-6.524-10.464-16.677-8.904-26.239.587-3.6 2.379-6.533 4.976-8.704 1.959-1.637 4.313-2.754 6.255-3.13 3.407-.657 6.328-.094 8.865 1.56 1.795 1.171 2.918 2.383 5.058 5.1l.108.136c3.103 3.94 4.43 4.839 7.315 4.363 2.054-.338 4.087-2.138 5.665-4.936C66.707 92.282 73.939 76 83.493 53.575c7.6-17.84 15.792-37.551 17.582-42.243C103.468 5.058 109.362.9 115.991.965a15.695 15.695 0 0 1 12.724 6.696zm-5.002 3.5a9.59 9.59 0 0 0-7.777-4.092h-.004c-4.035-.04-7.656 2.515-9.153 6.44-1.828 4.79-10.038 24.545-17.67 42.458-9.691 22.75-16.916 39.014-19.55 43.693-2.401 4.258-5.807 7.275-9.99 7.963-3.18.524-5.919-.089-8.31-1.71-1.68-1.138-2.748-2.302-4.794-4.899l-.107-.136c-1.728-2.194-2.55-3.08-3.598-3.764-1.204-.785-2.514-1.038-4.373-.68-.926.18-2.332.847-3.496 1.82-1.519 1.27-2.527 2.92-2.866 5.003-1.227 7.52 1.732 15.683 7.107 20.852 5.382 5.163 12.76 8.087 20.064 7.52 8.665-.672 16.912-6.068 23.877-16.805 2.283-3.52 9.723-19.908 20.403-44.61l1.007-2.331a4947.886 4947.886 0 0 0 14.132-33.118 5625.056 5625.056 0 0 0 6.303-14.97 9.59 9.59 0 0 0-1.205-8.634zM33.358 67.908c-.091 9.173-7.602 16.536-16.775 16.445C7.409 84.262.046 76.75.138 67.577c.09-9.173 7.602-16.536 16.775-16.445 9.174.092 16.536 7.602 16.445 16.776zm-6.105-.06c.058-5.803-4.599-10.553-10.4-10.61-5.803-.059-10.553 4.598-10.61 10.4-.058 5.802 4.598 10.552 10.4 10.61 5.802.058 10.553-4.599 10.61-10.4z" />
    </g>
  </svg>
);

export default IconHockeystick;
