import ApiClient from "./ApiClient";

export const getMatches = ({ team, from, till, event, opponent, page = 1, per_page = 10 }) =>
  ApiClient.get(`/matches`, {
    params: {
      team: team ? team : null,
      from: from ? from : null,
      till: till ? till : null,
      event: event ? event : null,
      opponent: opponent ? opponent : null,
      page: page,
      per_page: per_page
    }
  });
