import React from "react";
import Menu from "../atoms/Menu";
import MenuItem from "../atoms/MenuItem";
import MenuLink from "../atoms/MenuLink";
import styled from "styled-components";

const StyledMenu = styled(Menu)`
  flex-direction: column;
`;

const StyledMenuItem = styled(MenuItem)`
  width: 100%;
  line-height: 1.2rem;
`;

const AboutMenu = () => (
  <StyledMenu>
    <StyledMenuItem>
      <MenuLink href="http://knhb.nl/veelgestelde-vragen" isFooterLink={true}>
        Veelgestelde vragen
      </MenuLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <MenuLink href="http://knhb.nl/cookieverklaring" isFooterLink={true}>
        Cookieverklaring
      </MenuLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <MenuLink href="https://www.knhb.nl/over-knhb/organisatie" isFooterLink={true}>
        Organisatie
      </MenuLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <MenuLink href="http://knhb.nl/privacyverklaring" isFooterLink={true}>
        Privacyverklaring
      </MenuLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <MenuLink href="https://www.knhb.nl/verenigingsgegevens-en-onderzoek" isFooterLink={true}>
        Verenigingsgegevens en onderzoek
      </MenuLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <MenuLink href="http://knhb.nl/disclaimer" isFooterLink={true}>
        Disclaimer
      </MenuLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <MenuLink href="http://knhb.nl/gebruiksvoorwaarden" isFooterLink={true}>
        Gebruiksvoorwaarden
      </MenuLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <MenuLink href="http://knhb.nl/contact" isFooterLink={true}>
        Contact
      </MenuLink>
    </StyledMenuItem>
  </StyledMenu>
);

export default AboutMenu;
