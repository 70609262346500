import React from "react";
import Card from "../atoms/Card";
import styled from "styled-components";
import { color } from "../templates/ui";
import LocationItem from "./LocationItem";
import IconWorldMap from "../atoms/icons/IconWorldMap";
import IconCity from "../atoms/icons/IconCity";
import Button from "../atoms/Button";
import IconArrowRight from "../atoms/icons/IconArrowRight";
import { Link } from "react-router-dom";

const StyledCardLocation = styled(Card)`
  position: relative;
  left: -20px;
  background-color: ${color.gray.white};
  color: ${color.body.base};

  @media only screen and (min-width: 1200px) {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
`;

const CardInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 50px 20px 30px;
  height: 100%;

  > div:first-child {
    padding-bottom: 20px;
  }
`;

const StyledIconWorldMap = styled(IconWorldMap)`
  width: 100%;
  height: auto;
`;

const StyledIconCity = styled(IconCity)`
  width: 80%;
  height: auto;
`;

const StyledButton = styled(Button)`
  position: absolute;
  bottom: -40px;
  right: -10px;
  padding-right: 40px;
  text-transform: uppercase;
  z-index: 2;
`;

const StyledIconArrowRight = styled(IconArrowRight)`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  fill: ${color.body.base};

  path {
    opacity: 1;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const CardLocation = ({ className, country, city, href }) => (
  <StyledCardLocation className={className}>
    <CardInner>
      <LocationItem icon={<StyledIconWorldMap />} label="Land" text={country} />
      <LocationItem icon={<StyledIconCity />} label="Stad" text={city} />
      <StyledLink to={href}>
        <StyledButton>
          Bekijk toernooi
          <StyledIconArrowRight />
        </StyledButton>
      </StyledLink>
    </CardInner>
  </StyledCardLocation>
);

export default CardLocation;
