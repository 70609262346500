import React from "react";
import styled from "styled-components";
import MenuLink from "../atoms/MenuLink";
import { color, font } from "../templates/ui";
import MenuItem from "../atoms/MenuItem";

const StyledMainMenu = styled.ul`
  display: ${props => (props.isOffCanvasMenu ? "block" : "none")};

  @media only screen and (min-width: 992px) {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 1.6rem;
`;

const StyledLink = styled(MenuLink)`
  position: relative;
  overflow: hidden;
  padding: ${props => (props.isOffCanvasMenu ? "10px" : "0 14px")};
  height: ${props => (props.isOffCanvasMenu ? "47px" : "70px")};
  line-height: ${props => (props.isOffCanvasMenu ? "auto" : "72px")};
  min-width: 60px;
  text-align: center;
  text-transform: uppercase;
  font-family: ${font.family.head};
  font-weight: 700;
  color: ${props => (props.isOffCanvasMenu ? `${color.black.base};` : `${color.base.base}`)};
`;

const MainMenu = ({ isOffCanvasMenu }) => (
  <StyledMainMenu isOffCanvasMenu={isOffCanvasMenu}>
    <StyledMenuItem>
      <StyledLink href="https://www.knhb.nl/nieuws" target="_blank" isMainMenuLink isOffCanvasMenu={isOffCanvasMenu}>
        Nieuws
      </StyledLink>
    </StyledMenuItem>
    <MenuItem>
      <StyledLink href="https://www.knhb.nl/agenda" target="_blank" isMainMenuLink isOffCanvasMenu={isOffCanvasMenu}>
        Agenda
      </StyledLink>
    </MenuItem>
    <MenuItem>
      <StyledLink
        href="https://www.knhb.nl/kenniscentrum"
        target="_blank"
        isMainMenuLink
        isOffCanvasMenu={isOffCanvasMenu}
      >
        Kenniscentrum
      </StyledLink>
    </MenuItem>
    <MenuItem>
      <StyledLink href="https://www.knhb.nl/groepen" target="_blank" isMainMenuLink isOffCanvasMenu={isOffCanvasMenu}>
        Groepen
      </StyledLink>
    </MenuItem>
    <MenuItem>
      <StyledLink href="https://www.knhb.nl/clubs" target="_blank" isMainMenuLink isOffCanvasMenu={isOffCanvasMenu}>
        Clubs
      </StyledLink>
    </MenuItem>
    <MenuItem>
      <StyledLink href="https://www.knhb.nl/over-knhb" target="_blank" isMainMenuLink isOffCanvasMenu={isOffCanvasMenu}>
        Over knhb
      </StyledLink>
    </MenuItem>
    <MenuItem>
      <StyledLink href="https://www.knhb.nl/contact" target="_blank" isMainMenuLink isOffCanvasMenu={isOffCanvasMenu}>
        Contact
      </StyledLink>
    </MenuItem>
  </StyledMainMenu>
);

export default MainMenu;
