import React, { Component } from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import IconArrowRight from "./icons/IconArrowRight";
import IconCheckmark from "./icons/IconCheckmark";
import IconYellowCard from "./icons/IconYellowCard";
import IconRedCard from "./icons/IconRedCard";
import { Link } from "react-router-dom";

const StyledMatchDetailItem = styled.div`
  padding: 10px 15px;
  background-color: ${color.body.base};
  border-bottom: 1px solid ${color.gray.lightest};

  &:hover,
  &:focus {
    background-color: ${color.gray.lighter};
  }
`;

const StyledMatchRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Item = styled.div`
  display: none;

  @media only screen and (min-width: 992px) {
    display: block;
  }
`;

const Name = styled.div`
  color: ${color.black.base};
  font-size: 16px;
  font-family: ${font.family.head};
  font-weight: 600;
  width: 160px;

  @media only screen and (min-width: 768px) {
    width: 280px;
  }
`;

const Goals = styled(Item)`
  display: block;
  font-family: ${font.family.head};
  color: ${color.black.base};
  font-weight: 600;
  width: 100px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    width: 200px;
  }
`;

const Shootouts = styled(Item)`
  width: 90px;
  text-align: center;
`;

const Cards = styled(Item)`
  align-items: center;
  font-family: ${font.family.head};
  color: ${color.black.base};
  font-weight: 600;
  width: 90px;

  span {
    display: block;
    font-size: 14px;
    font-weight: 600;
    font-family: ${font.family.head};
    margin-left: 5px;
  }

  @media only screen and (min-width: 992px) {
    display: flex;
  }
`;

const StyledArrowRight = styled(IconArrowRight)`
  display: block;
  fill: ${color.primary.base};
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

class MatchDetailItem extends Component {
  state = {
    height: 0
  };

  render() {
    const { name, goals, hasShootout, hasYellowCard, hasRedCard, minutes, href } = this.props;

    return (
      <StyledLink to={href}>
        <StyledMatchDetailItem>
          <StyledMatchRow>
            <Name>{name}</Name>
            <Goals>{goals}</Goals>
            <Shootouts>{hasShootout ? <IconCheckmark /> : null}</Shootouts>
            <Cards>
              {hasYellowCard && <IconYellowCard />}
              {hasRedCard && <IconRedCard />}
              <span>{minutes}</span>
            </Cards>
            <StyledArrowRight />
          </StyledMatchRow>
        </StyledMatchDetailItem>
      </StyledLink>
    );
  }
}

export default MatchDetailItem;
