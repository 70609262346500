import React from "react";
import ReactPagination from "react-js-pagination";

const Pagination = ({ total, perPage, currentPage, handlePageChange }) => (
  <ReactPagination
    activePage={currentPage}
    itemsCountPerPage={perPage}
    totalItemsCount={total}
    pageRangeDisplayed={5}
    onChange={handlePageChange}
    innerClass="pagination-list"
    itemClass="pagination-item"
    activeClass="pagination-item--active"
    linkClass="pagination-item__link"
  />
);

export default Pagination;
