import React from "react";
import styled from "styled-components";

const StyledFlag = styled.img`
  width: 33px;
  height: 20px;
`;

const Flag = ({ className, src }) => <StyledFlag className={className} src={src} />;

export default Flag;
