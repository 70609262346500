import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const LocationItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Icon = styled.div`
  flex: 0 0 30%;
  display: flex;
  justify-content: space-around;

  svg {
    fill: ${color.gray.dark};
  }
`;

const LocationTextWrapper = styled.div`
  flex: 0 0 63%;
`;

const LocationLabel = styled.label`
  color: ${color.gray.dark};
  font-weight: 600;
  text-transform: uppercase;
  line-height: 0.8rem;
  display: inline-block;
  font-size: 13px;
`;

const LocationText = styled.div`
  color: ${color.gray.darkest};
  font-size: 17px;
  font-weight: 700;

  @media only screen and (min-width: 992px) {
    font-size: 20px;
  }
`;

const LocationItem = ({ icon, label, text }) => (
  <LocationItemContainer>
    <Icon>{icon}</Icon>
    <LocationTextWrapper>
      <LocationLabel>{label}</LocationLabel>
      <LocationText>{text}</LocationText>
    </LocationTextWrapper>
  </LocationItemContainer>
);

export default LocationItem;
