import React from "react";
import styled from "styled-components";
import TableHeader from "../atoms/TableHeader";
import TableHeaderItem from "../atoms/TableHeaderItem";
import SortButton from "./SortButton";

const StyledTableHeaderItem = styled(TableHeaderItem)`
  &:first-child {
    min-width: auto;
  }
`;

const Name = styled(StyledTableHeaderItem)`
  width: 130px;

  @media only screen and (min-width: 576px) {
    width: 200px;
  }
`;

const Interlands = styled(StyledTableHeaderItem)`
  width: 100px;
  text-align: center;
`;

const Goals = styled(StyledTableHeaderItem)`
  display: none;
  width: 100px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    display: block;
    width: 200px;
  }
`;

const Position = styled(StyledTableHeaderItem)`
  display: none;
  width: 50px;

  @media only screen and (min-width: 576px) {
    width: 100px;
    display: block;
  }
`;

const Team = styled(StyledTableHeaderItem)`
  display: none;

  @media only screen and (min-width: 992px) {
    width: 60px;
    display: block;
  }
`;

const Icon = styled(StyledTableHeaderItem)`
  width: 34px;

  @media only screen and (min-width: 992px) {
    width: 15px;
  }
`;

const InternationalsTableHeader = ({ sortInternationals, sortColumn, sortOrder, className }) => (
  <TableHeader className={className}>
    <Name>
      <SortButton
        direction={sortColumn === "last_name" ? sortOrder : ""}
        column="last_name"
        sortInternationals={sortInternationals}
      >
        Naam
      </SortButton>
    </Name>
    <Interlands>
      <SortButton
        direction={sortColumn === "matches_count" ? sortOrder : ""}
        column="matches_count"
        sortInternationals={sortInternationals}
        centered={true}
      >
        Interlands
      </SortButton>
    </Interlands>
    <Goals className="goals">
      <SortButton
        direction={sortColumn === "actions_count" ? sortOrder : ""}
        column="actions_count"
        sortInternationals={sortInternationals}
        centered={true}
      >
        Doelpunten
      </SortButton>
    </Goals>
    <Team>
      <SortButton
        direction={sortColumn === "gender" ? sortOrder : ""}
        column="gender"
        sortInternationals={sortInternationals}
      >
        Team
      </SortButton>
    </Team>
    <Position className="position">
      <SortButton
        direction={sortColumn === "preferred_position" ? sortOrder : ""}
        column="preferred_position"
        sortInternationals={sortInternationals}
      >
        Positie
      </SortButton>
    </Position>
    <Icon> </Icon>
  </TableHeader>
);

export default InternationalsTableHeader;
