import styled from "styled-components";
import { color } from "../templates/ui";

const Input = styled.input`
  transition: border-color 0.2s ease-in-out;
  position: relative;
  border: 1px solid #dddddd;
  border-radius: 0;
  width: 100%;
  background-color: ${color.body.base};
  height: 48px;
  padding-left: 14px;
  padding-right: ${props => (props.icon !== "" ? "45px" : "14px")};
  color: ${color.base.base};
  font-size: 14px;
  font-weight: 600;
  appearance: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #efefef;
    opacity: 0.8;
  }

  &::placeholder {
    color: #bbbbbb;
    font-weight: 400;
  }

  :-ms-input-placeholder {
    color: #bbbbbb;
    font-weight: 400;
  }

  ::-ms-input-placeholder {
    color: #bbbbbb;
    font-weight: 400;
  }
`;

export default Input;
