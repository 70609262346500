import React from "react";

const IconMagnifier = ({ className }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g fillRule="evenodd">
      <path
        d="M10.781 21.563A10.767 10.767 0 0 1 0 10.78 10.767 10.767 0 0 1 10.781 0a10.767 10.767 0 0 1 10.781 10.781 10.767 10.767 0 0 1-10.78 10.781zm0-20.125a9.325 9.325 0 0 0-9.344 9.343 9.325 9.325 0 0 0 9.344 9.344 9.325 9.325 0 0 0 9.344-9.344 9.325 9.325 0 0 0-9.344-9.344z"
        fillRule="nonzero"
      />
      <path d="M27.626 29.063c-.349 0-.698-.14-.977-.422l-9.565-9.475a.681.681 0 0 1 0-.983c.28-.28.699-.28.978 0l9.564 9.476.978-.983-9.495-9.545a.681.681 0 0 1 0-.983c.28-.28.698-.28.977 0l9.495 9.546c.28.28.419.631.419.982s-.14.702-.419.983l-.977.982c-.28.281-.629.422-.978.422z" />
    </g>
  </svg>
);

export default IconMagnifier;
