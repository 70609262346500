import React, { useEffect, useMemo, useReducer, useRef } from "react";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";

export const setFilters = filters => ({
  type: "SET_FILTERS",
  payload: {
    filters
  }
});
export const setPagination = page => ({
  type: "SET_PAGE",
  payload: {
    page
  }
});

const queryParamsReducerHandlers = {
  SET_FILTERS: (state, action) => ({ ...state, filters: action.payload.filters, pagination: { page: 1 }, dirty: true }),
  SET_PAGE: (state, action) => ({ ...state, pagination: { page: action.payload.page }, dirty: true })
};

const queryParamsReducer = (state, action) => {
  if (queryParamsReducerHandlers.hasOwnProperty(action.type)) {
    return queryParamsReducerHandlers[action.type](state, action);
  }

  throw new Error(`Filters does not support this action type: ${action.type}`);
};

export const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();

  const queryParams = useMemo(() => {
    return qs.parse(location.search, { ignoreQueryPrefix: true });
  }, [location.search]);

  const [state, dispatch] = useReducer(queryParamsReducer, {
    filters: queryParams.filters ?? {},
    pagination: {
      page: parseInt(queryParams.pagination?.page ?? 1)
    },
    dirty: false
  });

  const updateUrlDebouncerRef = useRef();

  // Update url after timeout
  useEffect(() => {
    clearTimeout(updateUrlDebouncerRef.current);
    const { dirty, ..._state } = state;

    if (!dirty) {
      return;
    }

    updateUrlDebouncerRef.current = window.setTimeout(() => {
      history.push({ search: qs.stringify(_state) });
    }, 200);
  }, [history, state]);

  return {
    queryParamsDispatch: dispatch,
    queryParams: state
  };
};

const withQueryParams = Component => props => {
  const queryParamsProps = useQueryParams();

  return <Component {...props} {...queryParamsProps} />;
};

export default withQueryParams;
