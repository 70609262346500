import React, { Component } from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledMenuToggle = styled.button`
  display: block;
  visibility: visible;
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;
  cursor: pointer;
  z-index: 7;
  user-select: none;
  appearance: none;
  transition: all 0.2s ease-in-out 0ms;
  text-transform: none;
  outline: none;
  border-radius: 0;
  border: none;
  background: ${color.primary.base};

  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const MenuGlyph = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 8px;
  border-bottom: 2px solid ${color.body.base};
  transition: all 0.2s ease-in-out 0ms;

  &:before,
  &:after {
    position: absolute;
    display: block;
    content: " ";
    width: 100%;
    height: 2px;
    transition: all 0.2s ease-in-out 0ms;
    background-color: ${color.body.base};
  }

  &:before {
    bottom: 100%;
    left: 0;
    margin-bottom: -1px;
  }

  &:after {
    top: 200%;
    left: 0;
    margin-top: 1px;
  }
`;

class MenuToggle extends Component {
  render() {
    const { onClick } = this.props;
    return (
      <StyledMenuToggle onClick={onClick}>
        <MenuGlyph />
      </StyledMenuToggle>
    );
  }
}

export default MenuToggle;
