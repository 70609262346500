import React from "react";
import styled from "styled-components";
import CardYear from "../molecules/CardYear";
import { font } from "../templates/ui";
import CardResults from "../molecules/CardResults";
import CardLocation from "../molecules/CardLocation";
import CardImage from "../molecules/CardImage";
import MediaQuery from "react-responsive";

const StyledTournamentItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: ${props => (props.isDetailLayout ? "991px" : "650px")};
  font-family: ${font.family.head};
  margin: ${props => (props.isDetailLayout ? "0 auto 40px auto" : "0 auto")};
  padding: ${props => (props.isDetailLayout ? "0" : "0 20px")};
  cursor: grab;

  @media only screen and (min-width: 768px) {
    padding: 0;
  }
`;

const StyledTournamentItemMobile = styled(StyledTournamentItem)`
  @media only screen and (min-width: 1200px) {
    max-width: 75%;
  }
`;

const StyledCardYear = styled(CardYear)`
  flex-basis: 100%;

  @media only screen and (min-width: 768px) {
    flex-basis: ${props => (props.isDetailLayout ? "37.5%" : "50%")};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
`;

const StyledCardYearMobile = styled(StyledCardYear)`
  > div {
    padding: 30px 20px;
  }
`;

const StyledCardResults = styled(CardResults)`
  flex-basis: 100%;
  top: ${props => (props.isDetailLayout ? "0" : "-10px")};
  z-index: ${props => (props.isDetailLayout ? "1" : "0")};

  @media only screen and (min-width: 768px) {
    flex-basis: ${props => (props.isDetailLayout ? "25%" : "50%")};
    transform: ${props => (props.isDetailLayout ? "scale(1.1)" : "none")};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  }
`;

const StyledCardResultsMobile = styled(StyledCardResults)`
  top: 0;
  min-height: 140px;

  > div {
    height: 100%;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      label {
        @media only screen and (min-width: 992px) {
          margin-right: 30px;
        }
      }

      h1 {
        padding: 0;
      }
    }
  }
`;

const StyledCardLocation = styled(CardLocation)`
  display: ${props => (props.isDetailLayout ? "block" : "none")};
  flex-basis: 100%;
  left: ${props => (props.isDetailLayout ? "0" : "-20px")};

  button {
    display: ${props => (props.isDetailLayout ? "none" : "block")};
  }

  @media only screen and (min-width: 768px) {
    display: ${props => (props.isDetailLayout ? "block" : "block")};
    flex-basis: ${props => (props.isDetailLayout ? "37.5%" : "50%")};
  }
`;

const StyledCardLocationMobile = styled(StyledCardLocation)`
  display: block;
  left: 0;
  min-height: 174px;

  > div {
    padding: 0 20px 20px 20px;
  }

  button {
    right: 15px;
    bottom: -190px;

    @media only screen and (min-width: 768px) {
      bottom: -35px;
    }

    @media only screen and (min-width: 1200px) {
      bottom: -55px;
    }
  }
`;

const StyledCardImage = styled(CardImage)`
  flex-basis: 100%;
  display: block;

  @media only screen and (min-width: 768px) {
    flex-basis: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
`;

const TournamentItem = ({
  isDetailLayout,
  year,
  className,
  ranking,
  country,
  city,
  tournamentId,
  tournamentType,
  href,
  showTournamentLink,
  images,
  filterEvent,
  children,
  team
}) => (
  <>
    <MediaQuery query="(max-device-width: 1199px)">
      <StyledTournamentItemMobile isDetailLayout={isDetailLayout} className={className}>
        <StyledCardYearMobile
          isDetailLayout={isDetailLayout}
          year={year}
          tournamentType={tournamentType}
          tournamentId={tournamentId}
          showTournamentLink={showTournamentLink}
          filterEvent={filterEvent}
          team={team}
        />
        <StyledCardResultsMobile isDetailLayout={isDetailLayout} ranking={ranking} />
        <StyledCardLocationMobile isDetailLayout={isDetailLayout} country={country} city={city} href={href} />
        {!isDetailLayout && <StyledCardImage images={images} isDetailLayout={isDetailLayout} />}
        {children}
      </StyledTournamentItemMobile>
    </MediaQuery>
    <MediaQuery query="(min-device-width: 1200px)">
      <StyledTournamentItem isDetailLayout={isDetailLayout} className={className}>
        <StyledCardYear
          isDetailLayout={isDetailLayout}
          year={year}
          tournamentType={tournamentType}
          tournamentId={tournamentId}
          showTournamentLink={showTournamentLink}
          filterEvent={filterEvent}
          team={team}
        />
        <StyledCardResults isDetailLayout={isDetailLayout} ranking={ranking} />
        <StyledCardLocation isDetailLayout={isDetailLayout} country={country} city={city} href={href} />
        {!isDetailLayout && <StyledCardImage images={images} isDetailLayout={isDetailLayout} />}
        {children}
      </StyledTournamentItem>
    </MediaQuery>
  </>
);

export default TournamentItem;
