import React from "react";

const IconFacebook = ({ className }) => (
  <svg
    width="30px"
    height="50px"
    viewBox="0 0 30 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <defs />
    <path
      d="M18.5264347,0.89374698 C17.2084274,1.09197305 15.9036583,1.47585298 14.6758478,2.09266034 C11.9267877,3.47368819 9.91662403,5.83837222 8.99959296,9.32799931 C8.91768991,9.54166757 8.77082761,10.1107665 8.62959771,10.9738694 C8.39978176,12.378351 8.26488186,14.1201259 8.26601048,16.2237735 L0.363367718,16.2472427 L0.363367718,25.3545369 L8.27654664,25.3310351 L8.27654664,49.3515625 L20.3633677,49.3515625 L20.3633677,25.3515609 L28.3633677,25.3515627 L28.3633677,16.2207773 L20.3633677,16.2207775 L20.3633677,13.3477537 C20.3633677,10.9120051 21.7158893,9.43513393 24.7016201,9.43513393 L29.4578833,9.43513393 L29.4578833,0.752181548 L20.8220135,0.752181548 C20.1584519,0.728090093 19.4007566,0.762250439 18.5264347,0.89374698 Z M27.4578833,7.43513393 L24.7016201,7.43513393 C20.570136,7.43513393 18.3633677,9.84479035 18.3633677,13.3477537 L18.3633677,18.2207775 L26.3633677,18.2207773 L26.3633677,23.3515623 L18.3633677,23.3515605 L18.3633677,47.3515625 L10.2765466,47.3515625 L10.2765466,23.3250863 L2.36336772,23.3485881 L2.36336772,18.2413119 L10.2969055,18.217751 L10.2763423,17.2005651 C10.226876,14.7536605 10.3575948,12.7987189 10.6033487,11.2968351 C10.7248306,10.5544192 10.84068,10.1054966 10.9068239,9.92314381 C11.7004704,6.91931432 13.3232389,5.01034797 15.5736528,3.87982184 C16.5983273,3.36506263 17.7027935,3.04011439 18.823886,2.87150397 C19.5676192,2.75964775 20.2040714,2.73095359 20.6675969,2.74734565 C20.7265001,2.74983344 20.793904,2.75218155 20.793904,2.75218155 L27.4578833,2.75218155 L27.4578833,7.43513393 Z"
      id="Shape"
      stroke="none"
      fillRule="evenodd"
    />
  </svg>
);

export default IconFacebook;
