import React from "react";

const IconArrowRight = ({ className }) => (
  <svg width="15" height="13" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M8.885.9L14.5 6.515l-5.615 5.614L7.471 10.7l3.183-3.183H.5V5.513h10.154L7.47 2.329z"
      fillRule="evenodd"
      opacity=".7"
    />
  </svg>
);

export default IconArrowRight;
