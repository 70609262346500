import React from "react";
import styled from "styled-components";
import TableHeaderItem from "../atoms/TableHeaderItem";
import TableHeader from "../atoms/TableHeader";

const StyledTableHeaderItem = styled(TableHeaderItem)`
  display: none;
  width: 90px;
  text-align: center;

  &:first-child,
  &:nth-child(2) {
    min-width: auto;
  }

  @media only screen and (min-width: 992px) {
    display: block;
  }
`;

const Name = styled(StyledTableHeaderItem)`
  display: block;
  text-align: left;
  width: 160px;

  @media only screen and (min-width: 768px) {
    width: 280px;
  }
`;

const Icon = styled(StyledTableHeaderItem)`
  display: block;
  width: 24px;
`;

const Goal = styled(StyledTableHeaderItem)`
  display: block;
  width: 100px;

  @media only screen and (min-width: 768px) {
    width: 200px;
  }
`;

const Shootout = styled(StyledTableHeaderItem)``;
const Cards = styled(StyledTableHeaderItem)`
  text-align: left;
`;

const MatchDetailTableHeader = () => (
  <TableHeader>
    <Name>Naam</Name>
    <Goal>Doelpunt</Goal>
    <Shootout> </Shootout>
    <Cards> </Cards>
    <Icon />
  </TableHeader>
);

export default MatchDetailTableHeader;
