import React from "react";

const IconErrorField = ({ className }) => (
  <svg width="212" height="155" viewBox="0 0 212 155" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M31.568 68.708h-.095l-9.85 14.104h9.945V68.708zm7.985 14.104c2.487 0 2.822 1.722 2.822 2.441 0 .764-.335 2.484-2.822 2.484H37.21v4.352c0 2.631-1.864 3.109-2.821 3.109-.956 0-2.821-.478-2.821-3.109v-4.352H19.231c-3.155 0-3.729-2.199-3.729-3.203 0-.765.239-1.722 1.052-2.869l13.197-18.744c1.483-2.104 2.822-2.438 3.874-2.438 1.147 0 3.585.575 3.585 4.254v18.075h2.343z"
        fill="#DF712B"
      />
      <path
        d="M31.568 68.708h-.095l-9.85 14.104h9.945V68.708zm7.985 14.104c2.487 0 2.822 1.722 2.822 2.441 0 .764-.335 2.484-2.822 2.484H37.21v4.352c0 2.631-1.864 3.109-2.821 3.109-.956 0-2.821-.478-2.821-3.109v-4.352H19.231c-3.155 0-3.729-2.199-3.729-3.203 0-.765.239-1.722 1.052-2.869l13.197-18.744c1.483-2.104 2.822-2.438 3.874-2.438 1.147 0 3.585.575 3.585 4.254v18.075h2.343z"
        stroke="#DF712B"
        strokeWidth=".8"
      />
      <path
        d="M184.396 68.708h-.095l-9.85 14.104h9.945V68.708zm7.985 14.104c2.486 0 2.822 1.722 2.822 2.441 0 .764-.336 2.484-2.822 2.484h-2.343v4.352c0 2.631-1.864 3.109-2.821 3.109-.956 0-2.821-.478-2.821-3.109v-4.352h-12.337c-3.156 0-3.729-2.199-3.729-3.203 0-.765.238-1.722 1.052-2.869l13.197-18.744c1.483-2.104 2.821-2.438 3.874-2.438 1.147 0 3.585.575 3.585 4.254v18.075h2.343z"
        fill="#DF712B"
      />
      <path
        d="M184.396 68.708h-.095l-9.85 14.104h9.945V68.708zm7.985 14.104c2.486 0 2.822 1.722 2.822 2.441 0 .764-.336 2.484-2.822 2.484h-2.343v4.352c0 2.631-1.864 3.109-2.821 3.109-.956 0-2.821-.478-2.821-3.109v-4.352h-12.337c-3.156 0-3.729-2.199-3.729-3.203 0-.765.238-1.722 1.052-2.869l13.197-18.744c1.483-2.104 2.821-2.438 3.874-2.438 1.147 0 3.585.575 3.585 4.254v18.075h2.343z"
        stroke="#DF712B"
        strokeWidth=".8"
      />
      <path d="M106.028 4.794v6" stroke="#939490" strokeWidth="4" strokeLinecap="round" />
      <path
        d="M106.028 35.175v97.522"
        stroke="#939490"
        strokeWidth="4"
        strokeLinecap="round"
        strokeDasharray="12.19,24.381"
      />
      <path d="M106.028 144.888v6" stroke="#939490" strokeWidth="4" strokeLinecap="round" />
      <path
        d="M87.632 77.841c0 10.158 8.233 18.395 18.396 18.395 10.16 0 18.397-8.237 18.397-18.395 0-10.16-8.237-18.396-18.397-18.396-10.163 0-18.396 8.236-18.396 18.396z"
        stroke="#DF712B"
        strokeWidth="7.3"
        strokeLinecap="round"
      />
      <path
        d="M207.986 134.091c-2.024 0-4.023-.107-5.992-.315"
        stroke="#939490"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M179.791 126.526c-16.773-9.737-28.055-27.893-28.055-48.686 0-24.959 16.255-46.121 38.756-53.476"
        stroke="#939490"
        strokeWidth="4"
        strokeLinecap="round"
        strokeDasharray="11.762,23.524"
      />
      <path
        d="M201.994 21.906a56.75 56.75 0 015.991-.315M4.943 134.091c2.025 0 4.024-.107 5.994-.315"
        stroke="#939490"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M33.138 126.526c16.775-9.737 28.055-27.893 28.055-48.686 0-24.959-16.255-46.121-38.755-53.476"
        stroke="#939490"
        strokeWidth="4"
        strokeLinecap="round"
        strokeDasharray="11.762,23.524"
      />
      <path d="M10.936 21.906a56.759 56.759 0 00-5.993-.315" stroke="#939490" strokeWidth="4" strokeLinecap="round" />
      <path
        d="M200.885 2.778H11.172a8.842 8.842 0 00-8.841 8.841v132.443a8.841 8.841 0 008.841 8.84h189.713a8.84 8.84 0 008.841-8.84V11.619a8.841 8.841 0 00-8.841-8.841z"
        stroke="#DF712B"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default IconErrorField;
