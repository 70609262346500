import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledTimelineItem = styled.li`
  position: relative;
  display: inline-block;

  &:first-child {
    button {
      top: -3px;
      width: 18px;
      height: 18px;
      border-radius: 12px;
      background-color: ${color.primary.base};
      padding: 0;

      > div {
        display: block;
      }

      // &:before {
      //   width: 34px;
      //   height: 34px;
      //   border-radius: 50%;
      //   border: 1px solid ${color.primary.base};
      //   background-color: transparent;
      //   z-index: 2;
      // }
      //
      // &:after {
      //   content: "";
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
      //   width: 54px;
      //   height: 54px;
      //   border-radius: 50%;
      //   border: 1px solid ${color.primary.base};
      //   z-index: 1;
      // }
    }
  }
`;

const TimelineItem = ({ children, index }) => <StyledTimelineItem key={index}>{children}</StyledTimelineItem>;

export default TimelineItem;
