import React from "react";
import Card from "../atoms/Card";
import styled from "styled-components";
import IconArrowRight from "../atoms/icons/IconArrowRight";
import CardButton from "../atoms/CardButton";
import { color, font } from "../templates/ui";
import { getLastLetterOfString } from "../../helpers";

const StyledCardYear = styled(Card)`
  background-color: ${color.primary.base};
  color: ${color.body.base};
  min-height: 174px;
`;

const StyledIconArrowRight = styled(IconArrowRight)`
  margin-left: 5px;
  fill: ${color.body.base};
`;

const CardInner = styled.div`
  position: relative;
  padding: 20px 50px 20px 20px;
  display: flex;
  justify-content: space-between;
  height: 100%;

  @media only screen and (min-width: 350px) {
    padding: 20px 70px 20px 20px;
  }
`;

const CardLabel = styled.label`
  display: block;
  font-size: 16px;
  text-transform: uppercase;
`;

const Year = styled.h1`
  margin: 0;
  font-size: 52px;
  font-family: ${font.family.alt};
  line-height: 3.4rem;

  @media only screen and (min-width: 1200px) {
    font-size: 65px;
  }
`;

const Type = styled.label`
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;

  @media only screen and (min-width: 1200px) {
    font-size: 30px;
  }
`;

const StyledCardButton = styled(CardButton)`
  background-color: ${color.primary.darker};
  color: ${color.body.base};
`;

const Team = styled.h3`
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-family: ${font.family.head};
  font-size: 26px;
  font-weight: 700;
  color: ${color.body.base};
  line-height: 1rem;
  text-transform: uppercase;
  opacity: 0.7;
`;

const CardYear = ({ className, year, tournamentId, tournamentType, showTournamentLink, filterEvent, team }) => (
  <StyledCardYear className={className}>
    <CardInner>
      <div>
        <CardLabel>Jaar</CardLabel>
        <Year>{year}</Year>
      </div>
      <>
        <div>
          <CardLabel>Toernooi</CardLabel>
          {tournamentType && <Type>{tournamentType}</Type>}
        </div>
      </>
      {team === "m" && <Team>Heren</Team>}
      {team === "f" && <Team>dames</Team>}
    </CardInner>
    {showTournamentLink && (
      <StyledCardButton tournamentId={tournamentId} filterEvent={filterEvent}>
        {tournamentType && (
          <>
            Toon alle {tournamentType} {getLastLetterOfString(`${tournamentType}`) !== "s" ? "'s" : ""}
          </>
        )}
        <StyledIconArrowRight />
      </StyledCardButton>
    )}
  </StyledCardYear>
);

export default CardYear;
