import React from "react";
import styled from "styled-components";

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 50%;
`;

const Card = ({ children, className }) => <StyledCard className={className}>{children}</StyledCard>;

export default Card;
