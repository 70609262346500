import React, { Component } from "react";
import styled from "styled-components";
import Header from "../organisms/Header";
import Layout from "../templates/Layout";
import Container from "../atoms/Container";
import Footer from "../organisms/Footer";
import OffCanvasContainer from "../organisms/OffCanvasContainer";
import MainMenu from "../molecules/MainMenu";
import FiltersCanvas from "../organisms/offcanvas/FiltersCanvas";
import Card from "../atoms/Card";
import PlayerRow from "../molecules/PlayerRow";
import { getInternationals } from "../../api/Internationals";
import AppLinks from "../molecules/AppLinks";
import InternationalFilter from "../molecules/InternationalFilter";
import Pagination from "../molecules/Pagination";
import IconLoader from "../atoms/icons/IconLoader";
import { Fade } from "react-reveal";
import InternationalsTableHeader from "../molecules/PlayersTableHeader";
import Label from "../atoms/Label";
import FilterButton from "../atoms/FilterButton";
import Dock from "../molecules/Dock";
import produce from "immer";
import withQueryParams, { setFilters, setPagination } from "../hoc/withQueryParams";
import { getBreadcrumbs } from "../molecules/Breadcrumbs";

const StyledCard = styled(Card)`
  flex: auto;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const StyledContainer = styled(Container)`
  padding: 0;
`;

const StyledIconLoader = styled(IconLoader)`
  display: block;
  margin: 0 auto;
  width: 52px;

  @media only screen and (min-width: 576px) {
    height: 501px;
  }
`;

const StyledInternationalsTableHeader = styled(InternationalsTableHeader)`
  @media only screen and (min-width: 768px) {
    .goals {
      width: 100px;
    }

    .position {
      width: 100px;
    }
  }
`;

class Internationals extends Component {
  state = {
    menu_open: false,
    sort_column: "matches_count",
    sort_direction: "desc",
    showFiltersCanvas: false,
    internationals: {
      data: null,
      meta: {
        pagination: {
          links: {}
        },
        filters: {
          years: [],
          tournaments: [],
          classifications: []
        }
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      this.fetchInternationals();
    }
  }

  handleSearchInputChange = event => {
    this.props.queryParamsDispatch(
      setFilters({
        ...this.props.queryParams.filters,
        search: event.target.value
      })
    );
  };

  handlePageChange = page => this.props.queryParamsDispatch(setPagination(page));

  handleSearchClick = event => {
    event.preventDefault();

    this.fetchInternationals();
  };

  handleGenderChange = event => {
    this.props.queryParamsDispatch(
      setFilters({
        ...this.props.queryParams.filters,
        gender: event.target.value
      })
    );
  };

  resetFilters = event => {
    event.preventDefault();
    this.props.queryParamsDispatch(setFilters({}));
  };

  sortInternationals = event => {
    var sortOrder = this.state.sort_direction === "desc" ? "asc" : "desc";
    sortOrder =
      event.target.value === "matches_count" && this.state.sort_column !== "matches_count" ? "desc" : sortOrder;
    sortOrder =
      event.target.value === "actions_count" && this.state.sort_column !== "actions_count" ? "desc" : sortOrder;

    this.setState({ sort_column: event.target.value, sort_direction: sortOrder }, () => this.fetchInternationals());
  };

  toggleStateBool = fieldName => {
    this.setState(prevState => {
      return {
        ...prevState,
        [fieldName]: !prevState[fieldName]
      };
    });
  };

  fetchInternationals = () => {
    this.setState(
      produce(draft => {
        draft.internationals.data = null;
      })
    );

    getInternationals({
      search: this.props.queryParams.filters.search,
      page: this.props.queryParams.pagination.page,
      team: this.props.queryParams.filters.gender,
      sort_column: this.state.sort_column,
      sort_direction: this.state.sort_direction
    }).then(response =>
      this.setState({
        internationals: response.data
      })
    );
  };

  componentDidMount() {
    this.fetchInternationals();
  }

  getAllInternationals = () => {
    if (this.state.internationals.data === null) {
      return <StyledIconLoader />;
    }

    if (this.state.internationals.data.length === 0) {
      return <Label>Er zijn geen internationals gevonden</Label>;
    }

    return this.state.internationals.data.map((international, index) => (
      <Fade bottom distance="40px" delay={index * 50} key={international.id}>
        <PlayerRow
          href={{
            pathname: `/internationals/${international.id}`,
            state: {
              breadcrumbs: getBreadcrumbs(this.props.location, "International")
            }
          }}
          name={international.name}
          interlands={international.stats.caps}
          goals={international.stats.goals}
          preferredPosition={international.preferredPosition}
          team={international.gender}
          data-testid={`international-${international.name}`}
        />
      </Fade>
    ));
  };

  render() {
    return (
      <>
        <Header toggleOffCanvas={() => this.setState({ menu_open: !this.state.menu_open })} />
        <Layout>
          <Container>
            <AppLinks
              results={this.state.internationals.meta.pagination.total}
              breadcrumbs={this.props.location.state?.breadcrumbs || []}
            />
            <InternationalFilter
              onSearchChange={this.handleSearchInputChange}
              onSearchClick={this.handleSearchClick}
              onGenderChange={this.handleGenderChange}
              search={this.props.queryParams.filters.search ?? ""}
              gender={this.props.queryParams.filters.gender ?? ""}
              resetFilters={this.resetFilters}
            />
            <StyledContainer isCompact>
              <StyledCard>
                {this.state.internationals.data?.length >= 1 && (
                  <StyledInternationalsTableHeader
                    sortInternationals={this.sortInternationals}
                    sortColumn={this.state.sort_column}
                    sortOrder={this.state.sort_direction}
                  />
                )}
                {this.getAllInternationals()}
              </StyledCard>
              {this.state.internationals.meta.pagination.total !== 0 &&
              this.state.internationals.meta.pagination.total > 10 ? (
                <Pagination
                  total={this.state.internationals.meta.pagination.total}
                  count={this.state.internationals.meta.pagination.count}
                  perPage={this.state.internationals.meta.pagination.per_page}
                  currentPage={this.props.queryParams.pagination.page}
                  totalPages={this.state.internationals.meta.pagination.total_pages}
                  next={this.state.internationals.meta.pagination.links.next}
                  previous={this.state.internationals.meta.pagination.links.previous}
                  handlePageChange={this.handlePageChange}
                />
              ) : null}
            </StyledContainer>
          </Container>
        </Layout>
        <Footer />
        <OffCanvasContainer containerOpen={this.state.menu_open} isMenu={true}>
          <MainMenu isOffCanvasMenu={true} />
        </OffCanvasContainer>
        <FiltersCanvas toggle={() => this.toggleStateBool("showFiltersCanvas")} open={this.state.showFiltersCanvas}>
          <InternationalFilter
            onSearchChange={this.handleSearchInputChange}
            onSearchClick={this.handleSearchClick}
            onGenderChange={this.handleGenderChange}
            search={this.props.queryParams.filters.search ?? ""}
            gender={this.props.queryParams.filters.gender ?? ""}
            resetFilters={this.resetFilters}
            onClose={event => {
              event.preventDefault();
              this.toggleStateBool("showFiltersCanvas");
              this.fetchInternationals();
            }}
          />
        </FiltersCanvas>
        <Dock>
          <FilterButton onClick={() => this.toggleStateBool("showFiltersCanvas")}>Toon filters</FilterButton>
        </Dock>
      </>
    );
  }
}

export default withQueryParams(Internationals);
