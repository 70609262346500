import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #e4e4e4;
  border-top: 1px solid ${color.gray.alt};
`;

const TableHeader = ({ children, className }) => (
  <StyledTableHeader className={className}>{children}</StyledTableHeader>
);

export default TableHeader;
