import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import Container from "../atoms/Container";
import { createMarkup } from "../../helpers";

const StyledIntroBlock = styled.div`
  background-color: ${color.body.base};
  padding: 35px 25px 25px;
`;

const Title = styled.h3`
  font-size: 22px;
  text-transform: uppercase;
  color: ${color.primary.base};
  margin-bottom: 10px;
`;

const Paragraph = styled.div`
  color: #797979;
  margin: 0;
  font-size: 16px;
  font-family: ${font.family.head};

  p,
  span {
    color: #797979 !important;
    margin: 0 !important;
    font-size: 16px !important;
    font-family: ${font.family.head} !important;
  }
`;

const StyledContainer = styled(Container)`
  padding: 0;
  max-width: 580px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    margin-top: 0;
  }
`;

const IntroBlock = ({ title, description }) => (
  <StyledContainer isCompact>
    {title || description ? (
      <StyledIntroBlock>
        {title && <Title>{title}</Title>}
        {description && (
          <Paragraph>
            <div dangerouslySetInnerHTML={createMarkup(`${description}`)} />
          </Paragraph>
        )}
      </StyledIntroBlock>
    ) : null}
  </StyledContainer>
);

export default IntroBlock;
