import ApiClient from "./ApiClient";

export const getInternationals = ({ search, team, from, till, page = 1, per_page = 10, sort_column, sort_direction }) =>
  ApiClient.get(`/players`, {
    params: {
      search: search ? (search === "#" ? "hek" : search) : null,
      team: team ? team : null,
      from: from ? from : null,
      till: till ? till : null,
      page: page,
      per_page: per_page,
      sort_column: sort_column ? sort_column : null,
      sort_direction: sort_direction ? sort_direction : null
    }
  });
