import React, { Component } from "react";
import styled from "styled-components";
import Row from "../atoms/Row";
import Date from "../molecules/Date";
import Location from "../molecules/Location";
import IconArrowRight from "../atoms/icons/IconArrowRight";
import { color, font } from "../templates/ui";
import ChampionShip from "../molecules/ChampionShip";
import { Link } from "react-router-dom";
import Flag from "../atoms/Flag";
import Responsive from "react-responsive";
import IconStick from "../atoms/icons/IconStick";

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledRow = styled(Row)`
  justify-content: space-between;
  border-bottom: ${props => (props.active ? "none" : "1px solid #f1f1f1")};
`;

const StyledIconArrowRight = styled(IconArrowRight)`
  fill: ${color.primary.base};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TeamsWrapper = styled(Wrapper)`
  max-width: 130px;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    max-width: none;
    justify-content: normal;
  }

  @media only screen and (min-width: 992px) {
    min-width: 400px;
  }
`;

const Score = styled.div`
  display: flex;

  @media only screen and (min-width: 375px) {
    margin: 0 5px;
  }

  @media only screen and (min-width: 992px) {
    margin: 0 20px;
    font-size: 20px;
  }
`;

const ScoreWrapper = styled.div`
  display: block;
  color: ${color.primary.base};
  font-weight: 700;
  font-size: 18px;
  font-family: ${font.family.head};
  letter-spacing: 2px;
  min-width: 25px;
  text-align: right;

  &:last-child {
    text-align: left;
  }
`;

const Country = styled.div`
  display: none;
  font-family: ${font.family.head};
  font-weight: 600;
  font-size: 17px;
  color: #1f1f1f;
  margin: 0 10px;

  @media only screen and (min-width: 992px) {
    display: block;
  }
`;

const CountryMobile = styled.div`
  font-family: ${font.family.head};
  font-weight: 600;
  font-size: 16px;
  color: #1f1f1f;

  &:first-child {
    margin-left: 0;
  }

  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const StyledLocation = styled(Location)`
  display: none;

  @media only screen and (min-width: 992px) {
    display: flex;
    min-width: 170px;
    max-width: 170px;
    margin-right: 20px;

    > div {
      &:last-child {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 170px;
      }
    }
  }
`;

const StyledDate = styled(Date)`
  margin-left: 5px;

  @media only screen and (min-width: 375px) {
    margin-left: 10px;
  }
`;

const StyledFlag = styled(Flag)`
  width: 25px;
  height: 15px;

  @media only screen and (min-width: 375px) {
    margin: 0 5px;
  }

  @media only screen and (min-width: 768px) {
    margin: 0 10px;
  }

  @media only screen and (min-width: 992px) {
    width: 33px;
    height: 20px;
    margin: 0;
  }
`;

const WonByShootouts = styled.span`
  color: ${color.primary.base} !important;
  font-weight: 700;
`;

const CountryWrapper = styled.div`
  display: flex;

  &:first-child {
    justify-content: flex-end;
  }

  &:last-child {
    justify-content: flex-start;
  }

  @media only screen and (min-width: 992px) {
    min-width: 180px;
  }
`;

const DesktopChampionShip = styled(ChampionShip)`
  min-width: 70px;
`;

const DesktopDate = styled(Date)`
  > div {
    min-width: 80px;
  }
`;

const MatchIndex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Source Sans Pro Webfont";
  font-size: 16px;
  font-weight: 700;
  color: ${color.black.base};
  width: 40px;
  height: 36px;
`;

const GoalsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50px;
  height: 36px;
`;

const Goals = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: "Source Sans Pro Webfont";
  font-size: 16px;
  font-weight: 700;
  color: ${color.primary.base};
  min-width: 17px;
`;

const StyledStick = styled(IconStick)`
  position: absolute;
  bottom: 0;
  left: 12px;
  width: 20px;
  height: 26px;
  fill: ${color.primary.base};
`;

const StyledWrapper = styled.div`
  display: none;

  @media only screen and (min-width: 992px) {
    display: flex;
  }
`;

const Divider = styled.span`
  font-family: "Merriweather Webfont", "Times New Roman", "serif";
  color: #9b9b9b;
`;

const Team = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-start;
  width: 50px;
  height: 36px;
  font-family: "Source Sans Pro Webfont";
  font-weight: 600;
  color: ${color.black.base};
  margin-left: 20px;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

const Desktop = props => <Responsive {...props} minWidth={991} />;
const Mobile = props => <Responsive {...props} maxWidth={990} />;

class MatchRow extends Component {
  render() {
    const {
      href,
      date,
      location,
      type,
      homeTeam,
      homeTeamShort,
      awayTeam,
      awayTeamShort,
      homeFlag,
      awayFlag,
      homeScore,
      awayScore,
      homeScoreExtended,
      awayScoreExtended,
      matchIndex,
      team,
      playerActions = null
    } = this.props;

    return (
      <>
        <Mobile>
          <StyledLink to={href}>
            <StyledRow>
              <TeamsWrapper>
                <CountryWrapper>
                  <Country>{homeTeam}</Country>
                  <CountryMobile>{homeTeamShort}</CountryMobile>
                  <StyledFlag src={homeFlag} />
                </CountryWrapper>
                <Score>
                  <ScoreWrapper>
                    {homeScoreExtended > awayScoreExtended ? <WonByShootouts>*</WonByShootouts> : ""}
                    {homeScore}
                  </ScoreWrapper>
                  <Divider>-</Divider>
                  <ScoreWrapper>
                    {awayScore}
                    {awayScoreExtended > homeScoreExtended ? <WonByShootouts>*</WonByShootouts> : ""}
                  </ScoreWrapper>
                </Score>
                <CountryWrapper>
                  <StyledFlag src={awayFlag} />
                  <Country>{awayTeam}</Country>
                  <CountryMobile>{awayTeamShort}</CountryMobile>
                </CountryWrapper>
              </TeamsWrapper>
              {team === "m" && <Team>Heren</Team>}
              {team === "f" && <Team>Dames</Team>}
              <div>
                <StyledDate date={date} />
              </div>
              <Wrapper>
                <StyledIconArrowRight />
              </Wrapper>
            </StyledRow>
          </StyledLink>
        </Mobile>
        <Desktop>
          <StyledLink to={href}>
            <StyledRow>
              <StyledWrapper>
                {matchIndex && <MatchIndex>{matchIndex}</MatchIndex>}
                <DesktopDate date={date} />
                <DesktopChampionShip type={type} hideIcon={true} />
                {playerActions > 0 ? (
                  <GoalsWrapper>
                    <Goals>{playerActions}</Goals> <StyledStick />
                  </GoalsWrapper>
                ) : (
                  <GoalsWrapper />
                )}
                {team === "m" && <Team>Heren</Team>}
                {team === "f" && <Team>Dames</Team>}
              </StyledWrapper>
              <TeamsWrapper>
                <CountryWrapper>
                  <Country>{homeTeam}</Country>
                  <CountryMobile>{homeTeamShort}</CountryMobile>
                  <StyledFlag src={homeFlag} />
                </CountryWrapper>
                <Score>
                  <ScoreWrapper>
                    {homeScoreExtended > awayScoreExtended ? <WonByShootouts>*</WonByShootouts> : ""}
                    {homeScore}
                  </ScoreWrapper>
                  <Divider>-</Divider>
                  <ScoreWrapper>
                    {awayScore}
                    {awayScoreExtended > homeScoreExtended ? <WonByShootouts>*</WonByShootouts> : ""}
                  </ScoreWrapper>
                </Score>
                <CountryWrapper>
                  <StyledFlag src={awayFlag} />
                  <Country>{awayTeam}</Country>
                  <CountryMobile>{awayTeamShort}</CountryMobile>
                </CountryWrapper>
              </TeamsWrapper>
              <Wrapper>
                <StyledLocation location={location} title={location} />
                <StyledIconArrowRight />
              </Wrapper>
            </StyledRow>
          </StyledLink>
        </Desktop>
      </>
    );
  }
}

export default MatchRow;
