import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import IconArrowRight from "./icons/IconArrowRight";
import { Link } from "react-router-dom";

const StyledMatchItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid ${color.gray.lightest};
`;

const Country = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (min-width: 576px) {
    min-width: 130px;
  }
`;

const Name = styled.div`
  color: ${color.black.base};
  font-size: 13px;
  font-family: ${font.family.head};
  font-weight: 600;

  @media only screen and (min-width: 992px) {
    font-size: 15px;
  }

  span {
    &:nth-child(1) {
      display: none;

      @media only screen and (min-width: 576px) {
        display: block;
      }
    }

    &:nth-child(2) {
      display: block;

      @media only screen and (min-width: 576px) {
        display: none;
      }
    }
  }
`;

const HomeFlag = styled.img`
  max-height: 15px;
  margin-left: 5px;
`;

const AwayFlag = styled.img`
  max-height: 15px;
  margin-right: 5px;
`;

const Score = styled.div`
  color: #5e5e5e;
  font-weight: 700;
  font-family: ${font.family.head};
  margin: 0 10px;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
    min-width: 40px;
    text-align: center;
  }
`;

const Date = styled.span`
  color: #797979;
  font-weight: 600;
  font-size: 13px;
  font-family: ${font.family.head};

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const CountryWrapper = styled.div`
  display: flex;
  min-width: 130px;

  > div {
    &:first-child {
      justify-content: flex-end;
    }
  }

  @media only screen and (min-width: 576px) {
    min-width: 320px;
  }
`;

const StyledIconArrowRight = styled(IconArrowRight)`
  fill: ${color.primary.base};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const MatchItem = ({
  homeTeam,
  homeTeamShort,
  homeFlag,
  homeScore,
  awayScore,
  awayFlag,
  awayTeam,
  awayTeamShort,
  date,
  href,
  className
}) => (
  <StyledLink to={href}>
    <StyledMatchItem className={className}>
      <CountryWrapper>
        <Country>
          <Name>
            <span>{homeTeam}</span>
            <span>{homeTeamShort}</span>
          </Name>
          <HomeFlag src={homeFlag} />
        </Country>
        <Score>
          {homeScore}-{awayScore}
        </Score>
        <Country>
          <AwayFlag src={awayFlag} />
          <Name>
            <span>{awayTeam}</span>
            <span>{awayTeamShort}</span>
          </Name>
        </Country>
      </CountryWrapper>
      <Date>{date}</Date>
      <StyledIconArrowRight />
    </StyledMatchItem>
  </StyledLink>
);

export default MatchItem;
