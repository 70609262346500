import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import IconArrowRight from "./icons/IconArrowRight";
import { Link } from "react-router-dom";

const StyledTeamMember = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: ${color.body.base};
  border-bottom: 1px solid ${color.gray.lightest};
`;

const Name = styled.div`
  color: ${color.black.base};
  font-size: 16px;
  font-family: ${font.family.head};
  font-weight: 600;
  flex: 1;
`;

const StyledLink = styled(Link)`
  color: ${color.black.base};
  text-decoration: none;
`;

const StyledArrowRight = styled(IconArrowRight)`
  fill: ${color.primary.base};
`;

const TeamMember = ({ className, name, href }) => (
  <StyledLink to={href}>
    <StyledTeamMember className={className}>
      <Name>{name}</Name>
      <StyledArrowRight />
    </StyledTeamMember>
  </StyledLink>
);

export default TeamMember;
