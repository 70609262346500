import React from "react";
import Card from "../atoms/Card";
import styled from "styled-components";
import { color } from "../templates/ui";
import TinySlider from "tiny-slider-react";

const StyledCardPlayerSlider = styled(Card)`
  color: ${color.gray.darkest};
  width: 100%;

  @media only screen and (min-width: 1200px) {
    max-width: 25%;
  }

  .tns-outer {
    position: relative;
    height: 100%;
  }

  .tns-ovh {
    height: 100%;
  }

  .tns-item {
    img {
      width: 100%;
    }
  }

  .tns-controls {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;

    button {
      position: relative;
      background-color: ${color.primary.base};
      transition: background 0.2s ease-in-out;
      text-align: center;
      border: none;
      width: 32px;
      height: 32px;
      padding: 0;
      color: transparent;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:first-child {
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url("data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjlweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgOSAxNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJUb2Vybm9vaWVuLWxpanN0L3RpamRsaWpuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAyNi4wMDAwMDAsIC03NzQuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxnIGlkPSJUb2Vybm9vaS1pdGVtIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNjAuMDAwMDAwLCAzODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iU2xpZGVyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYuMDAwMDAwLCAxODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMxNi4wMDAwMDAsIDIwMi4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTE3LjY4MDkyNDMsMjIuODc5OTcyNiBMMTQsMTkuMjU5OTQ1MSBMMTcuNjkxMDM2OSwxNS42Mjk5NzI2IEMxOS43MjExMDY4LDEzLjYzMzQ4NjkgMjEuMzkxMjI4NCwxMiAyMS40MDI0MTY5LDEyIEMyMS40MTM2MDU0LDEyIDIxLjc3NzYzOTIsMTIuMzQ5MDkgMjIuMjExMzgwMSwxMi43NzU3NTUyIEwyMywxMy41NTE1MTAzIEwyMC4xMDI4MTczLDE2LjQwMDc4MDYgQzE4LjUwOTM2NjYsMTcuOTY3ODgwMiAxNy4yMDU2MzQ3LDE5LjI1OTAwMzEgMTcuMjA1NjM0NywxOS4yNjk5NDI4IEMxNy4yMDU2MzQ3LDE5LjI4MDg4MjQgMTguNTAyNTUzMiwyMC41NjUyNTcgMjAuMDg3Njc0NCwyMi4xMjQxMDc3IEwyMi45Njk3MTU4LDI0Ljk1ODM4MzkgTDIyLjE4NjEyNjIsMjUuNzI5MTkxOSBDMjEuNzU1MTUwNiwyNi4xNTMxMzYxIDIxLjM5MzM4MDMsMjYuNSAyMS4zODIxOTE4LDI2LjUgQzIxLjM3MTAwMzMsMjYuNSAxOS43MDU0MzMyLDI0Ljg3MDk4NzcgMTcuNjgwOTI0MywyMi44Nzk5NzI2IEwxNy42ODA5MjQzLDIyLjg3OTk3MjYgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=") !important;
          background-repeat: no-repeat;
          width: 9px;
          height: 15px;
        }
      }

      &:last-child {
        margin-left: 3px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url("data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjlweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgOSAxNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJUb2Vybm9vaWVuLWxpanN0L3RpamRsaWpuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTA3Mi4wMDAwMDAsIC03NzQuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxnIGlkPSJUb2Vybm9vaS1pdGVtIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNjAuMDAwMDAwLCAzODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iU2xpZGVyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYuMDAwMDAwLCAxODAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM1OC4wMDAwMDAsIDIwMi4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTIzLjMxOTA3NTcsMTUuNjIwMDI3NCBMMjcsMTkuMjQwMDU0OSBMMjMuMzA4OTYzMSwyMi44NzAwMjc0IEMyMS4yNzg4OTMyLDI0Ljg2NjUxMzEgMTkuNjA4NzcxNiwyNi41IDE5LjU5NzU4MzEsMjYuNSBDMTkuNTg2Mzk0NiwyNi41IDE5LjIyMjM2MDgsMjYuMTUwOTEgMTguNzg4NjE5OSwyNS43MjQyNDQ4IEwxOCwyNC45NDg0ODk3IEwyMC44OTcxODI3LDIyLjA5OTIxOTQgQzIyLjQ5MDYzMzQsMjAuNTMyMTE5OCAyMy43OTQzNjUzLDE5LjI0MDk5NjkgMjMuNzk0MzY1MywxOS4yMzAwNTcyIEMyMy43OTQzNjUzLDE5LjIxOTExNzYgMjIuNDk3NDQ2OCwxNy45MzQ3NDMgMjAuOTEyMzI1NiwxNi4zNzU4OTIzIEwxOC4wMzAyODQyLDEzLjU0MTYxNjEgTDE4LjgxMzg3MzgsMTIuNzcwODA4MSBDMTkuMjQ0ODQ5NCwxMi4zNDY4NjM5IDE5LjYwNjYxOTcsMTIgMTkuNjE3ODA4MiwxMiBDMTkuNjI4OTk2NywxMiAyMS4yOTQ1NjY4LDEzLjYyOTAxMjMgMjMuMzE5MDc1NywxNS42MjAwMjc0IEwyMy4zMTkwNzU3LDE1LjYyMDAyNzQgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=") !important;
          background-repeat: no-repeat;
          width: 9px;
          height: 15px;
        }
      }
    }
  }

  .tns-slider {
    max-height: 211px;
  }
`;

const CardInner = styled.div`
  position: relative;
  height: 100%;

  img {
    height: 249px;
    object-fit: cover;
  }
`;

const settings = {
  lazyload: true,
  nav: false,
  mouseDrag: true,
  items: 1
};

const CardPlayerSlider = ({ className, images }) => (
  <StyledCardPlayerSlider className={className}>
    <CardInner>
      <TinySlider settings={settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image.url} data-src={image} alt="" />
          </div>
        ))}
      </TinySlider>
    </CardInner>
  </StyledCardPlayerSlider>
);

export default CardPlayerSlider;
