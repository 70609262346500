import React from "react";
import styled from "styled-components";
import SelectItem from "../molecules/SelectItem";

const StyledSelectGroup = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: 30px;

  > div {
    &:first-child {
      margin-right: 15px;
    }
  }

  .filter-canvas & {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const StyledSelectItem = styled(SelectItem)`
  select {
    min-width: 100px;
  }
`;

const SelectGroup = ({ onFromChange, onTillChange, from, till, fromOptions, tillOptions }) => (
  <StyledSelectGroup>
    <StyledSelectItem
      labelText="Jaartal"
      placeholder="Van"
      value={from}
      onChange={onFromChange}
      options={fromOptions}
    />
    <StyledSelectItem placeholder="Tot" value={till} onChange={onTillChange} options={tillOptions} />
  </StyledSelectGroup>
);

export default SelectGroup;
